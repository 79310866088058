import { CancelPaymentParams, Payment } from '@constants';
import { ID, S3Image, Token, CurrentUser, Chatroom } from '@interfaces';
import { getToken } from '@store';
import { PlainAPI, API, VERSION, API_URL } from './api.config';
import { ApiService, AuthorizedApiService } from './api.service';

interface DefaultParams {
  [key: string]: any;
}

export const refresh = (): Promise<{ data: Token }> =>
  PlainAPI.post(
    '/token',
    {},
    {
      headers: { 'X-CSRF-TOKEN': getToken().csrf, Authorization: `Bearer ${getToken().token}` },
    },
  );
export const userMeApi = (params) => API.get<CurrentUser>(`/users/me/${params}`);
export const updateUserToken = (id: ID, params: any) => API.post(`/users/${id}/token`, { user: params });

export const get = (url: string, params: any) => PlainAPI.get(url, params);
export const loginAPI = (params: FormData) => PlainAPI.post('/login', params);
export const updateAPI = (params: DefaultParams = {}) => API.post('/users/update', params);
export const signupAPI = (params: DefaultParams = {}) => API.post('/users/signup', params);
export const userDestroyAPI = (id) => async () => {
  const { data } = await API.delete(`/users/${id}`);
  return data;
};
export const getSmsAuth = (params) => API.get('/phone_certifications/sms_auth', { params });
export const deleteImage = (id, params) => API.delete(`/images/${id}`, { params });
export const getLikes = () => API.get('/likes');

// 일반적인 경우는 Ojbect API 사용하기
export const {
  query: getObjects,
  infiniteQuery: getInfiniteObjects,
  get: getObject,
  create: createObject,
  update: updateObject,
  destroy: destroyObject,
} = ApiService('objects');

export const {
  query: getImages,
  get: getImage,
  create: createImage,
  update: updateImage,
  destroy: destroyImage,
} = ApiService<S3Image>('images');

export const bulkCreateImages = async (params: DefaultParams) => {
  const { data } = await API.post<S3Image[]>(`/images/bulk`, params);
  return data;
};

export const { infiniteQuery: getUser, update: updateUser } = ApiService('users');
export { API_URL, VERSION };

export const getNotices = async () => {
  const { data } = await API.get(`/notices`);
  return data;
};

export const getNotice = async (params) => {
  const { data } = await API.get(`/notices/${params}`);
  return data;
};

export const getNotofications = async (params) => {
  const { data } = await API.get(`/notifications`, { params });
  return data;
};

export const createNotificationAPI = async (params) => {
  const { data } = await API.post(`/notifications`, params);
  return data;
};

export const deleteNotification = async (id: number) => {
  const { data } = await API.delete(`/notifications/${id}`);
  return data;
};

export const getFaqs = async () => {
  const { data } = await API.get(`/faqs`);
  return data;
};

export const getUsers = async (query) => {
  const { data } = await API.get('/users', { params: query });
  return data;
};

export const getDrivers = async (params, page, sortBy, searchBy) => {
  const { data } = await API.post(`/users/drivers?page=${page}&sort_by=${sortBy}&search_by=${searchBy}`, params);
  return data;
};

export const getOneDriver = async (params) => {
  const { data } = await API.get(`users/driver/${params}`);
  return data;
};

export const getDistance = async (params) => {
  const { data } = await API.get(`schedules/distance`, {
    params: {
      departure: params.departure,
      stopOvers: params.stopOvers,
      destination: params.destination,
    },
  });
  return data;
};

export const getDriversByRegion = async (x, y) => {
  const { data } = await API.get(`users/driversByRegion?x=${x}&y=${y}`);
  return data;
};

export const createReservation = async (params) => {
  const { data } = await API.post(`reservations`, params);
  return data;
};

export const getReservations = async (email: string, page: number, status = 'all', calculate_status = 'all') => {
  const { data } = await API.get(
    `reservations?email=${email}&status=${status}&calculate_status=${calculate_status}&page=${page}`,
  );
  return data;
};

export const updateReservation = async (params, id) => {
  const { data } = await API.patch(`reservations/${id}`, params);
  return data;
};

export const getReservation = async (id) => {
  const { data } = await API.get(`reservations/${id}`);
  return data;
};

export const getBillingKey = async (params) => {
  const { data } = await API.post(`users/getBilling`, params);
  return data;
};

export const deleteBillingKey = async () => {
  const { data } = await API.delete(`users/deleteBilling`);
  return data;
};

export const createPayment = async (params) => {
  const { data } = await API.post(`users/payment`, params);
  return data;
};

export const cancelPayment = async (params: CancelPaymentParams) => {
  const { data } = await API.post(`users/refund`, params);
  return data;
};

export const paymentAgencyAPI = async (params: Payment) => {
  const { data } = await API.post(`users/paymentAgency`, params);
  return data;
};

export const createImageAPI = async (params: FormData) => {
  const { data } = await API.post('/images', params);
  return data;
};

export const destroyImageAPI = async (params: FormData) => {
  const { data } = await API.delete('/images', { data: params });
  return data;
};

export const createReview = async (params: any) => {
  const { data } = await API.post('/reviews', params);
  return data;
};

export const getReviews = async (params) => {
  const { data } = await API.get(`/reviews`, { params });
  return data;
};

export const getReview = async (id: ID) => {
  const { data } = await API.get(`/reviews/${id}`);
  return data;
};

export const updateReviews = async (id: ID, params: any) => API.patch(`reviews/${id}`, params);

export const createChatroom = () => async (params: any) => {
  const { data } = await API.post('/chatrooms', params);
  return data;
};

export const getChatroom = async (targetUserUuid: string) => {
  const { data } = await API.get(`/chatrooms/single?user_uuid=${targetUserUuid}`);
  return data;
};

export const createUserChatroom =
  () =>
  async ({ chatroom_id, params }: any) => {
    const { data } = await API.post(`/chatrooms/${chatroom_id}/user_chatrooms`, params);
    return data;
  };

export const { index: getChatrooms } = AuthorizedApiService<Chatroom>('chatroom');

export const createChatroomAPI = (params: { user_uuid: string; room_type: string }) =>
  API.post<Chatroom>('/chatrooms', params);

export const getSingleChatroom = (params: any) => async () => {
  const { data } = await API.get(`/chatroom/single`, { params });
  return data;
};

export const createUserChatroomAPI = (chatroom_id: string, params: { user_chatroom: { user_uuid: string }[] }) =>
  API.post<{ user_uuids: string[] }>(`/chatrooms/${chatroom_id}/user_chatrooms`, params);

export const disableUserChatroomAPI = async (chatroom_id: string) => {
  const { data } = await API.patch(`/chatrooms/${chatroom_id}/user_chatrooms/disable`);
  return data;
};

export const activateChatroomAPI = async (chatroom_id: string) => {
  const { data } = await API.patch(`/chatrooms/${chatroom_id}/activate`);
  return data;
};

export const readChatroomAPI = async (chatroomId: string) => {
  const { data } = await API.patch(`/chatrooms/${chatroomId}/read`);
  return data;
};

export const updateChatroomAPI = async (chatroomId: string, message: string) => {
  const { data } = await API.patch(`/chatrooms/${chatroomId}`, { message });
  return data;
};

export const getComment = async (id) => {
  const { data } = await API.get(`/comments/${id}`);
  return data;
};

export const createComment = async (params) => {
  const { data } = await API.post(`/comments`, params);
  return data;
};

export const updateComment = async (id, params) => {
  const { data } = await API.patch(`/comments/${id}`, params);
  return data;
};

export const getBanners = async (params) => {
  const { data } = await PlainAPI.get(`/banners`, { params });
  return data;
};

export const getBanner = async (id) => {
  const { data } = await PlainAPI.get(`/banners/${id}`);
  return data;
};

export const getDriverDynamicLink = async ({ id, shareType }) => {
  const { data } = await PlainAPI.get(`/users/dynamic-link?id=${id}&shareType=${shareType}`);
  return data;
};

export const getSchedultDynamicLink = async ({ encodedTourData, shareType }) => {
  const { data } = await PlainAPI.get(`/users/dynamic-link?encodedTourData=${encodedTourData}&shareType=${shareType}`);
  return data;
};

export const createDriverManagement = (params: DefaultParams = {}) =>
  API.post('/users/create/driver_management', params);

export const updateDriverManagement = (params: DefaultParams = {}) =>
  API.put('/users/update/driver_management', params);

export const getBuses = (params: any) => async () => {
  const { data } = await API.get(`/buses`, { params });
  return data;
};

export const getBus = async (id: any, params = {}) => {
  const { data } = await API.get(`/buses/${id}`, { params });
  return data;
};

export const deleteBus = async (id: any) => {
  const { data } = await API.delete(`/buses/${id}`);
  return data;
};

export const createDayoff = async (params: any) => {
  const { data } = await API.post('/dayoff', params);
  return data;
};

export const deleteDayoff = async (id: any) => {
  const { data } = await API.delete(`/dayoff/${id}`);
  return data;
};
export const getDayoffUser = async (id: any, params) => {
  const { data } = await API.get(`/dayoff/users/${id}`, { params });
  return data;
};

export const sendCode = async (params: any) => {
  const { data } = await API.post('/users/sendCode', params);
  return data;
};
