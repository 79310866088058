import { atom, RecoilState, selector } from 'recoil';
import { CurrentUser, Schedule } from '@interfaces';
import moment from 'moment';

export const initialFlash = {
  flashType: null,
  body: null,
  isShow: false,
};

export const flashAtom = atom({
  key: 'flashAtom',
  default: initialFlash,
});

const initialCurrentUser = {
  isAuthenticated: false,
};

export const currentUserState = atom<CurrentUser>({
  key: 'currentUser',
  default: initialCurrentUser as CurrentUser,
});

export type CurrentUserState = RecoilState<CurrentUser>;

export const userLikes = atom({
  key: 'userLikes',
  default: {},
});

export const onewayState = atom({
  key: 'oneway',
  default: false,
});

export const tourScheduleState = atom<Schedule[]>({
  key: 'tourSchedule',
  default: [],
});

export const searchingOptionState = atom({
  key: 'searchingOption',
  default: {
    totalDistance: 0,
    departureDate: moment(),
    returnDate: null,
    departureTime: '오전 09:00',
    returnTime: '오후 05:00',
    people: null,
  },
});

export const searchingOptionDateSelector = selector({
  key: 'searchingOptionDateSelector',
  get: ({ get }) => {
    const searchingOption = get(searchingOptionState);
    const { departureDate, returnDate } = searchingOption;
    return {
      departureDate,
      returnDate,
    };
  },
});

export const searchingOptionDepartureTimeSelector = selector({
  key: 'searchingOptionDepartureTimeSelector',
  get: ({ get }) => {
    const searchingOption = get(searchingOptionState);
    const { departureTime } = searchingOption;
    return departureTime;
  },
  set: ({ set }, newValue) => {
    set(searchingOptionState, (prevState) => ({
      ...prevState,
      departureTime: `${newValue}`,
    }));
  },
});

export const searchingOptionReturnTimeSelector = selector({
  key: 'searchingOptionReturnTimeSelector',
  get: ({ get }) => {
    const searchingOption = get(searchingOptionState);
    const { returnTime } = searchingOption;
    return returnTime;
  },
  set: ({ set }, newValue) => {
    set(searchingOptionState, (prevState) => ({
      ...prevState,
      returnTime: `${newValue}`,
    }));
  },
});

export const distanceState = atom({
  key: 'distanceState',
  default: 0,
});

export const totalChargeState = atom({
  key: 'totalChargeState',
  default: 0,
});
