import React from 'react';

const PrivacyTerm = () => (
  <div className="px-4 py-8 flex flex-col bg-[#F3F4F6]">
    <div className="mb-2">
      <h1 className="font-bold text-lg">개인정보취급방침</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        폼나는누나(이하 "회사")는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법,
        전기통신사업법, 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
        의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 1장 수집하는 개인정보의 항목 및 수집방법</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        1&#41; 수집하는 개인정보의 항목
        <p className="text-sm text-[#2f3037] pt-2">
          "회사"는 회원가입, 원활한 고객상담, 각종 서비스 등 기본적인 서비스 제 공을 위한 필수정보와 고객 맞춤 서비스
          제공을 위한 선택정보로 구분하 여 아래와 같은 개인정보를 수집하고 있습니다.
        </p>
        <ul className="pl-4">
          <li className="list-disc pt-2">
            회원 : 이름, 아이디, 비밀번호, 프로필사진, 핸드폰번호, 기기고유번호(디 바이스 아이디 또는 IMEI)
          </li>
          <li className="list-disc pt-2">
            버스기사 : 프로필사진, 이름, 아이디, 비밀번호, 핸드폰번호, 소속회사명, 소속회사사업자등록번호, 지역,
            차량번호, 차량정보, 차량사진, 버스운전 자격증, 보험증서사진
          </li>
          <li className="list-disc pt-2">
            버스회사 : 회사로고, 회사이름, 담당자이름, 담당자이메일, 핸드폰번호, 사업자등록증, 여객자동차운송사업등록증,
            차량사진, 차량정보
          </li>
          <span className="pt-2">
            서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
          </span>
          <li className="list-disc pt-2">IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 결제기록, 불량 이용 기록</li>
          <span className="pt-2">
            예약 결제 서비스 이용시에 결제 등을 위해 불가피하게 신용카드 정보, 핸 드폰 및 은행계좌 등의 정보와 결제기록
            등의 정보가 수집 될 수 있습니 다.
          </span>
          <li className="list-disc pt-2">신용카드 결제시 : 카드사명, 카드번호 등</li>
          <li className="list-disc pt-2">휴대전화 결제시 : 이동전화번호, 통신사, 결제승인번호 등</li>
          <li className="list-disc pt-2">계좌이체 결제시 : 은행명, 계좌번호 등</li>
        </ul>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        2&#41; 개인정보 수집방법
        <p className="text-sm text-[#2f3037] pt-2">"회사"는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
        <ul className="pl-4">
          <li className="list-disc pt-2">
            서비스 실행 또는 사용시 자동으로 수집하거나 이용자의 자발적 제공을 통해 수집
          </li>
          <li className="list-disc pt-2">
            이메일, 전화, 팩스, 상담 게시판, 웹사이트, 서면양식, 이벤트 응모, 배송 요청 등
          </li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 2장 개인정보의 수집 및 이용목적</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도
        이외로는 사용되지 않으며 이용 목적이 변 경될 시에는 사전 동의를 구할 것입니다.
        <p className="text-sm text-[#2f3037] pt-2">서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산</p>
        <p className="text-sm text-[#2f3037] pt-2">
          콘텐츠 제공, 구매 및 요금 결제, 이벤트 경품 배송 또는 청구지 등 발송, 금융거래 본인 인증 및 금융 서비스,
          요금추심
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          서비스 가입/변경/해지 처리, 이벤트 경품 배송, 본인확인, 개인식별, 가입 의사 확인, 고지사항 전달, 서비스 제공
          관련 안내, 명의도용 방지를 위한 등록된 이동전화로 가입사실 통보, 이용요금 고지 결제 및 추심, 이용관련 문의
          불만 처리
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          회원 관리를 위해 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비 인가 사용 방지, 가입 의사 확인, 연령확인,
          불만처리 등 민원처리, 고지사 항 전달
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          마케팅 및 광고에 활용 동의한 회원에 한하여 광고, 이벤트, 프로모션 경 품 시행 및 당첨회원에 대한 물품배송,
          이벤트 당첨자 포인트(사이버 머 니) 제공, 기타 신규 서비스 개발 및 특화 내용 광고성 정보 전달 시 활용, 접속빈도
          파악, 회원의 서비스 이용에 대한 통계
        </p>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 3장 개인정보 위탁</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 고객에게 다양한 서비스를 제공하는데에 반드시 필요한 업무 중 일부를 외부업체로 하여금 수행하도록
        개인정보를 위탁하고 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 위탁 받은 업체가 개인정보보호 관계 법령을 위반하지 않도록 관리·감 독하고 있으며, 위탁업무의 내용이나
        수탁자가 변경될 경우에는 지체없이 본 개 인정보 처리방침을 통하여 공개하도록 하겠습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 위탁하는 이용자 개인정보 &#62;
        <ul className="pl-4">
          <li className="pt-2 list-disc">수탁자 : 토스페이먼츠</li>
          <li className="pt-2 list-disc">
            위탁 업무 : 휴대폰, 신용카드, 계좌이체, 간편결제, 무통장 입금 (가상계 좌) 등을 통한 결제정산처리
          </li>
          <li className="pt-2 list-disc">보유 및 이용기간 : 위탁 계약 만료 시 까지 보유</li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 4장 개인정보의 보유 및 이용기간</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이용자의 개인정보의 보유 및 이용기간은 회원 가입 시점부터 발생됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및
        이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료된 경우 해당 개인정보를
        지 체 없이 파기합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">1) "회사" 내부 방침에 의한 정보보유 사유</p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 부정이용 혹은 민원발생 &#62;
        <p className="text-sm text-[#2f3037] pt-2">
          보존 이유 : 불량이용자의 재가입 방지, 부정이용방지, 서비스 요금 미납 고객에 대한 요금 청구, 기타 민원 대응
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          보존 기간 : 5년(단, 미납 요금청구의 경우 요금 완납시, 민원 대응의 경우 민원 해결시까지)
        </p>
        <p className="text-sm text-[#2f3037] pt-2">보존 정보 : 수집된 개인정보 전체</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">2) 관련법령에 의한 정보보유 사유</p>
      <p className="text-sm text-[#2f3037] pt-2">
        관계법령의 규정에 의하여 보존할 필요가 있는 경우 "회사"는 관계법령에서 정한 일정한 기간 동안 회원정보를
        보관합니다. 이 경우 "회사"는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 소비자의 불만 또는 분쟁처리에 관한 기록 &#62;
        <p className="text-sm text-[#2f3037] pt-2">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p className="text-sm text-[#2f3037] pt-2">보존 기간 : 3년</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 본인확인에 관한 기록 &#62;
        <p className="text-sm text-[#2f3037] pt-2">보존 이유 : 정보통신 이용촉진 및 정보보호 등에 관한 법률</p>
        <p className="text-sm text-[#2f3037] pt-2">보존 기간 : 6개월</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 방문에 관한 기록 &#62;
        <p className="text-sm text-[#2f3037] pt-2">보존 이유 : 통신비밀보호법</p>
        <p className="text-sm text-[#2f3037] pt-2">보존 기간 : 3개월</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 대금결제 및 재화 등의 공급에 관한 기록 &#62;
        <p className="text-sm text-[#2f3037] pt-2">보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
        <p className="text-sm text-[#2f3037] pt-2">보존 기간 : 5년</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        3&#41; 휴면 회원
        <ul className="pt-4">
          <li className="list-disc pt-2">
            회원이 1년 이상 로그인을 하지 않은 경우 해당 회원은 휴면 회원으로 전 환되어 개인정보가 별도 분리 보관됩니다.
          </li>
          <li className="list-disc pt-2">
            "회사"가 휴면 회원의 서비스 이용에 대한 제한 및 개인정보를 파기할 경 우, 30일 전 회원의 이메일을 통하여 관련
            사항을 고지합니다. 만약 회원 의 이메일 또는 이동전화 연락처의 부재, 변경, 오류 등으로 개별 통지가 어려운
            경우, "회사"는 사전 통지 없이 휴면 회원의 서비스 이용에 대한 제 한 및 개인정보를 파기할 권한을 갖습니다.
          </li>
          <li className="list-disc pt-2">
            휴면 회원은 서비스 이용 제한 후 사이트 상에서 직접 본인확인을 거쳐 휴 면상태 해지신청을 하는 즉시 정상적으로
            서비스를 이용할 수 있습니다.
          </li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 5장 개인정보 파기절차 및 방법</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되거나 보 유 및 이용기간이 종료된 경우 지체
        없이 파기됩니다. "회사"의 개인정보 파기절 차 및 방법은 다음과 같습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        1&#41; 파기절차
        <ul className="pt-4">
          <li className="list-disc pt-2">
            이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB 로 옮겨져(종이의 경우 별도의 서류함)
            내부 방침 및 기타 관련 법령에 의 한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파
            기됩니다.
          </li>
          <li className="list-disc pt-2">
            동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목 적으로 이용되지 않습니다.
          </li>
        </ul>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        2&#41;. 파기방법
        <ul className="pt-2">
          <li className="list-disc pt-2">종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니 다.</li>
          <li className="list-disc pt-2">
            전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방 법을 사용하여 삭제합니다.
          </li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 6장 이용자 및 법정대리인의 권리와 그 행사방법</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할
        수 있으며 가입해지를 요청할 수도 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인 정보변경'(또는 '회원정보수정' 등)을,
        가입해지(동의철회)를 위해서는 "회 원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈 퇴가
        가능합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료 하기 전까지 당해 개인정보를 이용 또는
        제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에 게 지체 없이
        통지하여 정정이 이루어지도록 하겠습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인 정보는 "3. 개인정보의 보유 및 이용기간"에
        명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 7장 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보 를 저장하고 수시로 불러오는
        '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게
        보내는 소량 의 정보이며 이용자들의 컴퓨터내의 하드디스크에 저장되기도 합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">1) 쿠키의 사용 목적</p>
      <p className="text-sm text-[#2f3037] pt-2">
        "회사"는 이용자들이 방문한 "회사"의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부,
        뉴스편집, 이용자 규모 등을 파악하여 이 용자에게 최적화된 정보 제공을 위하여 쿠키를 사용합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        2&#41; 쿠키의 설치/운영 및 거부
        <ul className="pt-4">
          <li className="list-disc pt-2">
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든
            쿠키를 허용하거나, 쿠키가 저 장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있 습니다.
          </li>
          <li className="list-disc pt-2">
            쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션 을 선택함으로써 모든 쿠키를 허용하거나
            쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
          </li>
          <li className="list-disc pt-2">
            설정방법 예(Internet Explorer의 경우&#41; : 웹 브라우저 상단의 도구 &#62; 인터넷 옵션 &#62; 개인정보 탭
            &#62; 개인정보 수준 설정
          </li>
          <li className="list-disc pt-2">
            다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이 용에 어려움이 있을 수 있습니다.
          </li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 8장 개인정보관리책임자 및 담당자의 연락처</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        귀하께서는 "회사"의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민 원을 개인정보관리책임자 혹은
        담당부서로 신고하실 수 있습니다. "회사"는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 개인정보 관리책임자 &#62;
        <p className="text-sm text-[#2f3037] pt-2">이름 : 정수경</p>
        <p className="text-sm text-[#2f3037] pt-2">전화 : 1533-1035</p>
        <p className="text-sm text-[#2f3037] pt-2">직위 : 대표</p>
        <p className="text-sm text-[#2f3037] pt-2">메일 : form0313@baenangbus.com</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        &#60; 개인정보 관리책임자 &#62;
        <p className="text-sm text-[#2f3037] pt-2">이름 : 정창열</p>
        <p className="text-sm text-[#2f3037] pt-2">전화 : 1533-1035</p>
        <p className="text-sm text-[#2f3037] pt-2">직위 : 이사</p>
        <p className="text-sm text-[#2f3037] pt-2">메일 : form0313@baenangbus.com</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
        <ul className="pl-4">
          <li className="list-disc pt-2">개인분쟁조정위원회 (1336.or.kr / 1336&#41;</li>
          <li className="list-disc pt-2">정보보호마크인증위원회 (eprivacy.or.kr / 02-580-0533~4&#41;</li>
          <li className="list-disc pt-2">대검찰청 인터넷범죄수사센터 (icic.sppo.go.kr / 02-3480-3600&#41;</li>
          <li className="list-disc pt-2">경찰청 사이버테러대응센터 (ctrc.go.kr / 02-392-0330&#41;</li>
        </ul>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 9장 고지의 의무</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이 약관은 폼나는누나(이하 "회사"라 합니다&#41;가 제공하는 배낭버스 서비 스(이하 "서비스"라 합니다&#41;와
        관련하여, 회사와 이용 고객 간에 서비스의 이용조건 및 절차, 회사와 회원간의 권리, 의무 및 기타 필요한 사항을 규
        정함을 목적으로 합니다. 본 약관은 PC통신, 스마트폰(안드로이드폰, 아 이폰 등&#41; 앱 등을 이용하는 전자상거래에
        대해서도 그 성질에 반하지 않 는 한 준용됩니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제 6장 이용자 및 법정대리인의 권리와 그 행사방법</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부 터 홈페이지의 "알림"을 통해 고지할
        것입니다.
        <ul className="pl-4">
          <li className="pt-2 list-disc">개인정보보호취급방침 공고일자 : 2022년 2월</li>
          <li className="pt-2 list-disc">개인정보보호취급방침 시행일자 : 2022년 2월</li>
        </ul>
      </p>
    </div>
  </div>
);

export default PrivacyTerm;
