import React, { useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { f7, List, ListInput, Navbar, Button, Page, Link } from 'framework7-react';
import { showToast } from '@js/utils';
import { createReview } from '@api';
import { useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import backIcon from '@assets/images/back_icon.png';

const NewReviewPage = ({ f7route, f7router }) => {
  const queryClient = useQueryClient();
  const [rating, setRating] = useState(5);
  const [content, setContent] = useState('');
  const { reservationId: id } = f7route.query;

  const handleReviewButton = async () => {
    f7.preloader.show();
    let message: string;
    try {
      const params = {
        reservationId: id,
        rating,
        content,
      };
      await createReview(params);
      await queryClient.fetchQuery(REACT_QUERY_KEYS.RESERVATION);
      message = '리뷰가 작성되었습니다';
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        message = data.error.message;
      }
    } finally {
      f7.preloader.hide();
      showToast(message);
      f7router.back();
    }
  };

  const handleStar = (value) => {
    setRating(value);
  };

  return (
    <Page name="review" noToolbar className="px-4">
      <Navbar title="리뷰남기기">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>

      <div className="flex justify-center">
        <StarRatingComponent
          name="rating"
          starCount={5}
          value={rating}
          onStarClick={handleStar}
          starColor="#FF3A30"
          emptyStarColor="#AAAAAA"
          className="mt-7 text-2xl"
        />
      </div>

      <List mediaList>
        <ListInput
          type="textarea"
          placeholder="버스 이용에 대한 솔직한 리뷰를 남겨주세요"
          required
          onChange={(e) => setContent(e.target.value)}
        />
      </List>
      <Button fill className="font-semibold text-base h-10" onClick={handleReviewButton}>
        리뷰남기기
      </Button>
    </Page>
  );
};

export default NewReviewPage;
