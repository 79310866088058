import Framework7 from 'framework7';
import { f7 } from 'framework7-react';

const nativeConfig = {
  f7: {} as Framework7,
  handleAndroidBackButton() {
    window.addEventListener('popstate', (e) => {
      if (window.history && !!window.history.pushState) {
        if (f7.$el.find('.panel-left').hasClass('panel-in') || f7.$el.find('.panel-right').hasClass('panel-in')) {
          f7.panel.close();
        } else if (f7.$el.find('.dialog').hasClass('modal-in')) {
          f7.dialog.close();
        } else if (f7.$el.find('.popup:not(.home-date-picker)').hasClass('modal-in')) {
          f7.popup.close();
        } else if (f7.$el.find('.sheet-modal').hasClass('modal-in')) {
          f7.sheet.close();
        } else if (f7.actions.get('.actions-modal') !== undefined && f7.actions.get('.actions-modal').opened) {
          f7.actions.close();
        } else {
          f7.views.current.router.back();
        }
      }
    });
  },
  // eslint-disable-next-line @typescript-eslint/no-shadow
  init(f7: Framework7) {
    nativeConfig.f7 = f7;

    nativeConfig.handleAndroidBackButton();
  },
};

export default nativeConfig;
