import React from 'react';
import { Page } from 'framework7-react';
import logo from '../assets/images/logo.png';

const LandingPage = () => (
  <Page>
    <div className="w-full flex justify-center items-center" style={{ height: '90vh' }}>
      <img src={logo} alt="bus-logo" className="w-48" />
    </div>
  </Page>
);

export default LandingPage;
