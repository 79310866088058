import { configs } from '@config';

type shareTypeDefine = 'driver' | 'tour';

const shareTypeDriverContents = {
  description: '친구가 추천하는 버스기사!',
};

const shareTypePlanContents = {
  description: '친구가 공유한 일정!',
  imageUrl:
    'https://images.unsplash.com/photo-1592071241777-b1e32bbe3590?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1788&q=80',
};

const choiceContent = (type: shareTypeDefine) => {
  if (type === 'driver') return shareTypeDriverContents;
  return shareTypePlanContents;
};

export const shareByKakao = (link: string, shareType: shareTypeDefine, imageKey = []) => {
  if (!window.Kakao.isInitialized()) window.Kakao.init(configs.KAKAO_TOKEN);
  const shareContent = choiceContent(shareType);
  const driverImageUrl =
    imageKey.length > 0
      ? `https://backpackbusdev3ff52fc6d2b8468ea3342278ea216c11155633-dev.s3.ap-northeast-2.amazonaws.com/public/origins/${imageKey[0]?.key}`
      : 'https://images.unsplash.com/photo-1592071241777-b1e32bbe3590?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1788&q=80';
  window.Kakao.Link.sendDefault({
    objectType: 'feed',
    content: {
      title: '배낭버스',
      description: shareContent.description,
      imageUrl: shareType === 'driver' ? driverImageUrl : shareTypePlanContents.imageUrl,
      link: {
        mobileWebUrl: link,
      },
    },
    buttons: [
      {
        title: '앱에서 확인',
        link: {
          mobileWebUrl: link,
        },
      },
    ],
    installTalk: true,
  });
};
