import { AppleLoginButton, KakaoLoginButton } from '@components/oauthButtons';
import { configs } from '@config';
import useAuth from '@hooks/useAuth';
import { kakaoLoginSuccessHandler } from '@utils/customAuthChallenge';
import { getDevice } from 'framework7';
import React from 'react';
import backIcon from '@assets/images/back_icon.png';
import passengerIcon from '@assets/images/passenger_user.png';
import driverIcon from '@assets/images/driver_user.png';
import companyIcon from '@assets/images/company_user.png';

import { Navbar, Page, Link, PageContent } from 'framework7-react';

const SignUpIndexPage = ({ f7router }) => {
  const { KAKAO_TOKEN } = configs;
  const { authenticateUser } = useAuth();
  const device = getDevice();

  return (
    <Page name="SignUpIntro" className="bg-[#F3F4F6]" noToolbar pageContent={false}>
      <Navbar sliding={false} noHairline className="bg-[#F3F4F6]" noShadow>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <PageContent className="bg-[#F3F4F6]">
        <div className="sm:min-h-full" style={{ maxWidth: '30rem' }}>
          <div className="mx-12 py-14 text-center text-lg text-gray-700">
            회원가입 하시면,
            <br />
            <span className="font-bold">배낭버스의 다양한 서비스</span>를
            <br />
            이용하실 수 있습니다.
          </div>
          <div>
            <div className="mx-6">
              <a
                href="/users/sign_up/normal"
                className="shadow rounded-xl bg-white w-full h-[70px] px-7 flex items-center relative mb-2.5 focus:ring-1"
              >
                <div className="text-lg">
                  <span className="font-bold">고객</span>이신가요?
                </div>
                <img className="absolute top-3 right-10" src={passengerIcon} alt="" width="50px" />
              </a>
              <a
                href="/users/sign_up/driver"
                className="shadow rounded-xl bg-white w-full h-[70px] px-7 flex items-center relative mb-2.5 focus:ring-1"
              >
                <div className="text-lg">
                  <span className="font-bold">기사님</span>이신가요?
                </div>
                <img className="absolute top-3 right-10" src={driverIcon} alt="" width="50px" />
              </a>
              <a
                href="/users/sign_up/driver?company=true"
                className="shadow rounded-xl bg-white w-full h-[70px] px-7 flex items-center relative mb-2.5 focus:ring-1"
              >
                <div className="text-lg">
                  <span className="font-bold">회사(업체)</span>이신가요?
                </div>
                <img className="absolute top-3 right-10" src={companyIcon} alt="" width="50px" />
              </a>
            </div>
          </div>

          <div className="pt-14 text-center">
            <span className="text-center text-[#2f3037] font-medium text-[13px]">이미 배낭버스 계정이 있으신가요?</span>
            <a href="/users/sign_in" className="ml-3.5 underline text-primary font-bold">
              로그인하기
            </a>
          </div>
          <div className="p-4">
            <div className="mb-2.5">
              <KakaoLoginButton
                token={KAKAO_TOKEN}
                onSuccess={kakaoLoginSuccessHandler(authenticateUser)}
                onFail={(_) => _}
                f7router={f7router}
              />
            </div>
            {device.ios && <AppleLoginButton />}
          </div>
        </div>
      </PageContent>
    </Page>
  );
};

export default SignUpIndexPage;
