/* eslint-disable no-nested-ternary */
import S3ImageView from '@components/images/S3ImageView';
import useAuth from '@hooks/useAuth';
import { Link } from 'framework7-react';
import moment from 'moment';
import React from 'react';
import StarRatingComponent from 'react-star-rating-component';

const SingleReview = ({ review }) => {
  const { currentUser } = useAuth();
  const { id, rating, createdAt, content, user, comment } = review;

  return (
    <div className="mt-4 bg-white rounded-lg shadow p-4 mx-4 border-b border-background" key={id}>
      <div className="flex justify-between position-relative">
        <div className="grid grid-rows-3 grid-flow-col gap-2">
          <div className="row-span-3">
            <S3ImageView imageKey={user.profile?.key || ''} className="w-10 h-10 rounded-full overflow-hidden" />
          </div>
          <div className="col-span-2 text-sm font-bold">{user.name}</div>
          <div className="row-span-1 flex text-xs">
            <StarRatingComponent
              name="rating"
              editing={false}
              starCount={5}
              value={rating}
              starColor="#FFD400"
              emptyStarColor="#AAAAAA"
            />
            <div className="text-gray-500 ml-2">{`${moment(createdAt).format('YYYY/MM/DD')}`}</div>
          </div>
        </div>
        {currentUser &&
          currentUser.isAuthenticated &&
          (currentUser.user_type === 'driver' ? (
            comment ? (
              <Link
                className="position-absolute right-0 top-0 underline text-sm font-semibold items-baseline"
                href={`/comments/${comment.id}/edit`}
              >
                댓글수정
              </Link>
            ) : (
              <Link
                className="position-absolute right-0 top-0 underline text-sm font-semibold items-baseline"
                href={`/comments/new?reviewId=${id}&review=${JSON.stringify(review)}`}
              >
                댓글작성
              </Link>
            )
          ) : (
            user.id === currentUser.id && (
              <Link
                className="position-absolute right-0 top-0 underline text-sm font-semibold items-baseline"
                href={`/reviews/edit/${id}`}
              >
                리뷰 수정
              </Link>
            )
          ))}
      </div>
      <div className="text-base">{content}</div>
      {comment && (
        <div className="flex justify-between position-relative">
          <div className="mt-6 grid grid-rows-1 grid-cols-6 gap-2">
            <div className="row-span-1 col-span-1">
              <S3ImageView
                imageKey={comment.user?.profile?.key || ''}
                className="w-10 h-10 rounded-full overflow-hidden"
              />
            </div>
            <div className="row-span-1 col-span-5 bg-background rounded-xl w-full p-4">
              <div className="flex">
                <div className="text-sm font-bold">{comment.user?.name}</div>
                <div
                  className="text-gray-500 ml-2 text-xs"
                  style={{ margin: 'auto 0', fontWeight: 'normal', marginLeft: '3px' }}
                >{`${moment(comment?.createdAt).format('YYYY/MM/DD')}`}</div>
              </div>
              <div className="text-base break-all">{comment?.body}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleReview;
