import { f7, Link, Navbar, NavLeft, NavTitle, Page, Input, Button, NavRight, Card } from 'framework7-react';
import { Dom7 as $$ } from 'framework7/lite-bundle';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import {
  searchingOptionState,
  searchingOptionDateSelector,
  tourScheduleState,
  totalChargeState,
  searchingOptionDepartureTimeSelector,
  searchingOptionReturnTimeSelector,
} from '@atoms';
import DetailContainer from '@components/search/DetailContainer';
import DatePopup from '@components/search/DatePopUp';
import TimeDisplay from '@components/search/timeDisplay';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';
import { getDistance, getDriverDynamicLink, getDrivers, getSchedultDynamicLink } from '@api';
import { deleteReservationInformationAtLocalStorage, showToast, sleep } from '@js/utils';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import ListPreloader from '@components/shared/ListPreloader';
import { REACT_QUERY_KEYS } from '@constants';
import { IoReloadSharp, IoSearchOutline, IoAlertCircleOutline, IoShareOutline } from 'react-icons/io5';
import EmptyList from '@components/shared/EmptyList';
import useAuth from '@hooks/useAuth';
import { Base64 } from 'js-base64';
import { shareByKakao } from '@utils/shareDynamicLink';
import DriverListItem from './users/driverListItem';

const SearchPage = ({ f7router, f7route }) => {
  const KakaoPlaceRef = useRef(new (window as any).kakao.maps.services.Places());
  const allowInfinite = useRef(true);
  const sortBy = useRef('createdAtDesc');
  const searchBy = useRef('');
  const queryClient = useQueryClient();
  const [isInfinite, setIsInfinite] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [tourSchedule, setTourSchedule] = useRecoilState(tourScheduleState);
  const latestTourSchedule = useRef(tourSchedule);
  const [searchingOption, setSearchingOption] = useRecoilState(searchingOptionState);
  const { totalDistance } = searchingOption;
  const { departureDate, returnDate } = useRecoilValue(searchingOptionDateSelector);
  const isInitialPopup = useRef(!f7route.query.query);
  const latestSearchingOption = useRef(searchingOption);
  const resetSearchOption = useResetRecoilState(searchingOptionState);
  const departureTime = useRecoilValue(searchingOptionDepartureTimeSelector);
  const returnTime = useRecoilValue(searchingOptionReturnTimeSelector);
  const dayDiff = returnDate ? moment(returnDate).diff(moment(departureDate), 'days') + 1 : 0;
  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 0,
  });
  const { currentUser } = useAuth();
  const setTotalCharge = useSetRecoilState(totalChargeState);
  const [showEmptyList, setShowEmptyList] = useState(false);

  const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetching, status } = useInfiniteQuery(
    REACT_QUERY_KEYS.DRIVER,
    async ({ pageParam: page = 1 }) => {
      if (page === false) {
        return [];
      }
      const response = await getDrivers(
        { ...latestSearchingOption.current, schedule: latestTourSchedule.current },
        page,
        sortBy.current,
        searchBy.current,
      );
      return response.data;
    },
    {
      enabled: isInfinite,
      getNextPageParam: (lastPage, pages) => (pages[pages?.length - 1]?.length !== 0 ? pages.length + 1 : false),
    },
  );
  const drivers = useMemo(() => data?.pages?.flat() || '', [data]);
  const isDriverPresent: boolean = !!hasNextPage && !isLoading && data.pages.flat().length !== 0;

  const fetchNextPageAsync = useCallback(async () => {
    allowInfinite.current = false;
    await fetchNextPage();
    allowInfinite.current = true;
  }, [fetchNextPage]);

  useEffect(() => {
    if (!isTargetInView || !allowInfinite.current) return;
    fetchNextPageAsync();
  }, [isTargetInView, fetchNextPageAsync]);

  const getDayList = useCallback(() => {
    const days = [];
    if (dayDiff >= 0) {
      [...Array(dayDiff)].forEach((day, index) => {
        days.push({ day: moment(departureDate).add(index, 'days').format('YY년 MM월 D일'), idx: index });
      });
    }

    if (days.length === 1) {
      days.push({ ...days[0], idx: 1 });
    }
    return days;
  }, [dayDiff, departureDate]);

  useEffect(() => {
    if (f7route.query && moment(moment().format()).isAfter(searchingOption.departureDate, 'day') && !popupOpened) {
      isInitialPopup.current = true;
      resetSearchOption();
      showToast('이미 지난 일정입니다');
    }
  }, [searchingOption.departureDate]);

  const filterDrivers = async (value = null) => {
    sortBy.current = value;
    queryClient.removeQueries(['drivers']);
    await refetch();
    allowInfinite.current = true;
  };
  const isDailyTourNotHaveStopOvers = (tour) =>
    tour.length === 1 && tour[0].departure === tour[0].destination && tour[0].stopOvers.length === 0;

  const getResult = async () => {
    if (isDailyTourNotHaveStopOvers(tourSchedule)) {
      showToast('목적지와 출발지가 같은 하루 일정에는 경유지가 포함되어야 합니다.');
    } else if (departureDate !== null && returnDate !== null) {
      f7.preloader.show();
      data?.pages.length > 0 && (data.pages = []);
      const copiedTourSchedule = JSON.parse(JSON.stringify(tourSchedule));

      const schedulePromise = [];
      try {
        copiedTourSchedule.forEach((schedule: any) => {
          const { departure, destination, stopOvers } = schedule;
          const promise = getDistance({ departure, destination, stopOvers }).then((distance) => {
            schedule.distance = distance;
            return schedule;
          });
          schedulePromise.push(promise);
        });

        const addDistanceSchedules = await Promise.all(schedulePromise);
        const calculatedTotalDistance = addDistanceSchedules.map((el) => el.distance).reduce((acc, curr) => acc + curr);
        setSearchingOption({ ...searchingOption, totalDistance: calculatedTotalDistance });

        latestSearchingOption.current = searchingOption;
        latestTourSchedule.current = addDistanceSchedules;
        f7.preloader.hide();

        setTourSchedule(addDistanceSchedules);
        setIsInfinite(true);
        searchBy.current = '';
        await fetchNextPage();
      } catch (err) {
        queryClient.resetQueries('drivers', { exact: true });
        setIsInfinite(false);
        f7.preloader.hide();

        if (err.response?.data?.error?.message === 'empty data exist') {
          showToast('경로를 모두 입력해주세요');
          return;
        }
        if (err.response?.data?.error?.message === 'over 1000km') {
          showToast('하루의 일정중 1000km가 넘어간 일정이 있습니다. <br> 1000km이하의 경로만 요청 가능합니다.');
          return;
        }

        if (err.response) {
          showToast('다시 검색을 시도해주세요');
          return;
        }
      }

      setShowEmptyList(true);
      const accordions = $$('.accordion-item.accordion-item-opened');
      accordions.each((el) => {
        f7.accordion.close(el);
      });
    } else {
      showToast('일정을 모두 입력해주세요');
    }
  };

  const searchPlaces = async (keyword: string, callback: any) => {
    if (!keyword.replace(/^\s+|\s+$/g, '')) {
      return false;
    }
    return KakaoPlaceRef.current.keywordSearch(keyword, await callback);
  };

  const afterAuthenticatedProcess = () => {
    if (window.localStorage.getItem('isProcessingWithUnAuthenticated')) {
      const driverId = window.localStorage.getItem('driverId');
      const peoplesCount = JSON.parse(window.localStorage.getItem('peoplesCount'));
      const searchingOptionValue = JSON.parse(window.localStorage.getItem('searchingOption'));
      setTotalCharge(JSON.parse(window.localStorage.getItem('totalCharge')));
      setTourSchedule(JSON.parse(window.localStorage.getItem('tourSchedule')));
      setSearchingOption({
        ...searchingOptionValue,
        departureDate: moment(searchingOptionValue.departureDate),
        returnDate: moment(searchingOptionValue.returnDate),
      });

      f7router.navigate(`/drivers/${driverId}?people=${Number(peoplesCount)}`, {
        history: true,
      });
    }
  };

  useEffect(() => {
    if (f7route.query.query) {
      const tourJson = JSON.parse(Base64.decode(f7route.query.query));
      const transferSearchingOptionMoment = tourJson.shareSearchingOptionState;
      latestTourSchedule.current = tourJson.shareTourScheduleState;
      latestSearchingOption.current = {
        ...transferSearchingOptionMoment,
        departureDate: moment(transferSearchingOptionMoment.departureDate),
        returnDate: moment(transferSearchingOptionMoment.returnDate),
      };
      setTourSchedule(tourJson.shareTourScheduleState);

      setSearchingOption({
        ...transferSearchingOptionMoment,
        departureDate: moment(transferSearchingOptionMoment.departureDate),
        returnDate: moment(transferSearchingOptionMoment.returnDate),
      });

      refetch();
    }
  }, [f7route.query.query]);

  useEffect(() => {
    if (f7route.query.query && !popupOpened) {
      (document.querySelector('#departureTime_picker') as HTMLInputElement).value = departureTime as string;
      (document.querySelector('#returnTime_picker') as HTMLInputElement).value = returnTime as string;
    }
  }, [departureTime, returnTime]);

  return (
    <Page name="search" className="" onPageInit={() => afterAuthenticatedProcess()}>
      <Navbar>
        <NavLeft>
          <Link icon="menu_bars" panelOpen="left" />
        </NavLeft>
        <NavTitle>{isInitialPopup.current ? '날짜를 선택하세요' : '일정'}</NavTitle>
        <NavRight>
          <Link icon="noti_bell" href="/notifications" />
        </NavRight>
      </Navbar>
      {isInitialPopup.current ? (
        <DatePopup popupOpened setPopupOpened={setPopupOpened} isInit ref={isInitialPopup} />
      ) : (
        <>
          <TimeDisplay setPopupOpened={setPopupOpened} />
          <DatePopup popupOpened={popupOpened} setPopupOpened={setPopupOpened} />
        </>
      )}
      <h1 className="text-xl font-bold mx-4 mt-10 sm:p-4">이용일정</h1>
      <hr className="m-4 text-gray-200" />
      {getDayList().map((elem, index) => (
        <DetailContainer
          key={`detail-${elem.day}-${elem.idx}`}
          searchPlaces={searchPlaces}
          day={elem.day}
          index={index}
          lastIndex={dayDiff - 1 || 1}
          last={(dayDiff - 1 || 1) === index}
        />
      ))}

      <div className="p-4">
        <div className="text-xl font-bold">총 거리: {totalDistance}km</div>
      </div>
      <hr className="m-4 text-gray-200" />
      <div>
        <Button large onClick={getResult} text="검색" className="bg-primary text-white my-6 mx-4 sm:mx-0 text-lg" />
      </div>
      {!totalDistance && !drivers && drivers?.length === 0 && (
        <EmptyList
          title="일정을 선택후 검색해 주세요"
          height="h-80"
          icon={<IoSearchOutline size={54} color="#ccc" />}
        />
      )}
      {totalDistance !== 0 && drivers && drivers?.length !== 0 && (
        <>
          <div className="flex justify-between sm:p-4">
            <div className="flex items-center">
              <Input
                type="select"
                defaultValue={sortBy.current}
                className="w-28 mx-4 px-1 border-b-2 border-primary"
                onChange={(e) => filterDrivers(e.target.value)}
              >
                <option value="createdAtDesc">최신순</option>
                <option value="peopleAsc">낮은인승순</option>
                <option value="peopleDesc">높은인승순</option>
                <option value="chargeAsc">낮은가격순</option>
              </Input>
              <IoShareOutline
                className="text-2xl z-[9999]"
                onClick={async () => {
                  const encodedTourData = Base64.encode(
                    JSON.stringify({
                      shareTourScheduleState: tourSchedule,
                      shareSearchingOptionState: searchingOption,
                    }),
                  );
                  const dynamicLink = await getSchedultDynamicLink({ encodedTourData, shareType: 'tour' });
                  shareByKakao(dynamicLink, 'tour');
                }}
              />
            </div>
            <div
              className="relative"
              onKeyPress={(e) => {
                e.key === 'Enter' && filterDrivers();
              }}
            >
              <Input
                type="text"
                defaultValue={searchBy.current}
                placeholder="기사이름 검색"
                className="w-28 h-6 mx-4 px-1 border-b-2 border-primary"
                onChange={(e) => {
                  searchBy.current = e.target.value.replace(/(\s*)/g, '');
                }}
              />
              <div className="f7-icons absolute -top-1 right-4 text-primary text-xl" onClick={filterDrivers}>
                search
              </div>
            </div>
          </div>
          <div className="grid gap-x-4 gap-y-8 grid-cols-2 px-4 mb-10 mt-4">
            {drivers.map((driver) => (
              <DriverListItem driver={driver} key={`driver-${driver.id}`} />
            ))}
            <Card style={{ height: '210px', boxShadow: 'none' }}>&nbsp;</Card>
          </div>
        </>
      )}
      {isLoading && <EmptyList title="검색중 입니다" icon={<IoReloadSharp size={54} color="#ccc" />} />}
      {showEmptyList && drivers && drivers?.length === 0 && !isDriverPresent && !isFetching && (
        <EmptyList title="검색 결과가 없습니다" icon={<IoAlertCircleOutline size={54} color="#ccc" />} />
      )}
      {totalDistance !== 0 && hasNextPage && <ListPreloader ref={targetRef} />}
      {window.isDesktop && <div>-</div>}
    </Page>
  );
};

export default React.memo(SearchPage);
