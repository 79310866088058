/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://eh6rgetm6nap3eb5y2o72ai3ly.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bgazrokjsjepbfrqcyuzsnb67a",
    "aws_cognito_identity_pool_id": "ap-northeast-2:e467de7c-660d-4b99-877e-780ea0074558",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_powQVk1La",
    "aws_user_pools_web_client_id": "4k2rq1drako9r92jrkrq4mutha",
    "oauth": {
        "domain": "work-prod.auth.ap-northeast-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://backpack-bus.barber.work/",
        "redirectSignOut": "https://backpack-bus.barber.work/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "backpackbusdev3ff52fc6d2b8468ea3342278ea216c11165134-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
