import { useCallback, useMemo, useRef } from 'react';
import { AppSyncTypes, Chatroom, CurrentUser, MessageType, UserEachSerializer } from '@interfaces';
import {
  InfiniteData,
  QueryClient,
  QueryObserverResult,
  RefetchOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import { Messages as MessagesList, Toast } from 'framework7/types';
import { InfiniteAppSync, REACT_QUERY_KEYS } from '@constants';
import {
  activateChatroomAPI,
  disableUserChatroomAPI,
  readChatroomAPI,
  updateChatroomAPI,
  createNotificationAPI,
} from '@api';
import { f7 } from 'framework7-react';
import { API } from 'aws-amplify';
import { createMessage, createNotification } from '@graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { buildNewMessage, buildNewNotification } from '@utils';
import { getMessageInfiniteQuery, getMessageListQuery, MessageSubscription } from '@appsync';
import moment from 'moment';

// enabled 때문에 안해줘도 되지만 ts 적용을 위해
const CHATROOM_ID_NEVER_USED = 'CHATROOM_ID_NEVER_USED';

type ListRef = {
  el: HTMLElement | null;
  f7Messages: () => MessagesList.Messages;
};

export interface SingleChatHooks {
  sendMessageHandler: (unTrimmedMessage: string) => Promise<void>;
  messageSubscriptionNextHandler: (subscription: MessageSubscription) => Promise<void>;
  disableUserChatroomHandler: () => Promise<void>;
  messages: MessageType[];
  $listRef: React.RefObject<ListRef>;
  infiniteQuery: Omit<UseInfiniteQueryResult<InfiniteAppSync<MessageType>, Error>, 'data'>;
}

interface ChatRoomContainerProps {
  chatroom: Chatroom | undefined;
  currentUser: CurrentUser;
  targetUserUuid: string;
  queryClient: QueryClient;
  refetchChatroom: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<Chatroom, unknown>>;
}

const useSingleChat = (props: ChatRoomContainerProps) => {
  const { chatroom, currentUser, refetchChatroom, targetUserUuid, queryClient } = props;

  const enabled = Boolean(chatroom);

  const select = ({ pages, pageParams }: InfiniteData<InfiniteAppSync<MessageType>>) => ({
    pages: [...pages].reverse(),
    pageParams: [...pageParams].reverse(),
  });

  const $listRef = useRef<ListRef>(null);

  const lastMessageCreatedAtRef = useRef<undefined | string>();

  const getNextPageParam = (lastPage: InfiniteAppSync<MessageType>) => lastPage.nextToken;

  const { data: infiniteMessageData, ...infiniteQuery } = useInfiniteQuery<InfiniteAppSync<MessageType>, Error>(
    [REACT_QUERY_KEYS.INFINITE_MESSAGES, chatroom?.id || CHATROOM_ID_NEVER_USED],
    getMessageInfiniteQuery({ room_id: chatroom?.id || CHATROOM_ID_NEVER_USED }),
    {
      getNextPageParam,
      select,
      enabled,
      cacheTime: Infinity,
      staleTime: 1000,
    },
  );

  const { data: newMessages = [], refetch: refetchNewMessages } = useQuery<MessageType[]>(
    [REACT_QUERY_KEYS.NEW_MESSAGES, chatroom?.id || CHATROOM_ID_NEVER_USED],
    getMessageListQuery({
      room_id: chatroom?.id || CHATROOM_ID_NEVER_USED,
      order: 'ASC',
      filter: {
        createdAt: {
          gt: moment(lastMessageCreatedAtRef.current).utc().format(),
        },
      },
    }),
    { enabled },
  );

  const messageSubscriptionNextHandler = useCallback(
    async (subscription: MessageSubscription) => {
      if (!chatroom) return;
      await refetchNewMessages();
      const isCurrentUserTargetUserOfNewMessage =
        currentUser.uuid !== subscription.value.data?.onCreateMessageFilterChatroom?.user_id;
      if ($listRef.current) {
        const currentHeight = $listRef.current?.el?.offsetHeight || 0;
        $listRef.current.f7Messages().scroll(0, currentHeight);
        if (isCurrentUserTargetUserOfNewMessage) readChatroomAPI(chatroom.id);
      }
    },
    [chatroom, currentUser.uuid, refetchNewMessages],
  );

  const disableUserChatroomHandler = useCallback(async () => {
    if (!chatroom) return;
    let toast: undefined | Toast.Toast;
    try {
      await disableUserChatroomAPI(chatroom.id);
      await queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS);
      f7.views.current.router.back();
      toast = f7.toast.create({
        text: '채팅방을 나갔습니다',
        position: 'center',
        closeTimeout: 2000,
        destroyOnClose: true,
      });
    } catch {
      toast = f7.toast.create({
        text: '문제가 발생 했습니다. 잠시 후 다시 시도해주세요',
        position: 'center',
        closeTimeout: 2000,
        destroyOnClose: true,
      });
    } finally {
      if (toast) toast.open();
    }
  }, [chatroom, queryClient]);

  const createMessageMutation = useMutation<
    AppSyncTypes.CreateMessageMutation['createMessage'],
    unknown,
    AppSyncTypes.CreateMessageInput
  >(async (message) => {
    const result: GraphQLResult<AppSyncTypes.CreateMessageMutation> = await API.graphql({
      query: createMessage,
      variables: { input: message },
    });

    return result.data?.createMessage;
  });

  const createNotificationMutation = useMutation<unknown, unknown, AppSyncTypes.CreateNotificationInput>(
    async (notification) => {
      const response = await API.graphql({ query: createNotification, variables: { input: notification } });
      return response.data.createNotification;
    },
  );

  const createNotificationHandler = useCallback(
    (newMessageData: AppSyncTypes.CreateMessageMutation['createMessage']) => {
      if (!newMessageData) return;
      try {
        const newNotificationParams = buildNewNotification(newMessageData, currentUser);
        createNotificationMutation.mutate(newNotificationParams);
        queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS);
      } catch (error) {
        console.log(error);
      }
    },
    [createNotificationMutation, currentUser, queryClient],
  );

  const createMessageHandler = useCallback(
    async ({ message, unTrimmedMessage, roomId }: { message: string; unTrimmedMessage: string; roomId: string }) => {
      const newMessage = buildNewMessage({ message, targetUserUuid, currentUser, roomId });
      createMessageMutation.mutate(newMessage, { onSuccess: createNotificationHandler });
      await updateChatroomAPI(roomId, unTrimmedMessage);
      await createNotificationAPI({ message: '메시지가 도착했습니다', userUuid: targetUserUuid, roomId });
    },
    [createMessageMutation, createNotificationHandler, currentUser, targetUserUuid],
  );

  const sendMessageHandler = useCallback(
    async (unTrimmedMessage: string) => {
      try {
        const message = unTrimmedMessage.replace(/\n/g, '<br>').trim();
        if (!message.length) return;
        let targetUser: UserEachSerializer | undefined;
        switch (Boolean(chatroom)) {
          case true:
            targetUser = chatroom?.users.find((v) => v.id !== currentUser.id);

            if (!targetUser) throw new Error('상대방을 찾을 수 없습니다');
            if ((chatroom?.user_chatrooms_count || 0) < 2) {
              await activateChatroomAPI((chatroom as Chatroom).id);
              await refetchChatroom();
            }
            await createMessageHandler({ message, unTrimmedMessage, roomId: chatroom.id });
            break;
          default:
            f7.preloader.show();
            await createMessageHandler({ message, unTrimmedMessage, roomId: chatroom.id });
            await refetchChatroom();
            f7.preloader.hide();
            break;
        }
      } catch (error) {
        f7.preloader.hide();
        f7.dialog.alert((error as Error).message);
      }
    },
    [chatroom, createMessageHandler, currentUser.id, refetchChatroom],
  );

  const infiniteMessages = useMemo(
    () => infiniteMessageData?.pages.flatMap((v) => v.items) || [],
    [infiniteMessageData],
  );

  const messages: MessageType[] = useMemo(() => [...infiniteMessages, ...newMessages], [infiniteMessages, newMessages]);

  lastMessageCreatedAtRef.current = infiniteMessages[infiniteMessages.length - 1]?.createdAt;

  return {
    sendMessageHandler,
    messageSubscriptionNextHandler,
    messages,
    $listRef,
    disableUserChatroomHandler,
    infiniteQuery,
  };
};

export default useSingleChat;
