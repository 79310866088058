/* eslint-disable @typescript-eslint/no-shadow */
import { setMainViewHash } from '@js/utils';
import React, { useState, useCallback, useEffect } from 'react';
import { Popup, Button, Page, Navbar, NavLeft, NavTitle, Link, Block, Icon, Toolbar } from 'framework7-react';
import moment, { Moment } from 'moment';
import TimePicker from '@components/search/TimePicker';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  searchingOptionDepartureTimeSelector,
  searchingOptionReturnTimeSelector,
  searchingOptionState,
  tourScheduleState,
} from '@atoms';
import { converTimeFormat, isArrivalAfterDepart } from '@utils';
import Calendar from '@components/search/Calendar';
import { useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';

const DatePopUp = ({ popupOpened = true, setPopupOpened, isInit = false }, ref: React.MutableRefObject<boolean>) => {
  const queryClient = useQueryClient();
  const [searchingOption, setSearchingOption] = useRecoilState(searchingOptionState);
  const setTourSchedule = useSetRecoilState(tourScheduleState);
  const { departureDate, returnDate } = searchingOption;
  const [startDate, setStartDate] = useState<Moment | null | Date>(departureDate);
  const [endDate, setEndDate] = useState<Moment | null | Date>(returnDate);
  const [departureTime, setDepartureTime] = useRecoilState(searchingOptionDepartureTimeSelector);
  const [returnTime, setReturnTime] = useRecoilState(searchingOptionReturnTimeSelector);
  const resetCalendar = () => {
    (document.querySelector('#departureTime_picker') as HTMLInputElement).value = '오전 10 : 00';
    (document.querySelector('#returnTime_picker') as HTMLInputElement).value = '오후 05 : 00';
    setStartDate(moment());
    setEndDate(null);
    setDepartureTime('오전 10 : 00');
    setReturnTime('오후 05 : 00');
  };

  const setTourInforms = useCallback(() => {
    setMainViewHash('out');
    const set = {} as any;
    const [departureDate, returnDate] = [startDate, endDate];
    set.departureDate = departureDate;
    set.returnDate = '';
    set.totalDistance = 0;
    if (startDate !== null || endDate !== null) {
      set.returnDate = returnDate;
      const days = [];
      const dayDiff = returnDate ? moment(returnDate).diff(moment(departureDate), 'days') + 1 : 0;
      [...Array(dayDiff)].forEach((day, index) => {
        days.push({
          day: moment(departureDate).add(index, 'days').format('YY년 MM월 D일'),
          idx: index,
        });
      });

      if (days.length === 1) {
        days.push({ ...days[0], idx: 1 });
      }
      setTourSchedule(days.map((elem) => ({ ...elem, stopOvers: [] }), []));
    }
    setSearchingOption((prev) => ({ ...prev, ...set }));
    queryClient.setQueryData([REACT_QUERY_KEYS.DRIVER], { pages: [], pageParams: [undefined] });
    isInit && (ref.current = false);
  }, [startDate, endDate]);

  const isInputInvalid = useCallback(() => {
    const isEndDateInserted = endDate !== null;
    let isReturnAfterDepart = false;
    const convertedDepartureTime = converTimeFormat(departureTime);
    const convertedReturnTime = converTimeFormat(returnTime);

    if (
      (startDate as Moment)?.format('YYYY-MM-DD') === (endDate as Moment)?.format('YYYY-MM-DD') &&
      isArrivalAfterDepart(convertedDepartureTime, convertedReturnTime)
    ) {
      isReturnAfterDepart = true;
    } else if (startDate < endDate) {
      isReturnAfterDepart = true;
    }

    return !(isEndDateInserted && isReturnAfterDepart);
  }, [departureTime, returnTime, startDate, endDate]);

  useEffect(() => {
    setStartDate(departureDate);
    setEndDate(returnDate);
  }, [searchingOption.departureDate, searchingOption.returnDate]);

  return (
    <Popup
      className={`popup-tablet-fullscreen date-picker-popup ${isInit && 'home-date-picker'}`}
      animate
      opened={popupOpened}
      onPopupClosed={() => setPopupOpened(false)}
    >
      <Page>
        <Navbar>
          <NavLeft>
            <Link popupClose>
              <Icon f7="multiply" />
            </Link>
          </NavLeft>
          <NavTitle>이용 날짜를 선택하세요</NavTitle>
        </Navbar>
        {/* <Block className="h-24 mt-4 text-center mx-10">
          <div className="text-lg font-bold">주의사항</div>
          <div className="mt-2 text-xs">* 최대 예약 가능 기간은 1년을 초과할 수 없습니다. *</div>
          <div className="text-xs">* 서비스 예약 가능 기간은 6개월을 초과할 수 없습니다. *</div>
        </Block> */}
        <Calendar startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        <Block className="pb-4">
          <div className="flex justify-evenly">
            <div className="border-b-2 h-10 text-sm text-center truncate pt-4 w-5/12 leading-4">
              가는 날: {moment(startDate)?.format('YYYY년 M월 DD일')}
            </div>
            <div className="border-b-2 h-10 text-sm text-center truncate pt-4 w-5/12 leading-4">
              오는 날: {endDate ? moment(endDate)?.format('YYYY년 M월 DD일') : '-'}
            </div>
          </div>
          <div className="flex justify-evenly">
            <TimePicker el="departureTime" setter={setDepartureTime} timeValue={departureTime} />
            <TimePicker el="returnTime" setter={setReturnTime} timeValue={returnTime} />
          </div>
        </Block>
        <div className="gray-border mb-4" />
        <Block className={`px-4 ${isInit && 'datepopup'}`} style={{ height: '60px' }}>
          <div className="w-full flex justify-between">
            <Button text="날짜취소" className="text-lg py-4" large outline onClick={resetCalendar} />
            <Button
              popupClose
              text="확인"
              large
              className="text-lg py-4 bg-primary w-full ml-2"
              fill
              onClick={setTourInforms}
              disabled={isInputInvalid()}
            />
          </div>
        </Block>
      </Page>
    </Popup>
  );
};

export default React.forwardRef(DatePopUp);
