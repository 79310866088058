import { API, graphqlOperation } from '@aws-amplify/api';
import { Observable } from 'zen-observable-ts';
import { AppSyncTypes } from '@interfaces';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub';
import { onCreateMessageFilterChatroom, onNotificationCreateRecevier } from '../../graphql/subscriptions';
import * as queries from '../../graphql/queries';

interface MessagesParams {
  room_id: string;
  order?: string;
  limit?: number;
  filter?: any;
}

// Queries
export const getMessageListQuery =
  ({ room_id, order = 'DESC', limit = null, filter }: MessagesParams) =>
  async ({ pageParam = null }) => {
    const params = {
      room_id,
      sortDirection: order,
      nextToken: pageParam,
      limit,
      ...(filter || {}),
    };
    const {
      data: {
        messagesByDate: { items },
      },
    } = await API.graphql(graphqlOperation(queries.messagesByDate, params));
    // items.reverse();

    return items;
  };

export const getMessageInfiniteQuery =
  ({ room_id, order = 'DESC', limit = 20, filter }: MessagesParams) =>
  async ({ pageParam = null }) => {
    const params = {
      room_id,
      sortDirection: order,
      nextToken: pageParam,
      limit,
      ...(filter || {}),
    };
    const {
      data: {
        messagesByDate: { items, nextToken },
      },
    } = await API.graphql(graphqlOperation(queries.messagesByDate, params));
    items.reverse();
    return { items, nextToken };
  };

// Mutations

export interface MessageSubscription {
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<AppSyncTypes.OnCreateMessageFilterChatroomSubscription>;
}

export const getMessageSubscription = (room_id: string) => {
  const subscription: Observable<MessageSubscription> = API.graphql(
    graphqlOperation(onCreateMessageFilterChatroom, { room_id }),
  );
  return subscription;
};

export interface NotificationSubscription {
  provider: AWSAppSyncRealTimeProvider;
  value: GraphQLResult<AppSyncTypes.OnNotificationCreateRecevierSubscription>;
}

export const getNotificationSubscription = (currentUserUuid: string) => {
  const subscription: Observable<NotificationSubscription> = API.graphql(
    graphqlOperation(onNotificationCreateRecevier, { receiver_id: currentUserUuid }),
  );
  return subscription;
};
