import { getBus } from '@api';
import DriverEditForm from '@components/drivers/driverEditForm';
import { Link, Navbar, Page } from 'framework7-react';
import React from 'react';
import { useQuery } from 'react-query';
import backIcon from '@assets/images/back_icon.png';

const DriverEditManagePage = ({ id, f7router }) => {
  const { data: bus, isLoading } = useQuery(['driverManage', id], () => getBus(id), {
    enabled: !!id,
  });

  return (
    <Page noToolbar>
      <Navbar title="소속 기사 수정" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      {isLoading ? (
        <div>정보 로딩중...</div>
      ) : (
        <div>
          <DriverEditForm f7router={f7router} bus={bus} />
        </div>
      )}
    </Page>
  );
};

export default DriverEditManagePage;
