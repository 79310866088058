import React from 'react';
import { Link, Navbar, Page } from 'framework7-react';
import { getRandomPassword } from '@utils';
import backIcon from '@assets/images/back_icon.png';

const UserTypeSelectPage = ({ f7route, f7router }) => {
  const urlParams = new URLSearchParams({
    ...f7route.query,
    meta_data: JSON.stringify(JSON.parse(f7route?.query?.meta_data)),
  }).toString();
  return (
    <Page noToolbar>
      <Navbar title="계정유형 선택">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="mx-12 py-14 text-center text-lg text-gray-700">계정 유형을 선택해주세요</div>
      <div>
        <div className="flex justify-between text-center mx-20">
          <div className="text-center">
            <a href={`/users/sign_up/normal?${urlParams}`}>
              <div className="f7-icons text-6xl text-primary">person</div>
              <br />
              <span className="text-sm font-semibold">승객</span>
            </a>
          </div>
          <div className="text-center">
            <a href={`/users/sign_up/driver?${urlParams}`}>
              <div className="fas fa-bus text-6xl text-primary" />
              <br />
              <span className="text-sm font-semibold">기사님</span>
            </a>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default UserTypeSelectPage;
