import React from 'react';
import ReactDOM from 'react-dom';
import Framework7React from 'framework7-react';
import Framework7 from '@js/framework7-custom';
import '@styles/framework7-custom.less';
import '@styles/icons.css';
import '@styles/app.less';
import App from '@components/App';
import Amplify from 'aws-amplify';
import * as Sentry from '@sentry/react';
import i18n from './assets/lang/i18n';
import awsconfig from './aws-exports';
import 'lodash';

import { BrowserTracing } from '@sentry/tracing';

Amplify.configure({
  ...awsconfig,
});

const globalAny: any = global;
globalAny.i18next = i18n;

if (process.env.NODE_ENV === 'production') {
  window.console.log = () => {};

  Sentry.init({
    dsn: 'https://88e4c0b922ae4121bd4b96a525a90f7f@o4504336386293760.ingest.sentry.io/4504393352478720',
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.0,
  });
}

Framework7.use(Framework7React);
ReactDOM.render(React.createElement(App), document.getElementById('app'));
