import DesktopPanel from '@components/shared/DesktopPanel';
import { deleteReservationInformationAtLocalStorage, setMainViewHash } from '@js/utils';
import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { f7, Views, View, Toolbar, Link } from 'framework7-react';
import LeftPanel from '@components/shared/LeftPanel';
import useAuth from '@hooks/useAuth';
import LandingPage from '@pages/landing';
import IntroPage from '@pages/intro';
import { Auth } from '@aws-amplify/auth';
import { onNotificationCreateRecevier } from '@graphql/subscriptions';
import { API, graphqlOperation } from 'aws-amplify';
import { createInAppNotification, emptyFunc, getPlatform, innerNotification, IosAppState } from '@utils';
import { Observable } from 'zen-observable-ts';
import Cookies from 'js-cookie';
import { getNotificationSubscription, NotificationSubscription } from '@appsync';
import { readChatroomAPI, updateUserToken } from '@api';
import { useQueryClient } from 'react-query';
import { CUSTOM_EVENTS, REACT_QUERY_KEYS } from '@constants';
import { AppInterface } from '@utils/interfaces';

declare global {
  interface Window {
    receiveIosState?: (state: IosAppState) => void;
  }
}

const F7Views = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { authenticateUser, unAuthenticateUser, currentUser, signOutUser } = useAuth();
  const queryClient = useQueryClient();
  const platform = useMemo(() => getPlatform(), []);
  const graphqlSubscription = useRef<ZenObservable.Subscription | null>(null);
  const [isTabHome, setIsTabHome] = useState(true);

  const next = useCallback(
    async (subscription: NotificationSubscription) => {
      if (currentUser.isAuthenticated === false) return;
      const notification = subscription.value.data?.onNotificationCreateRecevier;
      if (!notification) return;

      const isCurrentUserReceiver = notification?.receiver_id === currentUser.uuid;
      const isInChatroom = notification.redirect_to === f7.views.current.router.currentRoute.path;

      if (isInChatroom) await readChatroomAPI(notification.target_id || '');
      queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS);

      if (!isCurrentUserReceiver || isInChatroom) return;
      createInAppNotification(notification);
      queryClient.refetchQueries([REACT_QUERY_KEYS.NEW_MESSAGES, notification.target_id]);
    },
    [currentUser, queryClient],
  );

  useEffect(() => {
    if (currentUser.isAuthenticated === false) return emptyFunc;
    graphqlSubscription.current = getNotificationSubscription(currentUser.uuid).subscribe({ next });
    return () => {
      graphqlSubscription.current?.unsubscribe();
    };
  }, [currentUser, next]);

  useEffect(() => {
    if (platform !== 'IOS') return emptyFunc;

    if (currentUser.isAuthenticated === false) {
      window.receiveIosState = emptyFunc;
      return emptyFunc;
    }

    window.receiveIosState = (event) => {
      const isCurrentViewInChatroom = f7.views.current.router.currentRoute.url.match(/users\/(.*)\/chatroom/);
      switch (event) {
        case 'didBecomeActive':
          if (isCurrentViewInChatroom) {
            window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.SUBSCRIBE_CHATROOM));
          }
          graphqlSubscription.current = getNotificationSubscription(currentUser.uuid).subscribe({ next });
          queryClient.invalidateQueries(REACT_QUERY_KEYS.MY_CHATROOMS);
          queryClient.invalidateQueries(REACT_QUERY_KEYS.NEW_MESSAGES);
          break;
        case 'willResignActive':
          if (isCurrentViewInChatroom) {
            window.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.UNSUBSCRIBE_CHATROOM));
          }
          graphqlSubscription.current?.unsubscribe();
          graphqlSubscription.current = null;
          break;
        default:
          break;
      }
    };

    return () => {
      window.receiveIosState = emptyFunc;
    };
  }, [currentUser]);

  useEffect(() => {
    async function getUserTokenAndUpdate() {
      const device_type = (AppInterface.isAndroid() && 'android') || (AppInterface.isIos() && 'ios') || null;
      const device_token = await AppInterface.getFCMToken();
      if (typeof device_token === 'string' && currentUser.isAuthenticated)
        updateUserToken(currentUser.id, { device_type, device_token });
    }
    getUserTokenAndUpdate();
  }, [currentUser]);

  useEffect(() => {
    (window as any).f7 = f7;

    if (window.localStorage.getItem('isProcessingWithUnAuthenticated')) {
      const schedules = JSON.parse(window.localStorage.getItem('tourSchedule')) || [];
      if (schedules.length <= 1) {
        deleteReservationInformationAtLocalStorage();
        return;
      }

      setIsTabHome(false);
    }
  }, []);

  const handleSignout = async () => {
    f7.dialog.confirm('로그아웃 하시겠어요?', async () => {
      await signOutUser();
      window.location.replace('/');
    });
  };

  const normalViews = (
    <Views tabs className="safe-areas mobile-view">
      <Toolbar tabbar labels bottom noHairline>
        <Link tabLink="#view-home" tabLinkActive={isTabHome} icon="bus-home" text="홈" />
        <Link tabLink="#view-search" tabLinkActive={!isTabHome} icon="bus-calendar" text="검색" />
        <Link tabLink="#view-rooms" icon="bus-chatting" text="채팅" />
        <Link tabLink="#view-mypage" icon="bus-user" text="마이페이지" />
      </Toolbar>
      <View
        id="view-home"
        name="home"
        main
        tab
        tabActive={isTabHome}
        url="/"
        iosDynamicNavbar={false}
        onTabShow={() => setMainViewHash('in')}
      />
      <View
        id="view-search"
        name="search"
        tab
        tabActive={!isTabHome}
        url="/search"
        onTabShow={() => setMainViewHash('in')}
      />

      <View id="view-rooms" name="rooms" tab url="/rooms" onTabShow={() => setMainViewHash('in')} />
      <View id="view-mypage" name="mypage" tab url="/mypage" onTabShow={() => setMainViewHash('in')} />
    </Views>
  );

  const driverViews = (
    <Views tabs className="safe-areas mobile-view">
      <Toolbar tabbar labels bottom noHairline>
        <Link tabLink="#view-home" tabLinkActive={isTabHome} icon="bus-home" text="홈" />
        <Link tabLink="#view-search" tabLinkActive={!isTabHome} icon="bus-calendar" text="검색" />
        <Link tabLink="#view-rooms" icon="bus-chatting" text="채팅" />
        <Link tabLink="#view-mypage" icon="bus-user" text="마이페이지" />
      </Toolbar>
      <View
        id="view-home"
        name="home"
        main
        tab
        tabActive={isTabHome}
        url="/"
        iosDynamicNavbar={false}
        onTabShow={() => setMainViewHash('in')}
      />
      <View
        id="view-search"
        name="search"
        tab
        tabActive={!isTabHome}
        url="/search"
        onTabShow={() => setMainViewHash('in')}
      />
      <View id="view-rooms" name="rooms" tab url="/rooms" onTabShow={() => setMainViewHash('in')} />
      <View id="view-mypage" name="mypage" tab url="/mypage" onTabShow={() => setMainViewHash('in')} />
    </Views>
  );

  const singleView = (
    <View
      main
      id="view-single"
      browserHistory
      browserHistorySeparator=""
      browserHistoryInitialMatch
      browserHistoryOnLoad={false}
      browserHistoryStoreHistory={false}
      animate={false}
    />
  );

  const renderViews = () => {
    const url = window.location.href;
    if (url.includes('kakaoShareType') && (url.includes('driverId') || url.includes('encodedTourData'))) {
      window.localStorage.setItem('skip-intro', 'true');
    }

    if (window.isDesktop) {
      return singleView;
    }

    // 모바일인 경우에만, 한번 본 경우 스킵
    const showIntro = !window.isDesktop && window.localStorage.getItem('skip-intro') !== 'true';
    if (showIntro) {
      return <IntroPage />;
    }
    if (currentUser.isAuthenticated && currentUser.user_type === 'normal') {
      return normalViews;
    }
    if (currentUser.isAuthenticated && currentUser.user_type === 'driver') {
      return driverViews;
    }
    return normalViews;
  };

  const renderPanel = () => {
    if (window.isDesktop) {
      return <DesktopPanel handleLogout={handleSignout} currentUser={currentUser} />;
    }

    return (
      <LeftPanel handleLogout={handleSignout} isLoggedIn={currentUser.isAuthenticated} currentUser={currentUser} />
    );
  };

  const getCognitoUserSession = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      await authenticateUser(cognitoUser);
    } catch (error) {
      unAuthenticateUser();
    } finally {
      setIsLoading(false);
    }
  }, [authenticateUser, unAuthenticateUser]);

  useEffect(() => {
    setTimeout(() => {
      setMainViewHash('in');
    }, 500);

    getCognitoUserSession();
  }, []);

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      const subscription = (
        API.graphql(
          graphqlOperation(onNotificationCreateRecevier, { receiver_id: currentUser.uuid }),
        ) as Observable<any>
      ).subscribe({
        next: (msg: any) => {
          const notification = msg.value.data.onNotificationCreateRecevier;
          if (notification?.receiver_id !== currentUser.uuid) return;
          innerNotification(notification);
        },
      });
      return () => subscription.unsubscribe();
    }
    return () => {};
  }, [currentUser]);

  if (isLoading) return <LandingPage />;

  return (
    <>
      {renderPanel()}
      {renderViews()}
    </>
  );
};

export default F7Views;
