import React from 'react';
import {
  searchingOptionDateSelector,
  searchingOptionDepartureTimeSelector,
  searchingOptionReturnTimeSelector,
} from '@atoms';
import moment from 'moment';
import 'moment/locale/ko';

import { AiOutlineCalendar } from 'react-icons/ai';
import { useRecoilValue } from 'recoil';

moment.locale('ko');

const TimeDisplay = ({ setPopupOpened }) => {
  const { departureDate, returnDate } = useRecoilValue(searchingOptionDateSelector);
  const departureTime = useRecoilValue(searchingOptionDepartureTimeSelector);
  const returnTime = useRecoilValue(searchingOptionReturnTimeSelector);
  return (
    <div className="pt-6 -mb-4 relative">
      <div className="mx-4">
        <div className="mb-4">
          <div
            className="border border-solid w-full rounded-md p-2 border-gray-200 flex items-center justify-between px-2"
            onClick={() => setPopupOpened(true)}
          >
            <div>
              <AiOutlineCalendar className="text-primary inline text-xl  pb-1 mr-1" />
              <span className="text-primary mr-6">시작일</span>
            </div>
            <span className="font-bold">{`${moment(departureDate).format('M월 DD일 (ddd)')} ${departureTime}`}</span>
          </div>
        </div>
        <div>
          <div
            className="border border-solid w-full rounded-md border-gray-200 p-2 flex items-center justify-between px-2"
            onClick={() => setPopupOpened(true)}
          >
            <div>
              <AiOutlineCalendar className="text-primary inline text-xl  pb-1 mr-1" />
              <span className="text-primary mr-6">종료일</span>
            </div>
            <span className="font-bold">
              {returnDate ? `${moment(returnDate).format('M월 DD일 (ddd)')} ${returnTime}` : ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeDisplay;
