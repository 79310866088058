import React, { useEffect } from 'react';
import { f7, ListInput } from 'framework7-react';
import { Picker } from 'framework7/types';
import clockIcon from '@assets/images/clock.png';
import calcIcon from '*.png';

declare global {
  interface Window {
    departureTime_picker: Picker.Picker;
    returnTime_picker: Picker.Picker;
  }
}

const TimePicker = (props: { el: string; setter: any; timeValue: any }) => {
  const { el, setter, timeValue } = props;
  const initialValue = timeValue ? timeValue?.replace(':', ' ').split(' ') : ['오전', '10', '00'];

  useEffect(() => {
    window[`${el}_picker`] = f7.picker.create({
      inputEl: `#${el}_picker`,
      rotateEffect: true,
      value: initialValue,
      backdrop: true,
      closeByOutsideClick: false,
      renderToolbar() {
        return `
          <div class="toolbar"> 
            <div class="toolbar-inner">
            <div class="left">
              <div>${el === 'departureTime' ? '출발시간' : '종료시간'}</div>
            </div>
            <div class="right">
              <a href="#" class="link sheet-close popover-close">완료</a>
            </div>
            </div>
          </div>`;
      },
      formatValue(values) {
        return `${values[0]} ${values[1]}:${values[2]}`;
      },
      cols: [
        {
          textAlign: 'left',
          values: ['오전', '오후'],
        },
        {
          textAlign: 'left',
          values: '01 02 03 04 05 06 07 08 09 10 11 12'.split(' '),
        },
        {
          values: '00 10 20 30 40 50'.split(' '),
        },
      ],
      on: {
        open(picker) {
          if (timeValue === undefined) {
            picker.setValue(['오전', '10', '00']);
          }
        },
        change(_picker, value: Array<string>, _displayValues) {
          const displayValue = `${value[0]} ${value[1]}:${value[2]}`;
          setter(() => displayValue);
        },
        closed(picker) {
          if (el === 'departureTime') {
            window.returnTime_picker.open();
          }
        },
      },
    });
  }, []);

  return (
    <div className="search-input flex items-center">
      <div>
        <img src={clockIcon} alt="" width="18px" className="mx-2" />
      </div>
      <input
        readOnly
        type="text"
        placeholder="시간을 선택해주세요"
        id={`${el}_picker`}
        style={{ marginLeft: '8px' }}
        className="input-with-value w-full"
      />
    </div>
  );
};

export default TimePicker;
