import { CurrentUser } from '@interfaces';
import { f7 } from 'framework7-react';
import { useEffect } from 'react';
import { SetterOrUpdater } from 'recoil';

interface redirectQuery {
  redirect_to: string;
}

const useRedirect = (f7routeQuery: redirectQuery) => {
  useEffect(() => {
    if (f7routeQuery.hasOwnProperty('redirect_to')) {
      switch (f7routeQuery.redirect_to) {
        case 'view-reservations':
          f7.tab.show('#view-reservations', true);
          break;
        default:
          console.log(f7routeQuery);
      }
    }

    window.history.replaceState({}, document.title, '/');
  }, [f7routeQuery]);
};

export default useRedirect;
