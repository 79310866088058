import { AppInterface } from '@utils/interfaces';
import { signupAPI } from '@api';
import { AuthenticateUser } from '@hooks/useAuth';
import { KakaoLoginSuccessHandler } from '@interfaces';
import { Auth } from 'aws-amplify';
import { chain, sampleSize } from 'lodash';
import { AppleLoginSuccessHandler } from '../interfaces/AppleLogin';

const customChallengeSignIn = async (username: string, clientMetadata: any) => {
  try {
    console.log('try');
  } catch (err: any) {
    if (err.code !== 'UsernameExistsException') {
      return false;
    }
  }
  let user;
  const createChallengeResponse = await Auth.signIn(username, undefined);
  if (createChallengeResponse.challengeName === 'CUSTOM_CHALLENGE') {
    user = await Auth.sendCustomChallengeAnswer(createChallengeResponse, 'sns_login', clientMetadata);
    return user;
  }
  return false;
};

export const kakaoLoginSuccessHandler =
  (authenticateUser: AuthenticateUser): KakaoLoginSuccessHandler =>
  async ({ response, profile }) => {
    const {
      id: username,
      kakao_account: { email = `${username}@backpack-bus.com` },
    } = profile;
    const clientMetadata = chain({ ...response, ...profile })
      .reduce((pre, curr, key) => ({ [key]: typeof curr === 'string' ? `${curr}` : JSON.stringify(curr), ...pre }), {})
      .value();
    console.log(clientMetadata);
    const user = await customChallengeSignIn(email, clientMetadata);
    if (user) {
      authenticateUser(user);
      window.location.replace('/');
    }
  };

export const appleLoginSuccessHandler = ({ authenticateUser, clientMetadata, email }) => {
  (async () => {
    const user = await customChallengeSignIn(email, clientMetadata);
    if (user) {
      authenticateUser(user);
      window.location.replace('/?apple_login_status=end');
    }
  })();
};
