import { currency } from '@js/utils';
import React from 'react';

const ClacInfo = ({ driver }) => {
  const { basic_km, night_begin, night_end } = driver;
  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <div className="">기본운행거리</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{currency(basic_km)}</span>km
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="">심야 시작시각</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{night_begin}</span>시
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="">심야 종료시각</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{night_end}</span>시
        </div>
      </div>
    </div>
  );
};

export default ClacInfo;
