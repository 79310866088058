/* eslint-disable @typescript-eslint/no-use-before-define */
import { signupAPI } from '@api';
import AgreeCheckboxes from '@components/shared/AgreeCheckboxes';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { f7, Navbar, Page, List, ListInput, Button, Link } from 'framework7-react';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { AxiosError } from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import i18next from 'i18next';
import * as Yup from 'yup';
import useAuth from '@hooks/useAuth';
import usePhoneCertification from '@hooks/usePhoneCertification';
import { AmplifySignUpParams, NormalSignUpParams } from '@interfaces';
import backIcon from '@assets/images/back_icon.png';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { showToast } from '@js/utils';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required('필수 입력사항 입니다'),
  email: Yup.string().email('유효한 이메일 주소가 아닙니다.').required('필수 입력사항 입니다'),
  password: Yup.string().min(4, '길이가 너무 짧습니다').max(50, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
  password_confirmation: Yup.string()
    .required('필수 입력사항 입니다')
    .when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('password')], '비밀번호가 일치하지 않아요'),
    }),
  phone: Yup.string()
    .min(9, '길이가 너무 짧습니다')
    .max(15, '길이가 너무 깁니다')
    .required('휴대폰 번호를 입력해주세요'),
  phone_matched: Yup.boolean().oneOf([true], '휴대폰 인증을 완료해주세요'),
  termCheck: Yup.boolean().oneOf([true], '이용약관에 동의해주세요'),
  locationCheck: Yup.boolean().oneOf([true], '이용약관에 동의해주세요'),
  privacyCheck: Yup.boolean().oneOf([true], '개인정보 보호정책에 동의해주세요'),
});

const INITIAL_SIGN_UP_PARAMS: Partial<NormalSignUpParams> = {
  user_type: 'NORMAL',
  name: '',
  email: '',
  password: '',
  password_confirmation: '',
  phone: '',
  sns_provider: '',
  sns_uuid: '',
  phoneCertificationCode: null,
  phone_matched: false,
  termCheck: false,
  locationCheck: false,
  privacyCheck: false,
};

type AmplifySignUp = (param: AmplifySignUpParams) => Promise<ISignUpResult>;

const amplifySignUp: AmplifySignUp = async (params: AmplifySignUpParams) => {
  // attributes 에는 whitelist 된 attributes 만 올 수 있음
  const user = await Auth.signUp(params);

  return user;
};

const NormalSignUpPage = ({ f7route }) => {
  const { authenticateUser } = useAuth();
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [isPasswordCheckShow, setIsPasswordCheckShow] = useState(false);

  const {
    query: {
      email = '',
      name = '',
      password = '',
      password_confirmation = '',
      login_type = 'normal',
      sns_uuid = '',
      sns_provider = '',
      meta_data = '',
    },
  } = f7route;
  const onSubmitHandler = useCallback(
    async (signUpParams: NormalSignUpParams, { setSubmitting, setFieldValue }: FormikHelpers<NormalSignUpParams>) => {
      setSubmitting(true);
      f7.preloader.show();
      let cognitoUserSession: null | CognitoUser = null; // cognito 유저 생성 여부 실패 시 null
      let isSignUpSuccess = false; // 서버 회원가입 성공 여부
      let message: string; // 에러 메시지 혹은 성공 메시지
      // amplify signup 시도
      try {
        Amplify.configure({
          aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        });
        await amplifySignUp(
          login_type === 'sns'
            ? {
                username: signUpParams.email,
                password: signUpParams.password,
                clientMetadata: JSON.parse(meta_data),
                attributes: { 'custom:provider': sns_provider },
              }
            : {
                username: signUpParams.email,
                password: signUpParams.password,
                attributes: { email: signUpParams.email },
              },
        );
        cognitoUserSession = await Auth.signIn({
          username: signUpParams.email,
          password: signUpParams.password,
        });
      } catch (error) {
        message = error.message;
        if (error.code === 'UsernameExistsException') {
          setFieldValue('email', '');
          setFieldValue('password', '');
          setFieldValue('password_confirmation', '');
          message = '이미 가입된 이메일 입니다';
        }
        setSubmitting(false);
        f7.preloader.hide();
        f7.dialog.alert(message);
      }
      // amplify signup 실패 시
      if (!cognitoUserSession) return;

      // signup api 시도
      try {
        // TODO signup api
        const { password: signUpPassword, ...apiSignUpParams } = signUpParams;
        const values = { signUpPassword, ...apiSignUpParams };
        await signupAPI(values);
        isSignUpSuccess = true;
        message = '성공적으로 가입 하였습니다';
      } catch (error) {
        console.log(error);
        message = (error as AxiosError).response?.data?.message;

        // TODO: need test amplify 유저 풀 삭제
        cognitoUserSession.deleteUser((deleteUserError) => {
          if (deleteUserError) throw deleteUserError;
          Auth.signOut({ global: true });
        });
      } finally {
        setSubmitting(false);
        f7.preloader.hide();
        f7.dialog.alert(message);
        if (isSignUpSuccess) authenticateUser(cognitoUserSession);
        window.location.replace('/');
      }
    },
    [authenticateUser],
  );

  const value = useFormik<Partial<NormalSignUpParams>>({
    // INITIAL_SIGN_UP_PARAMS,
    initialValues:
      login_type === 'normal'
        ? INITIAL_SIGN_UP_PARAMS
        : { ...INITIAL_SIGN_UP_PARAMS, email, name, password, password_confirmation, sns_provider, sns_uuid },
    validateOnMount: true,
    onSubmit: onSubmitHandler,
    validationSchema: SignUpSchema,
  });

  const { setFieldValue, handleChange, submitForm, values, isValid, handleBlur, errors, touched, isSubmitting } = value;
  const { phone, phoneCertificationCode } = values;
  const phoneCodeRef = useRef(phoneCertificationCode);
  const { certificateCode, phoneRegExp, sendPhoneCertification, checkPhoneCertification } = usePhoneCertification({
    phone,
    phoneCertificationCode: phoneCodeRef.current,
    setFieldValue,
  });

  return (
    <Page noToolbar bgColor="white">
      <Navbar noHairline title="회원가입" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <FormikProvider value={value}>
        <form
          encType="multipart/form-data"
          className="registration-form"
          onSubmit={(event) => {
            event.preventDefault();
            submitForm();
          }}
        >
          <List noHairlinesMd noHairlines>
            <h1 className="p-3 font-bold text-lg mb-2">기본 정보</h1>
            <ListInput
              label={i18next.t('login.name') as string}
              type="text"
              name="name"
              placeholder="이름을 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              errorMessageForce
              errorMessage={touched.name && errors.name}
              onInputClear={() => {
                setFieldValue('name', '');
              }}
            />
            <ListInput
              label={i18next.t('login.email') as string}
              type="email"
              name="email"
              placeholder="이메일을 입력해주세요"
              clearButton
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errorMessageForce
              errorMessage={touched.email && errors.email}
              onInputClear={() => {
                setFieldValue('email', '');
              }}
              style={login_type === 'normal' ? {} : { display: 'none' }}
            />
            <ListInput
              label={i18next.t('login.password') as string}
              type={isPasswordShow ? 'text' : 'password'}
              name="password"
              placeholder="비밀번호를 입력해주세요"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              errorMessageForce
              errorMessage={touched.password && errors.password}
              style={login_type === 'normal' ? {} : { display: 'none' }}
            >
              <span
                onClick={() => setIsPasswordShow(!isPasswordShow)}
                slot="input"
                className="absolute top-[-5px] transform translate-y-1/2 right-4 text-[17px] text-theme text-opacity-50 z-10"
              >
                {isPasswordShow ? (
                  <AiOutlineEye color="#b9c0c8" size="25" />
                ) : (
                  <AiOutlineEyeInvisible color="#b9c0c8" size="25" />
                )}
              </span>
            </ListInput>
            <ListInput
              label={i18next.t('login.password_confirmation') as string}
              type={isPasswordCheckShow ? 'text' : 'password'}
              name="password_confirmation"
              placeholder="비밀번호를 확인해주세요"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password_confirmation}
              errorMessageForce
              errorMessage={touched.password_confirmation && errors.password_confirmation}
              style={login_type === 'normal' ? {} : { display: 'none' }}
            >
              <span
                onClick={() => setIsPasswordCheckShow(!isPasswordCheckShow)}
                slot="input"
                className="absolute top-[-5px] transform translate-y-1/2 right-4 text-[17px] text-theme text-opacity-50 z-10"
              >
                {isPasswordCheckShow ? (
                  <AiOutlineEye color="#b9c0c8" size="25" />
                ) : (
                  <AiOutlineEyeInvisible color="#b9c0c8" size="25" />
                )}
              </span>
            </ListInput>
            <li className="grid grid-cols-12 gap-4">
              <div className="col-span-9">
                <ListInput
                  label="핸드폰번호"
                  type="text"
                  name="phone"
                  placeholder="'-'없이 입력해주세요"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  errorMessageForce
                  errorMessage={touched.phone && errors.phone}
                  onInputClear={() => {
                    setFieldValue('phone', '');
                  }}
                />
              </div>
              <div className="col-span-3 my-auto mr-4">
                <Button
                  disabled={!phoneRegExp.test(values.phone)}
                  className="text-primary border-primary"
                  onClick={sendPhoneCertification}
                >
                  인증받기
                </Button>
              </div>
            </li>
            <li className="grid grid-cols-12 gap-4">
              <div className="col-span-9">
                <ListInput
                  label="인증번호"
                  type="number"
                  name="phoneCertificationCode"
                  placeholder="인증번호를 입력해주세요"
                  clearButton
                  onChange={(e) => {
                    phoneCodeRef.current = e.target.value;
                    setFieldValue('phoneCertificationCode', e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.phoneCertificationCode}
                  errorMessageForce
                  errorMessage={touched.phoneCertificationCode && errors.phoneCertificationCode}
                  onInputClear={() => {
                    setFieldValue('phoneCertificationCode', '');
                  }}
                />
              </div>
              <div className="col-span-3 my-auto mr-4">
                <Button
                  fill
                  onClick={checkPhoneCertification}
                  disabled={certificateCode.current === ''}
                  className="authenticate-check-button"
                >
                  인증확인
                </Button>
              </div>
            </li>
            {login_type === 'sns' && (
              <div style={{ display: 'none' }}>
                <ListInput type="string" name="sns_provider" clearButton value={values.sns_provider} />
                <ListInput type="string" name="sns_uuid" clearButton value={values.sns_uuid} />
              </div>
            )}
          </List>

          <div className="bg-gray-200 mt-9">
            <AgreeCheckboxes names={['termCheck', 'locationCheck', 'privacyCheck']} />

            <div className="p-4">
              <button
                type="submit"
                className="button button-fill  disabled:bg-gray-400 rounded-xl h-12 font-bold text-base"
                disabled={isSubmitting || !isValid}
              >
                회원가입
              </button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </Page>
  );
};

export default NormalSignUpPage;
