import { totalChargeState } from '@atoms';
import S3ImageView from '@components/images/S3ImageView';
import { Card, f7, Link } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

const DriverListItem = ({ driver }) => {
  const { id, totalCharge, bus } = driver;
  const busProfile = bus?.profiles?.sort((pr, cu) => pr.id - cu.id);

  const setFinalCharge = useSetRecoilState(totalChargeState);

  return (
    <div className="rounded-lg border border-background mx-5">
      <div
        onClick={() => {
          setFinalCharge(totalCharge);
          f7.views.current.router.navigate(`/drivers/${id}`);
        }}
        className="w-full block"
      >
        <div className="py-2 divide-solid bg-white rounded-lg">
          <div className="max-w-3xl mx-auto px-2">
            <div className="flex flex-row items-start space-x-3">
              <div>
                {busProfile && (
                  <S3ImageView className="w-40 h-40 rounded-md overflow-hidden" imageKey={busProfile[0]?.key} />
                )}
              </div>

              <div className="driver-item-tags flex flex-wrap gap-1 my-4">
                <div>{driver.bus?.bus_number || ''}</div>
                <div> {driver.bus?.people_available || ''}인승</div>
                <div>{driver?.company_name ? `${driver?.company_name}` : '개인'}</div>
                <div> {driver.bus?.bus_old || ''}년식</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverListItem;
