import S3ImageView from '@components/images/S3ImageView';
import { formatTimeAgo } from '@utils';
import { SwipeoutActions, SwipeoutButton } from 'framework7-react';
import React from 'react';

const Notification = ({ notification, swipeAction, pageMoveHandler }) => {
  const { body, createdAt, user } = notification;
  return (
    <li className="swipeout" onClick={pageMoveHandler}>
      <div className="swipeout-content grid grid-rows-2 grid-cols-10 grid-flow-col gap-1 m-4">
        <div className="row-span-2 col-span-2">
          <S3ImageView imageKey={user.profile?.key || ''} className="w-12 h-12 rounded-full overflow-hidden" />
        </div>
        <div className="row-span-2 col-span-8 text-base">
          {body}
          <p className="text-sm text-gray-400">{formatTimeAgo(createdAt)}</p>
        </div>
      </div>
      <SwipeoutActions right>
        <SwipeoutButton delete onClick={swipeAction}>
          삭제하기
        </SwipeoutButton>
      </SwipeoutActions>
    </li>
  );
};

export default Notification;
