import { TOKEN_KEY } from '@constants';
import { Button, Page, Swiper, SwiperSlide } from 'framework7-react';
import Cookies from 'js-cookie';
import { eq, last } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { AppInterface } from '@utils/interfaces';
import introFirst from '../assets/images/new_intro1.png';
import introSecond from '../assets/images/new_intro2.png';
import introThird from '../assets/images/new_intro3.png';

const IntroPage: React.FC = () => {
  useEffect(() => {
    window.localStorage.setItem('skip-intro', 'true');
  }, []);

  const introPages = useMemo(() => [introFirst, introSecond, introThird], []);

  return (
    <Page noNavbar>
      <div className="flex flex-col h-full">
        <div className="flex-auto">
          <Swiper className="h-screen absolute" spaceBetween={30} slidesPerView={1} centeredSlides observer>
            {introPages.map((page: any, i: number) => (
              <SwiperSlide key={page} className="">
                <img src={page} className="w-full inline-block intro-center" alt="" />
                {eq(page, last(introPages)) && (
                  <div className="bottom-8 px-8 fixed w-full">
                    <Button className="bg-primary w-full" large fill onClick={() => window.location.replace('/')}>
                      시작하기
                    </Button>
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(IntroPage);
