import { List, AccordionContent, ListItem } from 'framework7-react';
import React from 'react';
import { ImArrowRight, ImArrowLeft } from 'react-icons/im';

const ScheduleDisplay = ({ tourSchedule, isOpen, type = 'default' }) => (
  <>
    {tourSchedule?.map((schedule) => (
      <List key={schedule.id}>
        <ListItem
          className={`py-2 ${type === 'reservation' ? 'text-base font-thin' : 'text-lg'}`}
          accordionItem
          title={schedule.day}
          accordionItemOpened={isOpen}
        >
          <AccordionContent>
            <div className="mt-2">
              <div className="flex items-center mb-2 px-4">
                <ImArrowRight className="text-base mr-1 text-secondary" />
                <input className="pl-3 h-8 flex-1 rounded-lg bg-gray-50" value={schedule.departure} disabled />
              </div>
            </div>
            {schedule.stopOvers &&
              schedule.stopOvers?.map(
                (stopOver) =>
                  stopOver.region !== '' && (
                    <div className="flex py-2" key={`${schedule.id}-${stopOver.region}`}>
                      <div className="f7-icons text-base mr-1">placemark</div>
                      <input className="pl-3 h-8 ml-1 flex-1 rounded-lg bg-gray-50" value={stopOver.region} disabled />
                    </div>
                  ),
              )}
            <div className="flex my-2 items-center px-4">
              <ImArrowLeft className="text-base mr-1 text-secondary" />
              <input className="pl-3 h-8 flex-1 rounded-lg bg-gray-50" value={schedule.destination} disabled />
            </div>
          </AccordionContent>
        </ListItem>
      </List>
    ))}
  </>
);

export default ScheduleDisplay;
