import React from 'react';

interface EmptyListProps {
  icon?: React.ReactElement;
  title: string;
  subTitle?: string;
  height?: string;
}

const EmptyList: React.FC<EmptyListProps> = ({ icon, title, subTitle, height }) => (
  <div className={`w-full flex flex-col items-center justify-center ${height || 'h-40'}`}>
    {icon}
    <span className="text-gray-400 font-bold text-base mt-4">{title}</span>
    {subTitle && <span className="text-gray-400 font-bold text-base mt-1">{subTitle}</span>}
  </div>
);

export default EmptyList;
