import { f7, Link, List, ListInput, Navbar, Page } from 'framework7-react';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import backIcon from '@assets/images/back_icon.png';

const emailSchema = Yup.object().shape({
  email: Yup.string().email().required('필수 입력 사항입니다'),
});

const NewPasswordPage = ({ f7router }) => (
  <Page className="bg-white" noToolbar>
    <Navbar title="비밀번호 재설정" sliding>
      <Link iconOnly slot="left" back>
        <img src={backIcon} alt="" width="18px" />
      </Link>
    </Navbar>
    <Formik
      initialValues={{ email: '' }}
      onSubmit={async (values, actions) => {
        f7.preloader.show();
        try {
          Auth.forgotPassword(values.email)
            .then((data) => {
              console.log(data);
              f7.dialog.alert('재설정 이메일이 발송되었습니다!', () => {
                f7router.navigate(`/users/passwords/new?email=${values.email}`);
              });
            })
            .catch((err) => {
              console.log(err);
              const { name } = err;
              const errorMessage =
                name === 'UserNotFoundException' ? '등록되지 않은 이메일 입니다.' : '다시 시도해주세요';
              f7.dialog.alert(errorMessage);
            });
          actions.setSubmitting(false);
          f7.preloader.hide();
        } catch (error) {
          f7.preloader.hide();
          f7.dialog.alert(error.message);
        }
      }}
      validationSchema={emailSchema}
    >
      {({ values, handleSubmit, handleChange, handleBlur, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="registration-form">
          <List noHairlinesMd>
            <ListInput
              outline
              label="이메일"
              name="email"
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="아이디"
              clearButton
            />
          </List>
          <div className="p-4">
            <button
              type="submit"
              className="button button-fill button-large disabled:opacity-50"
              disabled={isSubmitting || !isValid}
            >
              메일 전송
            </button>
          </div>
        </form>
      )}
    </Formik>
  </Page>
);

export default NewPasswordPage;
