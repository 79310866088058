import React, { useMemo, useState } from 'react';
import 'react-dates/initialize';
import { DayPickerRangeController, DayPickerSingleDateController, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import '@styles/calendar.less';
import { Picker } from 'framework7/types';

declare global {
  interface Window {
    departureTime_picker: Picker.Picker;
  }
}

const Calendar = ({ startDate: start_date, endDate: end_date, setStartDate, setEndDate, handler }) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>('startDate');

  const handleDatesChange = ({ startDate, endDate = null }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    handler(startDate, endDate);
  };

  const handleFocusChange = (arg: FocusedInputShape | null) => {
    if (arg === null) {
      arg = 'startDate';
    }
    setFocusedInput(arg);
  };

  const isDayBlocked = (day: Moment): boolean => day.isBefore(moment(moment().format('YYYY-MM-DD')));

  const renderDay = (day: Moment) =>
    start_date && end_date ? (
      <>
        <div className="day-wrapper" />
        <div className="day-content">
          <div className={`day ${[0, 6].includes(day.weekday()) && 'text-day-red'}`}>{day.format('D')}</div>
        </div>
      </>
    ) : (
      <>
        <div className="day-normal" />
        <div className="day-content">
          <div className={`day ${[0, 6].includes(day.weekday()) && 'text-day-red'}`}>{day.format('D')}</div>
        </div>
      </>
    );

  const renderWeekHeaderElement = (day: string) => <div>{day}</div>;

  return (
    <div className="h-1/2 search-calendar">
      <DayPickerRangeController
        startDate={start_date}
        endDate={end_date}
        onDatesChange={handleDatesChange}
        renderWeekHeaderElement={renderWeekHeaderElement}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        orientation="verticalScrollable"
        isDayBlocked={isDayBlocked}
        minimumNights={0}
        initialVisibleMonth={() => moment()}
        monthFormat="YYYY년 M월"
        numberOfMonths={6}
        verticalHeight={800}
        noNavPrevButton
        noNavNextButton
        hideKeyboardShortcutsPanel
        renderDayContents={renderDay}
      />
    </div>
  );
};

export default Calendar;
