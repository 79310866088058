import { Base64 } from 'js-base64';
import React, { useEffect } from 'react';

type ShareDynamicLinkQuery = {
  kakaoShareType: string;
  driverId?: string;
  encodedTourData?: string;
};

const useShareDynamicLink = (f7routeQuery: ShareDynamicLinkQuery, f7router, f7) => {
  useEffect(() => {
    if (f7routeQuery.hasOwnProperty('kakaoShareType')) {
      switch (f7routeQuery.kakaoShareType) {
        case 'driver':
          f7router.navigate(`/drivers/${f7routeQuery?.driverId}`, { props: { id: f7routeQuery?.driverId } });
          break;
        case 'tour':
          f7.tab.show('#view-search', true);
          f7.views.current.router.navigate(`/search?query=${f7routeQuery?.encodedTourData}`, {
            reloadCurrent: true,
          });
          break;
        default:
          throw new Error('예상치 못한 오류가 발생하였습니다');
      }
    }
  }, [f7routeQuery]);
};

export default useShareDynamicLink;
