import { f7, Link, Navbar, NavLeft, NavTitle, Page, Input, Button, NavRight, Card } from 'framework7-react';
import { Dom7 as $$ } from 'framework7/lite-bundle';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import {
  searchingOptionState,
  searchingOptionDateSelector,
  tourScheduleState,
  totalChargeState,
  searchingOptionDepartureTimeSelector,
  searchingOptionReturnTimeSelector,
} from '@atoms';
import DetailContainer from '@components/search/DetailContainer';
import DatePopup from '@components/search/DatePopUp';
import TimeDisplay from '@components/search/timeDisplay';
import moment from 'moment';
import { useInView } from 'react-intersection-observer';
import { getDistance, getDriverDynamicLink, getDrivers, getSchedultDynamicLink } from '@api';
import { deleteReservationInformationAtLocalStorage, setMainViewHash, showToast, sleep } from '@js/utils';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import ListPreloader from '@components/shared/ListPreloader';
import { REACT_QUERY_KEYS } from '@constants';
import { IoReloadSharp, IoSearchOutline, IoAlertCircleOutline, IoShareOutline } from 'react-icons/io5';
import EmptyList from '@components/shared/EmptyList';
import useAuth from '@hooks/useAuth';
import { Base64 } from 'js-base64';
import { shareByKakao } from '@utils/shareDynamicLink';
import Calendar from '@components/search/Calendar';
import { Moment } from 'moment/moment';
import { converTimeFormat, isArrivalAfterDepart } from '@utils';
import TimePicker from '@components/search/TimePicker';
import calcIcon from '@assets/images/calc.png';
import calcGrayIcon from '@assets/images/calc_gray.png';
import resetIcon from '@assets/images/reset.png';
import searchBusImage from '@assets/images/search_bus.png';
import backIcon from '@assets/images/back_icon.png';
import dotIcon from '@assets/images/location_dot_solid.png';
import ScheduleTimeDisplay from '@components/schedule/scheduleTimeDisplay';
import searchIcon from '@assets/images/search.png';
import DriverListItem from './users/driverListItem';

const SearchConfirmPage = ({ f7router, f7route }) => {
  const queryClient = useQueryClient();
  const [searchingOption, setSearchingOption] = useRecoilState(searchingOptionState);
  const [tourSchedule, setTourSchedule] = useRecoilState(tourScheduleState);
  const { departureDate, returnDate } = searchingOption;
  const [startDate, setStartDate] = useState<Moment | null | Date>(departureDate);
  const [endDate, setEndDate] = useState<Moment | null | Date>(returnDate);
  const [departureTime, setDepartureTime] = useRecoilState(searchingOptionDepartureTimeSelector);
  const [returnTime, setReturnTime] = useRecoilState(searchingOptionReturnTimeSelector);
  const setTotalCharge = useSetRecoilState(totalChargeState);
  const { totalDistance } = searchingOption;

  const latestTourSchedule = useRef(tourSchedule);
  const [isInfinite, setIsInfinite] = useState(false);
  const searchBy = useRef('');
  const sortBy = useRef('createdAtDesc');
  const latestSearchingOption = useRef(searchingOption);
  const [showEmptyList, setShowEmptyList] = useState(false);
  const allowInfinite = useRef(true);
  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 0,
  });

  const [showResult, setShowResult] = useState(false);

  const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetching, status } = useInfiniteQuery(
    REACT_QUERY_KEYS.DRIVER,
    async ({ pageParam: page = 1 }) => {
      if (page === false) {
        return [];
      }
      const response = await getDrivers(
        { ...latestSearchingOption.current, schedule: latestTourSchedule.current },
        page,
        sortBy.current,
        searchBy.current,
      );
      return response.data;
    },
    {
      enabled: isInfinite,
      getNextPageParam: (lastPage, pages) => (pages[pages?.length - 1]?.length !== 0 ? pages.length + 1 : false),
    },
  );
  const drivers = useMemo(() => data?.pages?.flat() || '', [data]);
  const isDriverPresent: boolean = !!hasNextPage && !isLoading && data.pages.flat().length !== 0;

  const fetchNextPageAsync = useCallback(async () => {
    allowInfinite.current = false;
    await fetchNextPage();
    allowInfinite.current = true;
  }, [fetchNextPage]);

  useEffect(() => {
    f7.preloader.hide();

    if (!isTargetInView || !allowInfinite.current) return;
    fetchNextPageAsync();
  }, [isTargetInView, fetchNextPageAsync]);

  const filterDrivers = async (value = null) => {
    sortBy.current = value;
    queryClient.removeQueries(['drivers']);
    await refetch();
    allowInfinite.current = true;
  };

  const calcTotalDistance = async () => {
    if (departureDate !== null) {
      f7.preloader.show();
      data?.pages.length > 0 && (data.pages = []);

      const copiedTourSchedule = JSON.parse(JSON.stringify(tourSchedule));

      const schedulePromise = [];
      try {
        copiedTourSchedule.forEach((schedule: any) => {
          const { departure, destination, stopOvers } = schedule;
          const promise = getDistance({ departure, destination, stopOvers }).then((distance) => {
            schedule.distance = distance;
            return schedule;
          });
          schedulePromise.push(promise);
        });

        const addDistanceSchedules = await Promise.all(schedulePromise);
        const calculatedTotalDistance = addDistanceSchedules.map((el) => el.distance).reduce((acc, curr) => acc + curr);

        setSearchingOption({ ...searchingOption, totalDistance: calculatedTotalDistance });

        latestSearchingOption.current = searchingOption;
        latestTourSchedule.current = addDistanceSchedules;
        f7.preloader.hide();

        setTourSchedule(addDistanceSchedules);
        setIsInfinite(true);
        searchBy.current = '';
        await fetchNextPage();
      } catch (err) {
        await queryClient.resetQueries('drivers', { exact: true });
        setIsInfinite(false);
        f7.preloader.hide();

        if (err.response?.data?.error?.message === 'empty data exist') {
          showToast('경로를 모두 입력해주세요');
          return;
        }
        if (err.response?.data?.error?.message === 'over 1000km') {
          showToast('하루의 일정중 1000km가 넘어간 일정이 있습니다. <br> 1000km이하의 경로만 요청 가능합니다.');
          return;
        }

        if (err.response) {
          showToast('다시 검색을 시도해주세요');
        }
      }
    } else {
      showToast('일정을 모두 입력해주세요');
    }
  };

  const getResult = async () => {
    if (drivers && drivers?.length === 0 && !isDriverPresent && !isFetching) {
      setShowEmptyList(true);
    }

    if (isInfinite) {
      const accordions = $$('.accordion-item.accordion-item-opened');
      accordions.each((el) => {
        f7.accordion.close(el);
      });
      setShowResult(true);
    }
  };

  return (
    <Page name="search-confirm" noToolbar className="bg-gray" onPageAfterIn={calcTotalDistance}>
      <Navbar>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
        <NavTitle>버스정보 검색</NavTitle>
      </Navbar>

      <div className="px-4">
        <div className="my-8">
          <img src={searchBusImage} alt="" className="mx-auto w-1/2" />
          <div className="flex justify-center mt-4">
            <span className="flex items-baseline mr-2" style={{ color: '#00abeb', fontSize: '23px' }}>
              <img src={dotIcon} alt="" width={14} className="inline-block mr-2" /> 총 거리
            </span>
            <span style={{ color: '#2f3037', fontSize: '25px', fontWeight: 'bold' }}> {totalDistance}km</span>
          </div>
        </div>
        <div>
          <ScheduleTimeDisplay
            isOpen
            tourSchedule={tourSchedule}
            departureDate={departureDate}
            departureTime={departureTime}
            returnDate={returnDate}
            returnTime={returnTime}
          />
        </div>
        {!showResult && (
          <>
            <div className="my-8 text-center" style={{ color: '#676a71' }}>
              예약완료 후에는 운행기사님과 직접 소통이 가능합니다.
            </div>
            <div className="mt-8 mb-4">
              <button className="button button-fill disabled:bg-[#b9c0c8] h-11 font-bold text-base" onClick={getResult}>
                검색
              </button>
            </div>
          </>
        )}

        {showResult && totalDistance !== 0 && drivers && drivers?.length !== 0 && (
          <>
            <div className="flex justify-between py-10">
              <div className="flex items-center">
                <Input
                  type="select"
                  defaultValue={sortBy.current}
                  style={{
                    border: '1px solid #bbc2c9',
                    borderRadius: '10px',
                    padding: '12px 15px',
                    width: '150px',
                  }}
                  onChange={(e) => filterDrivers(e.target.value)}
                >
                  <option value="createdAtDesc">최신순</option>
                  <option value="peopleAsc">낮은인승순</option>
                  <option value="peopleDesc">높은인승순</option>
                  <option value="chargeAsc">낮은가격순</option>
                </Input>
              </div>
              <div
                className="relative"
                onKeyPress={(e) => {
                  e.key === 'Enter' && filterDrivers();
                }}
              >
                <Input
                  type="text"
                  defaultValue={searchBy.current}
                  placeholder="기사이름 검색"
                  className="placeholder-text-white"
                  style={{
                    background: '#00abeb',
                    borderRadius: '10px',
                    padding: '12px 15px',
                    color: 'white',
                    width: '150px',
                  }}
                  onChange={(e) => {
                    searchBy.current = e.target.value.replace(/(\s*)/g, '');
                  }}
                />

                <div className="absolute right-4" style={{ top: '50%', transform: 'translateY(-50%)' }}>
                  <img src={searchIcon} width="20" alt="" />
                </div>
              </div>
            </div>
            <div className="grid gap-y-4 grid-cols-1 mb-10">
              {drivers.map((driver) => (
                <DriverListItem driver={driver} key={`driver-${driver.id}`} withPrice />
              ))}
              <Card className="bg-gray" style={{ height: '210px', boxShadow: 'none' }}>
                &nbsp;
              </Card>
            </div>
          </>
        )}
        {isLoading && <EmptyList title="검색중 입니다" icon={<IoReloadSharp size={54} color="#ccc" />} />}
        {showEmptyList && (
          <EmptyList title="검색 결과가 없습니다" icon={<IoAlertCircleOutline size={54} color="#ccc" />} />
        )}
        {totalDistance !== 0 && showResult && hasNextPage && <ListPreloader ref={targetRef} />}
      </div>
    </Page>
  );
};

export default React.memo(SearchConfirmPage);
