import { currency } from '@js/utils';
import React from 'react';

const PriceInfo = ({ driver }) => {
  const { basic_charge, charge_per_day, service_charge } = driver;
  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <div className="">기본요금</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{currency(basic_charge)}</span>원
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="">1박시 추가요금</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{currency(charge_per_day)}</span>원
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <div className="">봉사료</div>
        <div className="">
          <span className="font-bold text-black text-sm mr-1">{currency(service_charge)}</span>원
        </div>
      </div>
    </div>
  );
};

export default PriceInfo;
