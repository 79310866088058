import { createDayoff, deleteDayoff, getDayoffUser } from '@api';
import useAuth from '@hooks/useAuth';
import { showToast } from '@js/utils';
import { Button, f7, Link, Navbar, Page, Toolbar } from 'framework7-react';
import { filter, find, includes, isEqual, map, sortBy } from 'lodash';
import moment, { Moment } from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { useQuery, useQueryClient } from 'react-query';
import backIcon from '@assets/images/back_icon.png';

const EditDayoffPage = ({ id, f7route }) => {
  const format = 'YYYY-MM-DD';
  const { currentUser } = useAuth();
  const [start_date, setStartDate] = useState(null);
  const [dates, setDates] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const { isLoading } = useQuery(['dayoff', `users-${id}`], () => getDayoffUser(id, {}), {
    enabled: currentUser.isAuthenticated,
    onSuccess: (data) => {
      const arr_date = map(data, 'startDate');
      setDates(arr_date);
      setSelectedDates(arr_date);
    },
  });
  const queryClient = useQueryClient();

  const resetCalendar = () => {
    setStartDate(null);
  };

  const submitDayoff = async () => {
    // f7.preloader.show();
    let message: string;

    const removed = filter(dates, (elem) => !includes(selectedDates, elem));
    const added = filter(selectedDates, (elem) => !includes(dates, elem));

    try {
      const params = {
        user_id: id,
        added,
        removed,
      };
      await createDayoff(params);
      await queryClient.invalidateQueries(['dayoff', `users-${id}`]);
      message = '저장되었습니다';
    } catch (error) {
      if (error.response) {
        const { data } = error.response;
        message = data.error.message;
      }
    } finally {
      f7.preloader.hide();
      resetCalendar();
      showToast(message);
    }
  };

  const [focused, setFocused] = useState<boolean>(false);
  const handleDateChange = (clickedDate: Moment) => {
    const isCheckedDate = find(selectedDates, (elem) => elem === clickedDate.format(format));
    let highlight = [];
    if (isCheckedDate) {
      highlight = filter(selectedDates, (elem) => clickedDate.format(format) !== elem);
    } else {
      setStartDate(clickedDate);
      highlight = [...selectedDates, clickedDate.format(format)];
    }

    highlight = sortBy(highlight);
    setSelectedDates(highlight);

    if (isEqual(dates, highlight)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const isDayBlocked = (day: Moment): boolean => day.isBefore(moment(moment().format(format)));
  const isDayHighlighted = (day: Moment): boolean => find(selectedDates, (elem) => elem === day.format(format));

  const renderDay = (day: Moment) =>
    start_date ? (
      <>
        <div className="day-wrapper" />
        <div>{day.format('D')}</div>
      </>
    ) : (
      <>
        <div className="day-normal" />
        <div>{day.format('D')}</div>
      </>
    );

  const renderWeekHeaderElement = (day: string) => <div>{day}</div>;

  return (
    <Page noToolbar>
      <Navbar title="휴일 관리" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <Toolbar position="bottom" className={window.isDesktop && 'initial-bottom bottom'} style={{ height: '60px' }}>
        <div className="w-full flex justify-between py-4">
          <Button text="날짜취소" className="" onClick={resetCalendar} large outline />
          <Button
            text="저장"
            className="bg-primary w-full ml-2"
            fill
            onClick={submitDayoff}
            large
            disabled={disabled}
          />
        </div>
      </Toolbar>

      {isLoading ? (
        <div>정보 로딩중...</div>
      ) : (
        <>
          <div className="p-6">
            <div className="block text-base font-semibold">전세버스 예약이 불가능 한 날짜를 선택해주세요</div>
            <div className="font-semibold text-base">{f7route.query.name}</div>
          </div>
          <div className="dayoff-calendar" style={{ maxWidth: '45rem', margin: '0 auto' }}>
            <DayPickerSingleDateController
              date={start_date}
              renderDayContents={renderDay}
              renderWeekHeaderElement={renderWeekHeaderElement}
              focused={focused}
              onFocusChange={({ focused: focus }) => setFocused(focus)}
              orientation="verticalScrollable"
              isDayBlocked={isDayBlocked}
              isDayHighlighted={isDayHighlighted}
              initialVisibleMonth={() => moment()}
              monthFormat="M월"
              numberOfMonths={2}
              hideKeyboardShortcutsPanel
              daySize={50}
              onDateChange={handleDateChange}
            />
          </div>

          {/* <List noHairlinesMd className="mt-4">
            <div className="p-3 font-semibold bg-white">휴일 정보</div>
            {map(dayoff, (date) => (
              <ListItem key={date.id} title={`${date.startDate} ~ ${date.endDate}`}>
                <Button slot="after" text="삭제" color="red" onClick={() => removeDayoff(date.id)} />
              </ListItem>
            ))}
          </List> */}
        </>
      )}
    </Page>
  );
};

export default EditDayoffPage;
