import React from 'react';

const Footer = (props) => {
  const { className = '' } = props;

  return (
    <footer className={`p-5 ${className}`}>
      <div className="font-semibold" style={{ fontSize: '13px' }}>
        폼나는누나
      </div>
      <div className="mt-3 text-xs">
        <div>대표이사 정수경 | 사업자등록번호 845-14-01277</div>
        <div>주소 전라남도 여수시 덕충2길 32, 3층 302호</div>
        <div>통신판매번호 제2020-전남여수-0116호</div>
        <div>고객센터 1533-1035</div>
        <div>
          "폼나는누나"는 통신판매중개사업자로써 "버스기사" 혹은 "버스회사"와 "회원" 간의 상품의 거래를 중개하는 플랫폼
          서비스만을 제공할 뿐, "상품을 판매하는 당사자가 아니며, 상품에 대한 정보 및 이행여부, 하자, 도난사고, 교통사고
          등에 대한 책임과 의무는 각 판매자에게 있습니다.
        </div>
        <a className="underline link" href="/users/sign_up/terms">
          약관보기
        </a>
      </div>
    </footer>
  );
};

export default Footer;
