import { cancelPayment, paymentAgencyAPI, updateReservation } from '@api';
import ScheduleDisplay from '@components/schedule/scheduleDisplay';
import { CalculateStatus, ReservationStatus } from '@constants';
import { currency, showToast } from '@js/utils';
import {
  AccordionContent,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Col,
  f7,
  Link,
  List,
  ListItem,
  Row,
} from 'framework7-react';
import moment from 'moment';
import React from 'react';
import { AiOutlineCalendar } from 'react-icons/ai';
import { BiBus, BiWon } from 'react-icons/bi';
import { IoPerson } from 'react-icons/io5';
import { FaUserTie } from 'react-icons/fa';

interface refundReservationParams {
  reservationId: number;
  total_price?: number;
  payment_amount?: number;
  driverId?: number;
}

const NormalReservation = ({ reservation, refetch, f7router }) => {
  const {
    id,
    schedules,
    status,
    calculate_status: calculateStatus,
    total_distance,
    payment_amount,
    remainPaymentMethod,
    total_price,
    people,
    departureDate,
    departureTime,
    returnDate,
    returnTime,
    driver,
    createdAt,
    review,
  } = reservation;

  const busTypeObj = {
    small: '소형',
    middle: '중형',
    large: '대형',
  };

  const { id: driverId = '', name = '', busType = '' } = driver;

  const handleReservationCancel = async (param) => {
    if (calculateStatus !== 'yet') {
      showToast('정산된 예약입니다. 취소가 불가능합니다.');
    } else if (new Date(departureDate) < new Date()) {
      showToast('출발예정일 이후에는 예약 취소가 불가능합니다.');
    } else {
      f7.dialog.confirm('예약을 취소하시겠어요?', async () => {
        f7.preloader.show();
        let message: string;
        try {
          const { reservationId, nowStatus } = param;
          const DateDiff = moment(departureDate).diff(new Date(), 'days');
          const commission = total_price * 0.2;
          const price = total_price - commission;

          if (nowStatus !== 'waiting') {
            if (DateDiff < 5) {
              await cancelPayment({ reservationId, total_price: price });
              await paymentAgencyAPI({ total_price: commission, driverId });
              message = '수수료 10%를 제외한 금액이 환불되었으며, 예약이 취소되었습니다';
            } else {
              await cancelPayment({ reservationId });
              message = '예약이 취소되었습니다';
            }
          } else message = '예약이 취소되었습니다';

          await updateReservation(param, reservationId);
        } catch (error) {
          if (typeof error.message === 'string') message = error.message;
          else message = '예상치 못한 오류가 발생하였습니다';
        } finally {
          f7.preloader.hide();
          f7.dialog.alert(message);
          refetch();
        }
      });
    }
  };

  const reservationLabel = (label: ReservationStatus) => {
    switch (label) {
      case '대기':
        return (
          <div className="text-primary text-sm font-semibold border border-solid border-primary rounded-full px-2.5">
            {label}
          </div>
        );
      case '예약 완료':
        return calculateStatus === 'yet' ? (
          <div className="text-green-500 text-sm font-semibold border border-solid border-green-500 rounded-full px-2.5">
            {label}
          </div>
        ) : (
          <div className="text-sm font-semibold border-gray-400 border border-solid rounded-full px-2.5">{label}</div>
        );
      case '예약 취소':
      case '예약 거절':
        return (
          <div className="text-red-500 text-sm font-semibold border border-solid border-red-500 rounded-full px-2.5">
            {label}
          </div>
        );
      default:
        return '';
    }
  };

  const generateReservationActionButton = (reservationStatus: ReservationStatus) => {
    switch (reservationStatus) {
      case '대기':
        return (
          <span
            className="underline text-sm font-semibold"
            onClick={() => handleReservationCancel({ reservationId: id, status: 'cancelled', nowStatus: status })}
          >
            예약취소
          </span>
        );
      case '예약 완료':
        return calculateStatus === 'yet' ? (
          <span
            className="underline text-sm font-semibold"
            onClick={() => handleReservationCancel({ reservationId: id, status: 'cancelled', nowStatus: status })}
          >
            예약취소
          </span>
        ) : (
          <span
            className="underline text-sm font-semibold"
            onClick={() => {
              review
                ? f7router.navigate(`/reviews/${review.id}`)
                : f7router.navigate(`/reviews/new?reservationId=${id}`);
            }}
          >
            {review ? '리뷰 보기' : '리뷰 작성'}
          </span>
        );
      default:
        return '';
    }
  };

  return (
    <Card
      className="bg-white mb-5 relative h-auto border rounded-lg border-background normal-reservation-card"
      key={id}
    >
      <CardHeader className="no-border">
        <div className="mt-2 w-full">
          <div className="flex items-center justify-between w-full mb-4">
            <div className="flex items-center">
              <p className="text-sm font-semibold mr-2">{moment(createdAt).format('YYYY.MM.DD')}</p>
              {reservationLabel(ReservationStatus[status])}
            </div>
            {generateReservationActionButton(ReservationStatus[status])}
          </div>
          <Link href={`/drivers/${driverId}?noToolbar=true`} className="font-bold text-base mr-1">
            {name}
          </Link>
          <span className="text-base text-gray-500">기사님</span>
          <p className="mt-1 text-sm ">
            {driver.bus?.bus_number || ''} | {driver.bus?.people_available || ''}인승
            {busType ? busTypeObj[busType] : '개인'} | {driver?.company_name || ''} {driver.bus?.bus_old || ''}년식
          </p>
        </div>
      </CardHeader>

      <CardContent>
        <Row className="mb-2">
          <Col width="30">
            <AiOutlineCalendar className="text-primary inline text-xl  pb-1 mr-2" />
            <span className="text-primary text-base">가는날</span>
          </Col>
          <Col width="70" className="text-base font-medium">
            {`${moment(departureDate).format('YYYY년 MM월 DD일')} ${departureTime}`}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col width="30">
            <AiOutlineCalendar className="text-primary inline text-xl  pb-1 mr-2" />
            <span className="text-primary text-base">오는날</span>
          </Col>
          <Col width="70" className="text-base font-medium">
            {`${moment(returnDate).format('YYYY년 MM월 DD일')} ${returnTime}`}
          </Col>
        </Row>
        <List
          accordionList
          className="pt-4"
          style={{ padding: 'var(--f7-card-content-padding-vertical) var(--f7-card-content-padding-horizontal)' }}
        >
          <ListItem accordionItem accordionItemOpened>
            <div slot="title" className="font-bold font-xl">
              상세정보
            </div>
            <AccordionContent>
              <ScheduleDisplay type="reservation" tourSchedule={schedules} isOpen />
              <div className="py-2 terms">
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <IoPerson className="text-primary text-xl mr-2" />
                    <span className="text-base">차량번호</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{driver.bus?.bus_number}</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <IoPerson className="text-primary text-xl mr-2" />
                    <span className="text-base">인원</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{people}</span>인
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center mb-4">
                    <BiBus className="text-primary text-xl mr-2" />
                    <span className="text-base">총 거리</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{total_distance}</span>km
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">견적금액</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{currency(total_price)}</span>원
                    <span className="text-sm">(VAT포함)</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">기사님 결제금액</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{currency(total_price - payment_amount)}</span>원
                    <span className="text-sm">(이용일자 현장결제)</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="mb-4 flex items-center">
                    <BiWon className="text-primary text-xl mr-2" />
                    <span className="text-base">결제 방법</span>
                  </Col>
                  <Col width="50" className="mb-4 text-base">
                    <span className="font-bold">{remainPaymentMethod === 'cash' ? '계좌이체' : '카드결제'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col width="50" className="flex items-center">
                    <FaUserTie className="text-primary text-xl mr-2" />
                    <span className="text-base">기사 정보</span>
                  </Col>
                  <Col width="50" className="text-base">
                    <span className="font-bold">{driver?.name}&nbsp;</span>기사님
                  </Col>
                </Row>
              </div>
            </AccordionContent>
          </ListItem>
        </List>
      </CardContent>
      <CardFooter className="py-6">
        <div>
          <span className="mr-2">총 금액: </span>
          <span className="text-black font-bold">{total_price?.toLocaleString()}원</span>
        </div>
      </CardFooter>
    </Card>
  );
};

export default NormalReservation;
