import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { f7, Message, Messages, MessagesTitle, Preloader } from 'framework7-react';
import 'moment/locale/ko';
import { Storage } from 'aws-amplify';
import { Chatroom, CurrentUser, UserEachSerializer } from '@interfaces';
import { getMessageSubscription } from '@appsync';
import {
  emptyFunc,
  getMessageProps,
  getMessageType,
  getPlatform,
  IosAppState,
  isSameDate,
  messageTitleFormat,
  sanitizeHtml,
  sendAtFormat,
} from '@utils';
import defaultImg from '@assets/images/profile.png';
import { SingleChatHooks } from '@hooks/useSingleChat';
import { useInView } from 'react-intersection-observer';
import { CUSTOM_EVENTS } from '@constants';

declare global {
  interface Window {
    receiveIosState?: (state: IosAppState) => void;
  }
}

interface ChatRoomMessagesProps extends Omit<SingleChatHooks, 'sendMessageHandler' | 'disableUserChatroomHandler'> {
  chatroom: Chatroom;
  currentUser: CurrentUser;
  targetUser?: UserEachSerializer;
}

const ChatRoomMessageList: React.FC<ChatRoomMessagesProps> = (props) => {
  const { chatroom, currentUser, targetUser, messageSubscriptionNextHandler, messages, infiniteQuery, $listRef } =
    props;
  const [profileImage, setProfileImage] = useState(defaultImg);
  const graphqlSubscription = useRef<ZenObservable.Subscription | null>(null);
  const platform = useMemo(() => getPlatform(), []);

  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 0.8,
  });

  const loadProfileImage = useCallback(async () => {
    if (!targetUser?.profile_image?.key) return;
    const src = await Storage.get(`resized/${targetUser.profile_image?.key}`, { level: 'public' });
    Object.assign(new Image(), { src, onload: () => setProfileImage(src) });
  }, [targetUser]);

  const fetchNextPageHandler = useCallback(async () => {
    const prevHeight = $listRef.current?.el?.offsetHeight || 0;
    await infiniteQuery.fetchNextPage();
    const currHeight = $listRef.current?.el?.offsetHeight || 0;
    if ($listRef.current) $listRef.current.f7Messages().scroll(0, currHeight - prevHeight);
  }, [$listRef, infiniteQuery]);

  const navigateUserPage = useCallback(() => {
    if (!targetUser) {
      f7.toast
        .create({
          text: '상대를 찾을 수 없습니다',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true,
        })
        .open();
      return;
    }
    f7.views.current.router.navigate(`/users/${targetUser.id}`, { props: { user: targetUser, isMyPage: false } });
  }, [targetUser]);

  const isNextPageFetchable =
    infiniteQuery.hasNextPage && !infiniteQuery.isLoading && !infiniteQuery.isFetchingNextPage;

  useEffect(() => {
    loadProfileImage();
  }, [loadProfileImage]);

  useEffect(() => {
    if (!isTargetInView || !isNextPageFetchable) return;
    fetchNextPageHandler();
  }, [fetchNextPageHandler, isNextPageFetchable, isTargetInView]);

  useEffect(() => {
    const subscription = getMessageSubscription(chatroom.id).subscribe({
      next: messageSubscriptionNextHandler,
    });
    return () => subscription.unsubscribe();
  }, [chatroom, currentUser.id, messageSubscriptionNextHandler]);

  useEffect(() => {
    graphqlSubscription.current = getMessageSubscription(chatroom.id).subscribe({
      next: messageSubscriptionNextHandler,
    });

    return () => {
      graphqlSubscription.current?.unsubscribe();
    };
  }, [chatroom]);

  const subscribeChatroomHandler = useCallback(() => {
    if (graphqlSubscription.current) return;

    graphqlSubscription.current = getMessageSubscription(chatroom.id).subscribe({
      next: messageSubscriptionNextHandler,
    });
  }, [chatroom]);

  // IOS 구독 취소 커스텀 이벤트 핸들러
  const unSubscribeChatroomHandler = useCallback(() => {
    if (!graphqlSubscription.current) return;
    graphqlSubscription.current.unsubscribe();
    graphqlSubscription.current = null;
  }, []);

  useEffect(() => {
    if (platform !== 'IOS') return emptyFunc;
    // 구독, 구독취소 커스텀 이벤트 등록 (위의 최상단 뷰에서 구현 참고)
    window.addEventListener(CUSTOM_EVENTS.SUBSCRIBE_CHATROOM, subscribeChatroomHandler);
    window.addEventListener(CUSTOM_EVENTS.UNSUBSCRIBE_CHATROOM, unSubscribeChatroomHandler);

    return () => {
      // 클린업
      window.removeEventListener(CUSTOM_EVENTS.SUBSCRIBE_CHATROOM, subscribeChatroomHandler);
      window.removeEventListener(CUSTOM_EVENTS.UNSUBSCRIBE_CHATROOM, unSubscribeChatroomHandler);
    };
  }, []);

  return (
    <>
      {isNextPageFetchable && (
        <div className="flex justify-center items-center h-12" ref={targetRef}>
          <Preloader size={32} />
        </div>
      )}

      {infiniteQuery.isLoading && (
        <div className="flex justify-center items-center h-12">
          <Preloader size={32} />
        </div>
      )}

      {Boolean(messages.length) && (
        <Messages className="mx-2" ref={$listRef}>
          {messages.map((message, index) => (
            <React.Fragment key={message.id}>
              {!isSameDate({ message, previousMessage: messages[index - 1] }) && (
                <MessagesTitle>{messageTitleFormat(message)}</MessagesTitle>
              )}
              <Message
                {...getMessageProps({
                  message,
                  previousMessage: messages[index - 1],
                  nextMessage: messages[index + 1],
                  currentUserUuid: currentUser.uuid,
                })}
                onClickAvatar={navigateUserPage}
                avatar={
                  getMessageType({ message, currentUserUuid: currentUser.uuid }) === 'received'
                    ? profileImage
                    : undefined
                }
              >
                <span slot="text">{sanitizeHtml(message.text)}</span>
                <div slot="end" className="mx-1 text-gray-300">
                  {Number(message.view) > 0 && message.view}
                  <br />
                  {sendAtFormat(message)}
                </div>
              </Message>
            </React.Fragment>
          ))}
        </Messages>
      )}
    </>
  );
};

export default ChatRoomMessageList;
