import { getNotices } from '@api';
import EmptyList from '@components/shared/EmptyList';
import ReactQueryState from '@components/shared/ReactQueryState';
import { sleep } from '@js/utils';
import { Link, List, ListItem, Navbar, Page } from 'framework7-react';
import React, { useMemo } from 'react';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useQuery } from 'react-query';
import backIcon from '@assets/images/back_icon.png';
import moment from 'moment';

const NoticeIndexPage = () => {
  const { status, data, error, refetch, isFetching } = useQuery('notices', getNotices);
  const onRefresh = async (done) => {
    await refetch();
    done();
  };

  const notices = useMemo(() => data?.results?.flat() || '', [data]);

  return (
    <Page className="bg-white" noToolbar ptr ptrMousewheel onPtrRefresh={onRefresh}>
      <Navbar title="공지사항">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="sm:min-h-full">
        <ReactQueryState data={notices} status={status} error={error} isFetching={isFetching} />
        {data && data.results.length > 0 ? (
          <List mediaList className="m-0">
            {notices.map((notice) => (
              <ListItem
                key={notice.id}
                link={`/notices/${notice.id}/`}
                title={notice.title}
                subtitle={moment(notice.createdAt).format('YYYY/MM/DD')}
              />
            ))}
          </List>
        ) : (
          <EmptyList title="등록된 공지사항이 없습니다." />
        )}
      </div>
    </Page>
  );
};

export default NoticeIndexPage;
