import { Link, Navbar, Page } from 'framework7-react';
import React, { useRef } from 'react';
import ReservationList from '@components/reservations/List';
import backIcon from '@assets/images/back_icon.png';

const DriverPastReservationIndexPage = () => {
  const reservationListRef = useRef(true);

  return (
    <Page>
      <Navbar title="운행목록">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <ReservationList query="done" calculate_status="done" ref={reservationListRef} />
    </Page>
  );
};

export default DriverPastReservationIndexPage;
