import React, { useEffect } from 'react';
import { RiAppleFill } from 'react-icons/ri';
import { getDevice } from 'framework7';
import { configs } from '@config';

const { APPLE_REDIRECT_URL } = configs;

declare global {
  interface Window {
    AppleID: any;
    webkit: any;
  }
}

export const AppleLoginButton = () => {
  const onClick = () => {
    window.AppleID.auth.init({
      clientId: 'com.baenangbus.serviceid',
      scope: 'name email',
      redirectURI: APPLE_REDIRECT_URL,
      state: 'baenangbus_d6e9_sign_in',
    });
    const device = getDevice();
    if (device.ios) {
      try {
        window.webkit.messageHandlers.appleLoginButtonClick.postMessage('');
      } catch (error) {
        console.log('The native context does not exist yet');
      }
    }
  };

  return (
    <>
      <a
        className="button button-large text-white external cursor-pointer w-full inline-flex justify-center py-5 text-[15px] rounded-lg align-items-center font-medium"
        style={{ backgroundColor: '#000000', alignItems: 'center' }}
        onClick={onClick}
      >
        <RiAppleFill size="20" />
        <span className="ml-2">Apple로 계속하기</span>
      </a>
      <div
        id="appleid-signin"
        className="signin-button display-none"
        data-color="black"
        data-type="sign in"
        style={{ display: 'none' }}
      />
    </>
  );
};
