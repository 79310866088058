import { setMainViewHash } from '@js/utils';
import { width } from 'dom7';
import React, { useCallback } from 'react';
import {
  Page,
  List,
  SwipeoutActions,
  SwipeoutButton,
  f7,
  Navbar,
  NavLeft,
  Link,
  NavTitle,
  NavRight,
} from 'framework7-react';
import { useQuery } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import { disableUserChatroomAPI, getChatrooms } from '@api';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '@atoms';
import { Chatroom, F7Route, UserEachSerializer } from '@interfaces';
import S3ImageView from '@components/images/S3ImageView';
import { sleep } from '@utils';
import { Toast } from 'framework7/types';
import EmptyList from '@components/shared/EmptyList';
import { IoChatbubblesOutline } from 'react-icons/io5';

interface ChatroomCardProps {
  chatroom: Chatroom;
  targetUser: UserEachSerializer | undefined;
  isCurrentUserLastMessaged: boolean;
  navigateChatroomHandler: () => void;
  disableUserChatroomHandler: () => void;
}

const ChatroomCard: React.FC<ChatroomCardProps> = ({
  chatroom,
  isCurrentUserLastMessaged,
  targetUser,
  navigateChatroomHandler,
  disableUserChatroomHandler,
}) => (
  <li className="swipeout flex border-b border-gray-100 mx-4 py-2 relative ">
    <div className="flex items-center swipeout-content" style={{ width: '100%' }} onClick={navigateChatroomHandler}>
      <S3ImageView className="w-12 h-12 rounded-xl overflow-hidden mr-2" imageKey={targetUser?.profile_image?.key} />
      <div className="flex flex-col">
        <div className="text-bold">
          <div className="mr-1">{targetUser?.name || '상대없음'}</div>
          <div className="truncate">{chatroom.last_message_body}</div>
        </div>
      </div>
    </div>
    {!isCurrentUserLastMessaged && chatroom.unread_count > 0 && (
      <div className="absolute right-4 text-bold bg-primary text-white flex items-center justify-center w-6 h-6 rounded-lg">
        {chatroom.unread_count}
      </div>
    )}
    <SwipeoutActions right>
      <SwipeoutButton delete onClick={disableUserChatroomHandler}>
        나가기
      </SwipeoutButton>
    </SwipeoutActions>
  </li>
);

const ChatPage: React.FC<F7Route> = ({ f7router }) => {
  const currentUser = useRecoilValue(currentUserState);
  const { data, refetch } = useQuery(REACT_QUERY_KEYS.MY_CHATROOMS, getChatrooms({}), {
    enabled: currentUser.isAuthenticated,
  });

  const chatrooms = data || [];

  const navigateChatroomHandler = useCallback(
    (user: UserEachSerializer | undefined) => {
      if (!user) return;
      f7router.navigate(`/users/${user.uuid}/chatroom`);
    },
    [f7router],
  );

  const disableUserChatroomHandler = useCallback(
    async (chatroomId: string) => {
      let toast: undefined | Toast.Toast;
      try {
        await disableUserChatroomAPI(chatroomId);
        toast = f7.toast.create({
          text: '채팅방을 나갔습니다',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true,
        });
      } catch {
        toast = f7.toast.create({
          text: '문제가 발생 했습니다. 잠시 후 다시 시도해주세요',
          position: 'center',
          closeTimeout: 2000,
          destroyOnClose: true,
        });
      } finally {
        await refetch();
        if (toast) toast.open();
      }
    },
    [refetch],
  );

  const onPtrRefreshHandler = useCallback(
    async (done: () => void) => {
      await refetch();
      sleep(600);
      done();
    },
    [refetch],
  );

  return (
    <Page
      ptr
      ptrMousewheel
      onPtrRefresh={onPtrRefreshHandler}
      onPageAfterIn={() => {
        setMainViewHash('in');
      }}
      onPageAfterOut={() => {
        setMainViewHash('out');
      }}
    >
      <Navbar>
        <NavLeft>
          <Link icon="menu_bars" panelOpen="left" />
        </NavLeft>
        <NavTitle>채팅목록</NavTitle>
        <NavRight>
          <Link icon="noti_bell" href="/notifications" />
        </NavRight>
      </Navbar>
      <div className="sm:min-h-full">
        {currentUser.isAuthenticated && (
          <List className="my-4 0">
            {chatrooms.map((chatroom) => (
              <ChatroomCard
                key={chatroom.id}
                chatroom={chatroom}
                isCurrentUserLastMessaged={currentUser.id === chatroom?.lastMessageUser?.id}
                targetUser={chatroom?.users?.find((v) => v.id !== currentUser.id)}
                navigateChatroomHandler={() =>
                  navigateChatroomHandler(chatroom?.users?.find((v) => v.id !== currentUser.id))
                }
                disableUserChatroomHandler={() => disableUserChatroomHandler(chatroom.id)}
              />
            ))}
          </List>
        )}
        {!chatrooms.length && currentUser.isAuthenticated && (
          <EmptyList title="대화중인 채팅방이 없어요" icon={<IoChatbubblesOutline size={54} color="#ccc" />} />
        )}
        {!chatrooms.length && !currentUser.isAuthenticated && (
          <EmptyList title="배낭버스에 로그인 해주세요" icon={<IoChatbubblesOutline size={54} color="#ccc" />} />
        )}
      </div>
    </Page>
  );
};

export default ChatPage;
