import React from 'react';

const LocationTerm = () => (
  <div className="px-4 py-8 flex flex-col bg-[#F3F4F6]">
    <div className="mb-2">
      <h1 className="font-bold text-lg">제1조 (목적)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이 약관은 폼나는누나(이하 "회사"라 합니다)가 제공하는 배낭버스 서비 스(이하 "서비스"라 합니다)와 관련하여,
        회사와 이용 고객 간에 서비스의 이용조건 및 절차, 회사와 회원간의 권리, 의무 및 기타 필요한 사항을 규 정함을
        목적으로 합니다. 본 약관은 PC통신, 스마트폰(안드로이드폰, 아 이폰 등) 앱 등을 이용하는 전자상거래에 대해서도 그
        성질에 반하지 않 는 한 준용됩니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제2조 (약관의 명시와 개정)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회사"는 이 약관의 내용과 상호, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 대표자의
        성명, 사업자등록번호, 통신판매업 신고번호, 연락처(전화, 전자우편 주소 등) 등을 "이용자"가 쉽게 알 수 있도록
        "사이트"의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 "이용자"가 연결화면을 통하여 보도록 할 수
        있습니다.③ "회사"는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 "사이트"의 초기화면에
        그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, "이용자"에게 불리하게 약관내용을 변경하는 경우에는
        최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "회사"는 개정 전과 개정 후 내용을 "이용자"가 알기
        쉽도록 표시합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제등에 관한 법률』, 『전자문서 및
        전자거래기본법』, 『전자서명법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관련법령을
        위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ "회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을
        중단하고 탈퇴를 요청할 수 있습니다. 다만, "이용자"가 제3항의 방법 등으로 "회사"가 별도 고지한 약관 개정 공지
        기간 내에 "회사"에 개정 약관에 동의하지 않는다는 명시적인 의사표시를 하지 않는 경우 변경된 약관에 동의한 것으로
        간주합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 『전자상거래 등에서의 소비자보호에 관한 법률』,
        『약관의 규제 등에 관한 법률』, 공정거래위원회가 정하는 『전자상거래 등에서의 소비자보호지침』 및 관계 법령 또는
        상관례에 따릅니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제3조 (관련 법령과의 관계)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        본 약관에 규정되지 않은 사항에 대해서는 위치정보의 보호 및 이용 등에 관한 법률(이하 “위치정보법”이라고 합니다),
        전기통신사업법, 정보통신망 이용촉진 및 보호 등에 관한 법률(이하 “정보통신망법”이라고 합니다), 개인정보보호법 등
        관련법령 또는 회사가 정한 서비스의 운영정책 및 규칙 등(이하 “세부지침”이라고 합니다)의 규정에 따르며, 개인정보
        처리기준 및 보호조치 등에 관한 사항은 개인정보 처리방침을 통해 공개합니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제1조 (목적)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        이 약관은 폼나는누나(이하 "회사"라 합니다)가 제공하는 배낭버스 서비 스(이하 "서비스"라 합니다)와 관련하여,
        회사와 이용 고객 간에 서비스의 이용조건 및 절차, 회사와 회원간의 권리, 의무 및 기타 필요한 사항을 규 정함을
        목적으로 합니다. 본 약관은 PC통신, 스마트폰(안드로이드폰, 아 이폰 등) 앱 등을 이용하는 전자상거래에 대해서도 그
        성질에 반하지 않 는 한 준용됩니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제4조 (이용계약의 성립)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 동의를 하고, "회사"가 정한 가입 양식에
        따라 회원정보를 기입하여 회원가입신청을 하거나 "회사"가 이러한 신청에 대하여 승인함으로써 체결됩니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만, 회원자격 상실 후 3년이 경과한
          자로서 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">2. 실명이 아니거나 타인의 명의를 이용한 경우</p>
        <p className="text-sm text-[#2f3037] pt-2">
          3. 회사가 실명확인절차를 실시할 경우에 이용자의 실명가입신청이 사실 아님이 확인된 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          4. 등록내용에 허위의 정보를 기재하거나, 기재누락, 오기가 있는 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">5. 이미 가입된 회원과 전화번호나 전자우편주소가 동일한 경우</p>
        <p className="text-sm text-[#2f3037] pt-2">
          6. 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          7. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여
          필요하다고 인정하는 경우
        </p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ 제1항에 따른 신청에 있어 "회사"는 "회원"에게 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑤ "회원"은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "회사"에 대하여 회원정보 수정 등의
        방법으로 그 변경사항을 알려야 합니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제5조 (서비스의 해지)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회원"은 언제든지 "회사"에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다. "회사"는 전항에 따른
        "회원"의 해지요청에 대해 특별한 사정이 없는 한 이를 즉시 처리합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회원"이 계약을 해지하는 경우 "회원"이 작성한 게시물은 삭제되지 않습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ "회사"는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 제7조 제2항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          2. "회원"이 "회사"나 다른 회원 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해 하는 행위를 한 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          3. 기타 "회원"이 이 약관 및 "회사"의 정책에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">4. 1년 이상 서비스를 이용한 이력이 없는 경우</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ 이용계약은 "회사"가 해지의사를 "회원"에게 통지함으로써 종료됩니다. 이 경우 "회사"는 "회원"에게 전자우편, 전화,
        팩스, 그 외 등의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 다만, "회사"는 해당 "회원"에게 해지사유에
        대한 의견진술의 기회를 부여 할 수 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제6조 (서비스의 내용)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "서비스"의 이용은 "회사"의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.
        다만, 정기 점검 등의 필요로 "회사"가 정한 날이나 시간은 제외됩니다. 정기점검시간은 서비스제공화면에 공지한 바에
        따릅니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 "서비스"의 원활한 수행을 위하여 필요한 기간을 정하여 사전에 공지하고 서비스를 중지할 수 있습니다. 단,
        불가피하게 긴급한 조치를 필요로 하는 경우 사후에 통지할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ "회사"는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 "서비스"의
        제공을 일시적으로 중단할 수 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제7조 (서비스 이용요금)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회사"는 다음과 같은 서비스를 제공합니다.
        <p className="text-sm text-[#2f3037] pt-2">1. 전세버스 대절 견적 서비스</p>
        <p className="text-sm text-[#2f3037] pt-2">2. 전세버스 대절 예약 서비스</p>
        <p className="text-sm text-[#2f3037] pt-2">3. 통근버스 대절 견적 서비스</p>
        <p className="text-sm text-[#2f3037] pt-2">4. 버스대절 관련 정보제공 서비스</p>
        <p className="text-sm text-[#2f3037] pt-2">5. 기타 "회사"가 정하는 서비스</p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회사가 제공하는 서비스는 기본적으로 유료 또는 무료입니다. 단, 별도의 유료서비스의 경우 해당 서비스에 명시된
        요금을 지불하여야 사용 가능합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ 회사는 유료서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에 합산하여
        청구할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 등 관련법령에 따릅니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑤ 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될
        수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑥ 무선서비스 이용 시 발생하는 데이터 통신료는 별도이며, 회원이 가입한 각 이동통신사의 정책에 따릅니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑦ MMS 등으로 게시물을 등록할 경우 발생하는 요금은 회원이 가입한 각 이동통신사의 정책에 따릅니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제8조 (서비스내용변경 통지 등)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회사"가 "회원"에 대한 통지를 하는 경우, "회원"이 가입신청 시 "회사"에 제출한 전자우편 주소나 휴대전화번호
        등으로 할 수 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 불특정다수 "회원"에 대한 통지의 경우, 1주일 이상 사이트에 게시함으로써 개별 통지에 갈음할 수
        있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제9조 (개인위치정보의 이용 또는 제공)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 약관에 명시한 후 개인위치정보주체의
        동의를 얻어야 합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를
        관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의
        경우에는 여 관할법원에 제기합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ 회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 이용·제공사실 확인자료를 자동
        기록·보존하며, 해당 자료는 6개월간 보관합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ 회사는 개인위치정보주체의 동의 없이 개인위치정보를 제3자에게 제공하지 아니하며, 제3자 제공 서비스를 제공하는
        경우에는 제공 받는자 및 제공목적을 사전에 개인위치정보주체에게 고지하고 동의를 받습니다. 다만, 다음의 경우는
        예외로 하고 있습니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        </p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑤ 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로
        매회 회원에게 제공받는 자, 제공 일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는
        회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">2. 회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우</p>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제10조 (개인위치정보주체의 권리)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회원은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한
        동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실
        확인자료를 파기합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회원은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는
        이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ 회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는
        경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.
        <p className="text-sm text-[#2f3037] pt-2">1. 본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</p>
        <p className="text-sm text-[#2f3037] pt-2">
          2. 본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된
          이유 및 내용
        </p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ 회원은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제11조 (법정대리인의 권리)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회사는 14세 미만의 회원에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에
        대한 동의를 당해 회원과 당해 회원의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제11조에 의한
        회원의 권리를 모두 가집니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용, 제공사실 확인자료를 이용약관에 명시 또는 고지한
        범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세 미만의 아동과 그 법정대리인의 동의를 받아야
        합니다. 단, 아래의 경우는 제외합니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          2. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우
        </p>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제12조 (8세 이하의 아동 등의 보호의무자의 권리)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회사는 아래의 경우에 해당하는 자(이하 “8세 이하의 아동 등”이라고 합니다)의 보호의무자가 8세 이하의 아동 등의
        생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
        <p className="text-sm text-[#2f3037] pt-2">1. 8세 이하의 아동</p>
        <p className="text-sm text-[#2f3037] pt-2">2. 피성년후견인 </p>
        <p className="text-sm text-[#2f3037] pt-2">
          3. 장애인복지법 제2조 제2항 제2호의 규정에 따른 정신적 장애를 가진 자로서 장애인 고용촉진 및 직업재활법 제2조
          제2호의 규정에 따라 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 따라 장애인등록을 한 자에 한합니다)
        </p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 전항의 규정에 따른 8세 이하 아동 등의 보호의무자는 해당 아동을 사실상 보호하는 자로서 다음 각 호에 해당하는
        자를 말합니다.
        <p className="text-sm text-[#2f3037] pt-2">
          1. 8세 이하의 아동의 법정대리인 또는 보호시설에 있는 미성년자의 후견 직무에 관한 법률 제3조의 규정에 따른
          후견인
        </p>
        <p className="text-sm text-[#2f3037] pt-2">2. 피성년후견인의 법정대리인</p>
        <p className="text-sm text-[#2f3037] pt-2">
          2. 피성년후견인의 법정대리인 3. 본 조 제1항 제3호의 자의 법정대리인 또는 장애인복지법 제58조 제1항 제1호의
          규정에 따른 장애인생활시설(국가 또는 지방자치단체가 설치·운영하는 시설에 한합니다)의 장, 정신보건법 제3조
          제4호의 규정에 따른 정신질환자 사회복귀시설(국가 또는 지방자치단체가 설치·운영하는 시설에 한합니다)의 장, 동법
          동조 제5호의 규정에 따른 정신요양시설의 장
        </p>
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는
        보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를
        행사할 수 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제13조 (회사의 주소 및 연락처 등)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회사의 상호, 주소 및 연락처는 아래와 같습니다. ② 회사는 개인위치정보를 적절히 관리·보호하고,
        개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를
        위치정보관리책임자로 지정해 운영하고 있으며, 위치정보관리책임자의 성명과 연락처는 아래와 같습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">상호 : 폼나는누나</p>
      <p className="text-sm text-[#2f3037] pt-2">대표 : 정수경</p>
      <p className="text-sm text-[#2f3037] pt-2">주소 : 전남 여수시 덕충2길 32, 3층 302호 </p>
      <p className="text-sm text-[#2f3037] pt-2">대표전화 : 1533-1035</p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회사는 개인위치정보를 적절히 관리·보호하고, 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을
        질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영하고 있으며, 위치정보관리책임자의 성명과 연락처는
        아래와 같습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">위치정보 관리책임자 : 정수경</p>
      <p className="text-sm text-[#2f3037] pt-2">대표전화 : 1533-1035</p>
      <p className="text-sm text-[#2f3037] pt-2">대표이메일 : form0313@baenangbus.com</p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제14조 (양도금지)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        회원의 서비스 받을 권리는 이를 양도 내지 증여하거나 담보제공 등의 목적으로 처분할 수 없습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제15조 (손해배상)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 회사가 위치정보법 제15조 내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은 회사에 대하여
        손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 회원이 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 하여 손해배상을 청구할 수 있습니다.
        이 경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제16조 (면책)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회사"는 "버스기사" 혹은 "버스회사"와 "회원" 간의 "재화 등"의 거래를 중개하는 플랫폼 서비스만을 제공할 뿐,
        "재화 등"을 판매하는 당사자가 아니며, "재화 등"에 대한 정보 및 이행여부, 하자, 도난사고, 교통사고 등에 대한
        책임은 "회원"과 "버스기사"에게 있습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 "버스기사" 혹은 "버스회사"가 게재한 정보, 자료, 사실(운전면허증, 보험증서 등 포함)의 신뢰도, 정확성
        등 내용에 관해서는 책임을 지지 않습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 서비스 제공에 관한
        책임이 면제됩니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ④ "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑤ "회사"는 "회원" 및 "버스기사"가 게재한 이용후기, 평가, 사진 등 정보/자료/사실의 신뢰도, 정확성에 대해서는
        책임을 지지 않습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑥ "회사"는 제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고한 제품 또는 서비스의 내용과 품질에 대하여
        감시할 의무 기타 어떠한 책임도 지지 아니합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑦ "회사"는 "회원"이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를
        통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑧ "회사"는 "회원"간 또는 "회원"과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ⑨ "회사" 및 "회사"의 임직원 그리고 대리인은 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는
        손해에 대해 책임을 지지 아니합니다.
        <p className="text-sm text-[#2f3037] pt-2">1. 회원 정보의 허위 또는 부정확성에 기인하는 손해</p>
        <p className="text-sm text-[#2f3037] pt-2">
          2. 서비스에 대한 접속 및 서비스의 이용과정에서 "회원"의 귀책사유로 발생하는 손해
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          3. 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해 및 제3자의 불법적인
          행위를 방지하거나 예방하는 과정에서 발생하는 손해
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          4. 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및
          기타 악성 프로그램으로 인한 손해
        </p>
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제17조 (분쟁의 조정 및 기타)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① "회사"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 고객상담 및 피해보상
        처리기구를 설치・운영합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② "회사"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한
        경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ③ "회사"와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회
        또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">제18조 (준거법 및 관할법원)</h1>
      <p className="text-sm text-[#2f3037] pt-2">
        ① 이 약관의 해석 및 회사와 회원 간의 분쟁에 대하여는 대한민국의 법을 적용합니다.
      </p>
      <p className="text-sm text-[#2f3037] pt-2">
        ② 서비스 이용 중 발생한 회원과 회사 간의 소송은 민사소송법에 의한 관할법원에 제소합니다.
      </p>
    </div>
    <div className="mb-2">
      <h1 className="font-bold text-lg">부칙</h1>
      <p className="text-sm text-[#2f3037] pt-2">제1조 (시행일) 본 약관은 2022년 02월부터 시행합니다.</p>
    </div>
  </div>
);

export default LocationTerm;
