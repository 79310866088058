import { DayPickerSingleDateController, FocusedInputShape } from 'react-dates';
import React, { useMemo, useState } from 'react';

import 'react-dates/initialize';

import moment, { Moment } from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import '@styles/calendar.less';
import { Picker } from 'framework7/types';

declare global {
  interface Window {
    departureTime_picker: Picker.Picker;
  }
}

const SingleCalendar = ({ startDate: start_date, setStartDate, setEndDate, handler }) => {
  const [focused, setFocused] = useState(true);

  const handleDatesChange = (startDate) => {
    setStartDate(startDate);
    setEndDate(startDate);
    handler(startDate);
  };

  const isDayBlocked = (day: Moment): boolean => day.isBefore(moment(moment().format('YYYY-MM-DD')));

  const renderDay = (day: Moment) =>
    start_date ? (
      <>
        <div className="day-wrapper" />
        <div className="day-content">
          <div className={`day ${[0, 6].includes(day.weekday()) && 'text-day-red'}`}>{day.format('D')}</div>
        </div>
      </>
    ) : (
      <>
        <div className="day-normal" />
        <div className="day-content">
          <div className={`day ${[0, 6].includes(day.weekday()) && 'text-day-red'}`}>{day.format('D')}</div>
        </div>
      </>
    );

  const renderWeekHeaderElement = (day: string) => <div>{day}</div>;

  return (
    <div className="h-1/2 search-calendar">
      <DayPickerSingleDateController
        date={start_date}
        onDateChange={handleDatesChange}
        focused={focused}
        onFocusChange={({ focused: f }) => setFocused(f)}
        renderWeekHeaderElement={renderWeekHeaderElement}
        orientation="verticalScrollable"
        isDayBlocked={isDayBlocked}
        initialVisibleMonth={() => moment()}
        monthFormat="YYYY년 M월"
        numberOfMonths={6}
        verticalHeight={800}
        noNavPrevButton
        noNavNextButton
        hideKeyboardShortcutsPanel
        renderDayContents={renderDay}
      />
    </div>
  );
};

export default SingleCalendar;
