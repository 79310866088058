/* eslint-disable react-hooks/rules-of-hooks */
import DriverForm from '@components/drivers/driverForm';
import { Link, Navbar, Page, Stepper } from 'framework7-react';
import { eq, map, times } from 'lodash';
import React, { useState } from 'react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import backIcon from '@assets/images/back_icon.png';

const DriverNewManagePage = ({ f7router }) => (
  <Page noToolbar>
    <Navbar title="소속 기사 등록" sliding={false}>
      <Link iconOnly slot="left" back>
        <img src={backIcon} alt="" width="18px" />
      </Link>
    </Navbar>

    <DriverForm f7router={f7router} type="new" randomEmail={Math.random().toString(36).substr(2, 11)} />
  </Page>
);

export default DriverNewManagePage;
