import { Kakao, KakaoLoginProps } from '@interfaces';
import { getRandomPassword } from '@utils';
import React, { useEffect } from 'react';
import { RiKakaoTalkFill } from 'react-icons/ri';
import { getUsers } from '@api';
import { chain } from 'lodash';
import { f7 } from 'framework7-react';

declare global {
  interface Window {
    Kakao: Kakao;
  }
}
export const loadSdk = () =>
  new Promise((resolve, reject) => {
    const js: HTMLScriptElement = document.createElement('script');

    js.id = 'kakao-sdk';
    js.src = '//developers.kakao.com/sdk/js/kakao.min.js';
    js.onload = resolve;

    document.body.append(js);
  });

export const KakaoLoginButton: React.FC<KakaoLoginProps> = (props) => {
  const { token, render } = props;
  useEffect(() => {
    (async () => {
      // await loadSdk(); index.html에서 추가해주고 있음
      if (window.Kakao?.isInitialized() === false) {
        window.Kakao.init(token);
      }
    })();
  }, []);

  const handleLoginButtonClick = () => {
    const {
      throughTalk = true,
      persistAccessToken = true,
      needProfile = true,
      useLoginForm = false,
      onSuccess,
      onFail,
      f7router,
    } = props;
    const method = useLoginForm ? 'loginForm' : 'login';

    (window.Kakao?.Auth)[method]({
      throughTalk,
      persistAccessToken,
      success: (response) => {
        window.Kakao?.API.request({
          url: '/v2/user/me',
          success: async (profile) => {
            const result = { response, profile };
            const {
              profile: {
                id: username,
                kakao_account: {
                  email = `${username}@backpack-bus.com`, // `${moment().format('YYYYMMDDSSSS')}@backpack-bus.com`,
                  profile: { nickname },
                },
              },
            } = result;

            const user = await getUsers({ q: { email_eq: email } });

            if (user.results.length !== 0) {
              const { sns_provider, email: userEmail } = user.results[0];
              if (sns_provider === 'kakao') {
                onSuccess({ ...result, email: `${userEmail}` }); // : user.results[0]?.email
              } else {
                f7.dialog.alert(`${sns_provider || '이메일'}로 가입한 유저입니다.`);
              }
            } else {
              const sns_password = getRandomPassword();
              const signUpParams = {
                email,
                name: nickname,
                password: sns_password,
                password_confirmation: sns_password,
                sns_uuid: `${username}`,
                sns_provider: 'kakao',
                login_type: 'sns',
              };
              const clientMetadata = chain({ ...response, ...profile })
                .reduce(
                  (pre, curr, key) => ({
                    [key]: typeof curr === 'string' ? `${curr}` : JSON.stringify(curr),
                    ...pre,
                  }),
                  {},
                )
                .value();
              const urlParams = new URLSearchParams(signUpParams).toString();
              f7router.navigate(`/users/sign_up/type?${urlParams}&meta_data=${JSON.stringify(clientMetadata)}`);
            }
          },
          fail: onFail,
        });
      },
      fail: onFail,
    });
  };

  if (typeof render === 'function') {
    return render({ onClick: handleLoginButtonClick });
  }

  return (
    <a
      className="button button-large text-[#381e1f] external cursor-pointer w-full inline-flex justify-center py-5 text-[15px] rounded-lg align-items-center font-medium"
      style={{ backgroundColor: '#FEE500', alignItems: 'center' }}
      onClick={handleLoginButtonClick}
    >
      <RiKakaoTalkFill size="20" />
      <span className="ml-2">카카오톡으로 계속하기</span>
    </a>
  );
};
