import { AppInterface } from '@utils/interfaces';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { getUsers } from '@api';
import { getRandomPassword } from '@utils';
import { Router } from 'framework7/types';
import { chain } from 'lodash';
import { appleLoginSuccessHandler } from '@utils/customAuthChallenge';
import { f7 } from 'framework7-react';
import { LoginResponse } from '../interfaces/AppleLogin';
import { AuthenticateUser } from './useAuth';

interface F7AppleLoginRouteQuery {
  state: string;
  id_token: string;
}

const useAppleLogin = (
  f7router: Router.Router,
  f7routeQuery: F7AppleLoginRouteQuery,
  authenticateUser: AuthenticateUser,
) => {
  useEffect(() => {
    if (f7routeQuery.hasOwnProperty('state') && f7routeQuery.hasOwnProperty('id_token')) {
      const { state, id_token } = f7routeQuery;
      window.history.replaceState({}, document.title, '/');
      if (state === 'baenangbus_d6e9_sign_in') {
        const response: LoginResponse = jwt_decode(id_token);
        const { email, sub: sns_uuid } = response;
        const loginOrRegistrateUser = async () => {
          const user = await getUsers({ q: { email_eq: email } });
          const clientMetadata = chain({ ...response })
            .reduce((pre, curr, key) => ({ [key]: `${curr}`, ...pre }), {})
            .value();

          if (user.results.length !== 0) {
            const { sns_provider } = user.results[0];
            if (sns_provider === 'apple') {
              appleLoginSuccessHandler({ authenticateUser, clientMetadata, email });
            } else {
              AppInterface.dismissSplash();
              f7.dialog.alert(`${sns_provider || '이메일'}로 가입한 유저입니다.`);
            }
          } else {
            const sns_password = getRandomPassword();
            const signUpParams = {
              email,
              name: '',
              password: sns_password,
              password_confirmation: sns_password,
              sns_uuid: `${sns_uuid}`,
              sns_provider: 'apple',
              login_type: 'sns',
            };

            const urlParams = new URLSearchParams(signUpParams).toString();
            f7router.navigate(`/users/sign_up/type?${urlParams}&meta_data=${JSON.stringify(clientMetadata)}`);
            AppInterface.dismissSplash();
          }
        };
        loginOrRegistrateUser();
      }
    } else if (f7routeQuery.hasOwnProperty('apple_login_status')) {
      AppInterface.dismissSplash();
    }
  }, []);
};

export default useAppleLogin;
