import { getBanners } from '@api';
import S3ImageView from '@components/images/S3ImageView';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { SwiperOptions } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';

const swiperDefaultProps: SwiperOptions = {
  spaceBetween: 10,
  slidesPerView: 1,
  observer: true,
  speed: 600,
  loop: true,
  autoplay: {
    delay: 5000,
  },
  pagination: false,
};

const HomeBanner = ({ f7router, type }) => {
  const { data, refetch } = useQuery(`banners-${type}`, async () => getBanners({ q: { banner_type_eq: type } }));
  const banners = useMemo(() => data?.results?.flat(), [data]);

  return (
    <div className="relative mx-4 py-3 sm:py-0 mb-2">
      <>
        <Swiper {...swiperDefaultProps} className="drop-shadow-lg">
          {banners &&
            banners?.length !== 0 &&
            banners.map((banner, idx) => (
              <SwiperSlide
                className={`${type === 'top' ? 'aspect-w-4 aspect-h-3' : 'aspect-w-16 aspect-h-9'} rounded-lg`}
                key={banner?.id || idx}
              >
                <S3ImageView
                  size="fullsize"
                  ratio="banner"
                  imageKey={banner?.image?.key || ''}
                  className="w-full h-full object-cover rounded-lg"
                  onClick={() => {
                    f7router.navigate(`/banners/${banner.id}`);
                  }}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </>
    </div>
  );
};
export default React.memo(HomeBanner);
