/* eslint-disable react-hooks/rules-of-hooks */
import { createDriverManagement, updateDriverManagement } from '@api';
import { currentUserState } from '@atoms';
import S3MultiFilePicker from '@components/files/S3MultiFilePicker';
import { MainPlaceHolder } from '@components/images';
import S3ImagePicker from '@components/images/S3ImagePicker';
import S3MultiImagePicker from '@components/images/S3MultiImagePicker';
import { configs } from '@config';
import { BusBirth, BusTypes, NightBegin, NightEnd, Regions } from '@constants';
import { showToast } from '@js/utils';
import { formatCurrency, sleep } from '@utils';
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import { AccordionContent, Block, Chip, f7, List, ListInput, ListItem, Toggle } from 'framework7-react';
import i18next from 'i18next';
import { pick, keys } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { useQueryClient } from 'react-query';
import { errorSelector, useRecoilValue } from 'recoil';
import * as Yup from 'yup';

interface DriverFormValue {
  name: string;
  email: string;
  profileImg: string;
  busNumber: string;
  busType: '대형' | '중형' | '소형';
  busOld: number;
  busProfiles: Array<any>;
  peopleAvailable: string;
  introduce: string;
  basicCharge: string;
  basicKm: string;
  chargePerKm: string;
  nightCharge: string;
  nightBegin: number;
  nightEnd: number;
  chargePerDay: string;
  serviceCharge: string;
  peakCharge: string;
  peakChargePerKm: string;
  wifi: boolean;
  sanitizer: boolean;
  fridge: boolean;
  usb: boolean;
  movie: boolean;
  audio: boolean;
  drivableRegion: string;
  company_name: string;
  files: Array<any>;
}

const UserInfoSchema = Yup.object().shape({
  peopleAvailable: Yup.number().typeError('숫자만 입력해주세요').required('필수 입력사항 입니다'),
  basicKm: Yup.number().typeError('숫자만 입력해주세요').required('필수 입력사항 입니다'),
  basicCharge: Yup.string().required('필수 입력사항 입니다'),
  chargePerKm: Yup.string().required('필수 입력사항 입니다'),
  chargePerDay: Yup.string().required('필수 입력사항 입니다'),
  serviceCharge: Yup.string().required('필수 입력사항 입니다'),
  nightBegin: Yup.string().required('필수 입력사항 입니다'),
  nightEnd: Yup.string().required('필수 입력사항 입니다'),
  nightCharge: Yup.string().required('필수 입력사항 입니다'),
  busType: Yup.string().required('필수 입력사항 입니다'),
  busOld: Yup.string().required('필수 입력사항 입니다'),
  busNumber: Yup.string().required('필수 입력사항 입니다'),
  introduce: Yup.string(),
  peakCharge: Yup.string().required('필수 입력사항 입니다'),
  peakChargePerKm: Yup.string().required('필수 입력사항 입니다'),
  wifi: Yup.boolean(),
  sanitizer: Yup.boolean(),
  fridge: Yup.boolean(),
  usb: Yup.boolean(),
  movie: Yup.boolean(),
  audio: Yup.boolean(),
  name: Yup.string().required('필수 입력사항 입니다'),
  email: Yup.string().email().required('필수 입력사항 입니다'),
  files: Yup.mixed().test('fileLength', '파일을 첨부해주세요', (value) => value.length >= 2),
});

const DriverForm = ({ f7router, bus = undefined, type, randomEmail }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [removedIds, setRemovedIds] = useState<number[]>([]);
  const removeIds = useRef(removedIds);

  const [removedKey, setRemovedKey] = useState('');
  const removeKey = useRef(removedKey);

  const queryClient = useQueryClient();

  const currentUser = useRecoilValue(currentUserState);
  const [drivableRegion, setDrivableRegion] = useState([]);
  const { company_name } = currentUser;

  const handleArrayChange = (e, arrayName) => {
    const { value } = e.target;
    const valueIndex = arrayName.indexOf(value);
    const duplicatedArr = JSON.parse(JSON.stringify(arrayName));
    if (valueIndex !== -1) {
      duplicatedArr.splice(valueIndex, 1);
      if (arrayName === drivableRegion) {
        setDrivableRegion(duplicatedArr);
      }
    } else {
      duplicatedArr.push(value);
      if (arrayName === drivableRegion) {
        setDrivableRegion(duplicatedArr);
      }
    }
  };

  useEffect(() => {
    removeIds.current = removedIds;
  }, [removedIds]);

  const initialValues: DriverFormValue = {
    name: '',
    email: `${randomEmail}@baenangbus.com`,
    profileImg: '',
    busNumber: '',
    busType: '대형',
    busOld: 2010,
    busProfiles: [],
    peopleAvailable: '',
    introduce: '',
    basicCharge: '500000',
    basicKm: '50',
    chargePerKm: '1800',
    nightCharge: '30000',
    nightBegin: 21,
    nightEnd: 4,
    chargePerDay: '600000',
    serviceCharge: '30000',
    peakCharge: '600000',
    peakChargePerKm: '1800',
    wifi: false,
    sanitizer: false,
    fridge: false,
    usb: false,
    movie: false,
    audio: false,
    drivableRegion: '',
    company_name: company_name || '',
    files: [],
  };

  return (
    <Formik
      enableReinitialize
      validateOnMount
      validationSchema={UserInfoSchema}
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }: FormikHelpers<DriverFormValue>) => {
        setSubmitting(false);
        f7.dialog.preloader('잠시만 기다려주세요...');
        try {
          if (!(values.drivableRegion.length > 0)) {
            f7.dialog.close();
            f7.dialog.alert('출발가능지역 최소 1개 지역을 선택해주세요');
            return;
          }

          bus
            ? await updateDriverManagement({
                ...values,
                removeKey: removeKey.current,
                removeIds: removeIds.current,
              })
            : await createDriverManagement({
                ...values,
                removeKey: removeKey.current,
                removeIds: removeIds.current,
              });

          await queryClient.invalidateQueries(['driverManagement', 'buses']);

          f7router.refreshPage();
          showToast('기사가 추가되었습니다');
        } catch (error) {
          let msg = '오류가 발생했습니다. 다시 시도해주세요';
          if (error.response.data?.message) {
            msg = error.response.data.message;
          } else if (error.response.data?.error?.message) {
            msg = error.response.data?.error?.message;
          }

          f7.dialog.alert(msg);
        } finally {
          f7.dialog.close();
        }
      }}
    >
      {({ handleChange, handleBlur, errors, handleSubmit, setFieldValue, values, isValid }) => (
        <Form
          encType="multipart/form-data"
          className="registration-form"
          onSubmit={(e) => {
            e.preventDefault();

            if (!isValid) {
              console.log(errors);
              f7.dialog.alert('필수 정보를 모두 입력해주세요');
              setIsOpen(() => true);
              handleSubmit(e);
              return;
            }

            [
              'basicCharge',
              'chargePerKm',
              'nightCharge',
              'chargePerDay',
              'serviceCharge',
              'peakCharge',
              'peakChargePerKm',
            ].forEach((priceType) => {
              values[priceType] = `${values[priceType]}`.replace(/[^\d.]+/g, '');
            });

            if (values.profileImg) {
              const { profileImg, ...rest } = values;
              setFieldValue(
                'profileImg',
                pick((profileImg as any)?.key !== removeKey.current ? profileImg : {}, ['key']),
              );
            }

            if (currentUser.bus?.profiles || values.busProfiles?.length) {
              const tempBusProfiles = values.busProfiles.length === 0 ? currentUser.bus.profiles : values.busProfiles;
              setFieldValue(
                'busProfiles',
                tempBusProfiles.filter((el) => removeIds.current.indexOf(el.id) === -1),
              );
            }

            setFieldValue('drivableRegion', drivableRegion);

            handleSubmit(e);
          }}
        >
          <List noHairlinesMd>
            <div className="p-3 font-semibold bg-white">기본 정보</div>
            <div className="p-3 bg-white">프로필 사진을 등록 해주세요</div>
            <div
              className="flex flex-col items-center bg-white"
              style={{
                paddingLeft:
                  'calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) - var(--menu-list-offset))',
                paddingRight:
                  'calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right) - var(--menu-list-offset))',
              }}
            >
              <S3ImagePicker
                isMultiple={false}
                initialData={undefined}
                placeholderComponent={<MainPlaceHolder maxCount={1} isImage />}
                deleteButtonComponent={<IoCloseCircleSharp size={26} className="text-black bg-white rounded-full" />}
                removeImageHandler={(key, removedS3Image) => {
                  setRemovedKey(() => key);
                  removeKey.current = key;
                }}
                containerClassName="w-full relative rounded-md overflow-hidden"
                addImageHandler={(v: any) => {
                  if (v[0]) {
                    setFieldValue('profileImg', v[0]);
                  }
                  setRemovedKey((prev) => '');
                }}
              />
              <p className="text-sm pt-3" style={{ color: '#666' }}>
                * 신뢰감을 줄 수 있는 밝은 느낌의 사진이면 좋습니다
              </p>
            </div>
            <ListInput
              disabled
              outline
              label={i18next.t('소속회사') as string}
              type="text"
              name="company_name"
              value={company_name}
            />
          </List>

          <List noHairlinesMd>
            <div className="p-3 font-semibold bg-white">버스운전자격증 (인증절차에만 사용됩니다)</div>
            <S3MultiFilePicker
              isMultiple={false}
              initialData={undefined}
              placeholderComponent={<MainPlaceHolder maxCount={1} />}
              containerClassName="flex justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md py-6 -mb-4 relative"
              deleteButtonComponent={<IoCloseCircleSharp size={26} className="text-black bg-white rounded-full" />}
              removeFileHandler={(key, removedS3File) => {
                if (removedS3File) setRemovedIds((prev) => [...prev, removedS3File.id]);
              }}
              addFileHandler={(v: any) => {
                setFieldValue('files[0]', v[0]);
              }}
            />
          </List>
          <ErrorMessage name="files" render={(msg) => <div className="mt-2 text-red-500 px-4 py-1">{msg}</div>} />

          <List noHairlinesMd>
            <div className="p-3 font-semibold bg-white">공제 가입 확인서 (인증절차에만 사용됩니다)</div>
            <S3MultiFilePicker
              isMultiple={false}
              initialData={undefined}
              placeholderComponent={<MainPlaceHolder maxCount={1} />}
              containerClassName="flex justify-center w-full h-full border-2 border-gray-300 border-dashed rounded-md py-6 -mb-4 relative"
              deleteButtonComponent={<IoCloseCircleSharp size={26} className="text-black bg-white rounded-full" />}
              removeFileHandler={(key, removedS3File) => {
                if (removedS3File) setRemovedIds((prev) => [...prev, removedS3File.id]);
              }}
              addFileHandler={(v: any) => {
                // 이미지 컬럼 변경 후 여기도 변경해야함
                setFieldValue('files[1]', v[0]);
              }}
            />
          </List>
          <ErrorMessage name="files" render={(msg) => <div className="mt-2 text-red-500 px-4 py-1">{msg}</div>} />

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="기사정보" className=" bg-white" accordionItemOpened={isOpen}>
              <AccordionContent>
                <ListInput
                  outline
                  label={i18next.t('login.name') as string}
                  type="text"
                  name="name"
                  value={values.name}
                  className="accordion-title font-bold"
                  placeholder="이름을 입력해주세요"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <ErrorMessage name="name" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
              </AccordionContent>
            </ListItem>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="차량정보" className="font-bold bg-white" accordionItemOpened={isOpen}>
              <AccordionContent>
                <div className="p-4">
                  <label className="text-sm font-semibold block pb-1">차량 사진</label>
                  <span className="text-secondary text-sm">&#x0002A;운행하는 차량의 실물을 올려주세요</span>
                </div>
                <S3MultiImagePicker
                  isMultiple
                  maxCount={10}
                  initialData={undefined}
                  placeholderComponent={<MainPlaceHolder maxCount={10} isImage />}
                  deleteButtonComponent={
                    <IoCloseCircleSharp size={26} className="text-secondary bg-white rounded-full" />
                  }
                  removeImageHandler={(key, removedS3Image) => {
                    if (removedS3Image) setRemovedIds((prev) => [...prev, removedS3Image.id]);
                  }}
                  containerClassName="w-full h-2/5 relative"
                  addImageHandler={(v: any) => {
                    setFieldValue('busProfiles', v);
                    setRemovedIds(() => []);
                  }}
                />
                <ListInput
                  label={i18next.t('차량번호') as string}
                  outline
                  type="text"
                  name="busNumber"
                  placeholder="예: 서울12가1234"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busNumber}
                />
                <ErrorMessage name="busNumber" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('가용승객수') as string}
                  outline
                  type="text"
                  name="peopleAvailable"
                  placeholder="가용 승객수를 숫자만 입력해주세요"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.peopleAvailable}
                />
                <ErrorMessage
                  name="peopleAvailable"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
                <ListInput
                  label={i18next.t('차량유형') as string}
                  outline
                  type="select"
                  name="busType"
                  // defaultValue="대형"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busType}
                >
                  {BusTypes.map((busType) => (
                    <option key={busType} value={busType}>
                      {busType}
                    </option>
                  ))}
                </ListInput>
                <ErrorMessage name="busType" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('차량연식') as string}
                  outline
                  type="select"
                  name="busOld"
                  // defaultValue="2011년식"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busOld}
                >
                  {BusBirth.map((birth) => (
                    <option key={birth} value={birth}>
                      {birth}년식
                    </option>
                  ))}
                </ListInput>
                <ErrorMessage name="busOld" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
              </AccordionContent>
            </ListItem>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="출발가능지역 (복수선택 가능)" accordionItemOpened={isOpen}>
              <AccordionContent>
                {keys(Regions).map((key) => {
                  const regions = Regions[key].map((region) => (
                    <ListItem
                      checkbox
                      key={region}
                      onChange={(e) => handleArrayChange(e, drivableRegion)}
                      value={region}
                      title={region}
                      name={`${key}-checkbox`}
                    />
                  ));

                  return (
                    <div className="accordion-list">
                      <ListItem accordionItem title={key} className="drivable-region">
                        <AccordionContent>{regions}</AccordionContent>
                      </ListItem>
                    </div>
                  );
                })}
              </AccordionContent>
            </ListItem>
            <Block strong className="ml-3 mt-1">
              {drivableRegion.map((region) => (
                <Chip outline key={region} className="mr-1 text-secondary" text={region} />
              ))}
            </Block>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="운행요금" className="font-bold bg-white" accordionItemOpened={isOpen}>
              <AccordionContent>
                <ListInput
                  label={i18next.t('기본요금') as string}
                  outline
                  type="text"
                  name="basicCharge"
                  placeholder="숫자만 입력해주세요 (예 : 300000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.basicCharge)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="basicCharge"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
                <ListInput
                  label={i18next.t('기본운행거리(km)') as string}
                  outline
                  type="text"
                  name="basicKm"
                  placeholder="숫자만 입력해주세요 (예 : 100)"
                  inputmode="numeric"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.basicKm}
                />
                <ErrorMessage name="basicKm" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('km당 단가') as string}
                  outline
                  type="text"
                  name="chargePerKm"
                  placeholder="숫자만 입력해주세요 (예 : 1000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.chargePerKm)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="chargePerKm"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
                <ListInput
                  label={i18next.t('1박 추가시 추가요금') as string}
                  outline
                  type="text"
                  name="chargePerDay"
                  placeholder="숫자만 입력해주세요 (예 : 400000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.chargePerDay)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="chargePerDay"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
                <ListInput
                  label={i18next.t('봉사료') as string}
                  outline
                  type="text"
                  name="serviceCharge"
                  placeholder="숫자만 입력해주세요 (예 : 50000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.serviceCharge)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="serviceCharge"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
              </AccordionContent>
            </ListItem>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem
              accordionItem
              title="성수기 및 할증요금(4~6월, 9~11월)"
              className="font-bold bg-white"
              accordionItemOpened={isOpen}
            >
              <AccordionContent>
                <ListInput
                  label={i18next.t('성수기 기본요금') as string}
                  outline
                  type="text"
                  name="peakCharge"
                  placeholder="숫자만 입력해주세요 (예 : 500000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.peakCharge)}
                  inputmode="numeric"
                />
                <ErrorMessage name="peakCharge" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('성수기 km당 요금') as string}
                  outline
                  type="text"
                  name="peakChargePerKm"
                  placeholder="숫자만 입력해주세요 (예 : 2000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.peakChargePerKm)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="peakChargePerKm"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
                <ListInput
                  label={i18next.t('심야시작시간') as string}
                  outline
                  type="select"
                  // defaultValue="21시"
                  name="nightBegin"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nightBegin}
                >
                  {NightBegin.map((nightBegin) => (
                    <option key={nightBegin} value={nightBegin}>
                      {nightBegin}시
                    </option>
                  ))}
                </ListInput>
                <ErrorMessage name="nightBegin" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('심야종료시간') as string}
                  outline
                  type="select"
                  // defaultValue="04시"
                  name="nightEnd"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nightEnd}
                >
                  {NightEnd.map((nightEnd) => (
                    <option key={nightEnd} value={nightEnd}>
                      0{nightEnd}시
                    </option>
                  ))}
                </ListInput>
                <ErrorMessage name="nightEnd" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
                <ListInput
                  label={i18next.t('심야시간 추가요금') as string}
                  outline
                  type="text"
                  name="nightCharge"
                  placeholder="숫자만 입력해주세요 (예 : 50000)"
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={formatCurrency(values.nightCharge)}
                  inputmode="numeric"
                />
                <ErrorMessage
                  name="nightCharge"
                  render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>}
                />
              </AccordionContent>
            </ListItem>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="편의시설" className="font-bold bg-white" accordionItemOpened={isOpen}>
              <AccordionContent>
                <ListItem title="손소독제">
                  <Toggle
                    slot="after"
                    name="sanitizer"
                    onChange={handleChange}
                    defaultChecked={values.sanitizer === true}
                  />
                </ListItem>
                <ListItem title="냉장고">
                  <Toggle slot="after" name="fridge" onChange={handleChange} defaultChecked={values.fridge === true} />
                </ListItem>
                <ListItem title="음향시설">
                  <Toggle slot="after" name="audio" onChange={handleChange} defaultChecked={values.audio} />
                </ListItem>
                <ListItem title="와이파이">
                  <Toggle slot="after" name="wifi" onChange={handleChange} defaultChecked={values.wifi} />
                </ListItem>
                <ListItem title="usb포트">
                  <Toggle slot="after" name="usb" onChange={handleChange} defaultChecked={values.usb} />
                </ListItem>
                <ListItem title="영화관람">
                  <Toggle slot="after" name="movie" onChange={handleChange} defaultChecked={values.movie} />
                </ListItem>
              </AccordionContent>
            </ListItem>
          </List>

          <List noHairlinesMd accordionList>
            <ListItem accordionItem title="자기소개" className=" bg-white">
              <AccordionContent>
                <ListInput
                  type="textarea"
                  outline
                  name="introduce"
                  placeholder="승객에게 표시되는 문구입니다."
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.introduce}
                />
                <ErrorMessage name="introduce" render={(msg) => <div className="text-red-500 px-4 py-1">{msg}</div>} />
              </AccordionContent>
            </ListItem>
          </List>
          <div className="p-4">
            <button type="submit" className="button button-fill button-large">
              기사 등록
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DriverForm;
