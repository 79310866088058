/* eslint-disable no-nested-ternary */
import { Page, Navbar, Block, List, Link } from 'framework7-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import EmptyList from '@components/shared/EmptyList';
import { useInfiniteQuery } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import { getNotofications, deleteNotification, getComment, getChatroom } from '@api';
import useAuth from '@hooks/useAuth';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useInView } from 'react-intersection-observer';
import ListPreloader from '@components/shared/ListPreloader';
import backIcon from '@assets/images/back_icon.png';
import Notification from './Notification';

const NotificationIndexPage = ({ f7router }) => {
  const { currentUser } = useAuth();
  console.log(currentUser);
  const allowInfinite = useRef(true);
  const { data, isError, error, fetchNextPage, isLoading, refetch, hasNextPage } = useInfiniteQuery(
    REACT_QUERY_KEYS.NOTIFICATIONS,
    async ({ pageParam: page = 1 }) => {
      const response = await getNotofications({ cursor: page, per: 20 });
      return response.results || [];
    },
    {
      enabled: currentUser.isAuthenticated,
      getNextPageParam: (lastPage, pages) => (pages[pages?.length - 1]?.length !== 0 ? pages.length + 1 : false),
    },
  );

  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 1,
  });

  const fetchNextPageAsync = useCallback(async () => {
    allowInfinite.current = false;
    await fetchNextPage();
    allowInfinite.current = true;
  }, [fetchNextPage]);

  useEffect(() => {
    if (!isTargetInView || !allowInfinite.current) return;
    fetchNextPageAsync();
  }, [isTargetInView, fetchNextPageAsync]);

  const notifications = useMemo(() => data?.pages?.flat() || '', [data]);

  const deleteNotificationHandler = useCallback(
    async (id) => {
      await deleteNotification(id);
    },
    [refetch],
  );

  const pageMoveHandler = useCallback(
    async (type, id) => {
      switch (type) {
        case 'NoticesEntity':
          f7router.navigate(`/notices/${id}`);
          break;
        case 'ReservationsEntity':
          f7router.navigate(`/reservations/${id}`);
          break;
        case 'ReviewsEntity':
          f7router.navigate(`/reviews/${id}`);
          break;
        case 'CommentsEntity':
          await new Promise((resolve, reject) => resolve(getComment(id))).then((comment) =>
            f7router.navigate(`/reviews/${(comment as any).review.id}`),
          );
          break;
        case 'ChatroomsEntity':
          await new Promise((resolve, reject) => resolve(getChatroom(currentUser.uuid))).then((chatroom) => {
            const user = (chatroom as any).users?.find((v) => v.id !== currentUser.id);
            if (!user) return;
            f7router.navigate(`/users/${user.uuid}/chatroom`);
          });
          break;
        default:
          console.log('done');
      }
    },
    [refetch],
  );

  return (
    <Page noToolbar>
      <Navbar title="알림 목록">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="sm:min-h-full">
        {currentUser.isAuthenticated ? (
          isLoading ? (
            <div>로딩중입니다</div>
          ) : isError ? (
            <Block>{(error as any).message}</Block>
          ) : (
            <List className="m-0 list-none">
              {notifications && notifications.length > 0 ? (
                <>
                  {notifications.map((notification) => (
                    <Notification
                      notification={notification}
                      key={notification?.createdAt}
                      swipeAction={() => deleteNotificationHandler(notification.id)}
                      pageMoveHandler={() => pageMoveHandler(notification.entityType, notification.entityId)}
                    />
                  ))}
                </>
              ) : (
                <EmptyList title="알림 내역이 없습니다." icon={<IoLockClosedOutline size={54} color="#ccc" />} />
              )}
            </List>
          )
        ) : (
          <EmptyList title="배낭버스에 로그인 해주세요" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
        )}
        {hasNextPage && <ListPreloader ref={targetRef} />}
        {window.isDesktop && <div>-</div>}
      </div>
    </Page>
  );
};

export default NotificationIndexPage;
