/* eslint-disable consistent-return */
import { getReservations } from '@api';
import EmptyList from '@components/shared/EmptyList';
import ListPreloader from '@components/shared/ListPreloader';
import { REACT_QUERY_KEYS } from '@constants';
import useAuth from '@hooks/useAuth';
import { List } from 'framework7-react';
import React, { useCallback, useEffect, useRef, useImperativeHandle } from 'react';
import { IoLockClosedOutline } from 'react-icons/io5';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import DriverReservation from './DriverReservation';

interface ReservationListProps {
  query: string;
  calculate_status?: string;
  sendInitMessageForUser?: ({ message, targetUserUuid, presentUser, roomId }) => void;
}

const ReservationList = ({ query, calculate_status, sendInitMessageForUser }: ReservationListProps, ref) => {
  const allowInfinite = useRef(true);
  const { currentUser } = useAuth();
  const { data, fetchNextPage, refetch, hasNextPage, isFetched } = useInfiniteQuery(
    [REACT_QUERY_KEYS.RESERVATION, query, calculate_status],
    async ({ pageParam: page = 1 }) => {
      if (currentUser.isAuthenticated) {
        const response = await getReservations(currentUser.email, page, query, calculate_status);
        return response || [];
      }
    },
    {
      getNextPageParam: (lastPage, pages) => (pages[pages?.length - 1]?.length !== 0 ? pages.length + 1 : false),
    },
  );

  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 1,
  });

  const reservations = data?.pages?.flat() || [];

  const fetchNextPageAsync = useCallback(async () => {
    allowInfinite.current = false;
    await fetchNextPage();
    allowInfinite.current = true;
  }, [fetchNextPage]);

  useImperativeHandle(ref, () => ({
    reload: refetch,
  }));

  useEffect(() => {
    if (!isTargetInView || !allowInfinite.current) return;
    fetchNextPageAsync();
  }, [isTargetInView, fetchNextPageAsync]);

  return (
    <>
      {!isFetched ? (
        <ListPreloader />
      ) : (
        <List className="m-0 pb-4">
          {reservations && reservations.length > 0 ? (
            <>
              {reservations.map((reservation) => (
                <DriverReservation
                  reservation={reservation}
                  refetch={refetch}
                  key={reservation?.id || ''}
                  sendInitMessageForUser={sendInitMessageForUser}
                />
              ))}
            </>
          ) : (
            <EmptyList title="예약 내역이 없습니다 :)" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
          )}
        </List>
      )}
      {hasNextPage && <ListPreloader ref={targetRef} />}
      {window.isDesktop && <div>-</div>}
    </>
  );
};

export default React.memo(React.forwardRef(ReservationList));
