import ScheduleDisplay from '@components/schedule/scheduleDisplay';
import ScheduleTimeDisplay from '@components/schedule/scheduleTimeDisplay';
import { Link, Navbar, Page, Preloader } from 'framework7-react';
import { REACT_QUERY_KEYS } from '@constants';
import { useQuery } from 'react-query';
import { getReservation } from '@api';
import React from 'react';
import NormalReservation from '@components/reservations/NormalReservation';
import backIcon from '@assets/images/back_icon.png';

const ShowReservationPage = ({ id, f7router, refetch }) => {
  const {
    data: reservation,
    isLoading,
    isError,
  } = useQuery([REACT_QUERY_KEYS.RESERVATION, id], async () => getReservation(id));

  return (
    <Page noToolbar>
      <Navbar title="예약 정보">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="sm:min-h-full pt-6">
        {isLoading && (
          <div className="h-32 flex items-center justify-center">
            <Preloader size={20} />
          </div>
        )}
        {isError && (
          <div className="h-32 flex items-center justify-center">
            <span className="text-gray-400">서버에 문제가 발생 했습니다. </span>
          </div>
        )}
        {reservation && (
          <NormalReservation
            reservation={reservation}
            refetch={refetch}
            key={reservation?.id || ''}
            f7router={f7router}
          />
        )}
      </div>
    </Page>
  );
};

export default ShowReservationPage;
