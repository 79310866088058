import React from 'react';
import { Page, Navbar, Block, Link } from 'framework7-react';
import backIcon from '@assets/images/back_icon.png';

const NotFoundPage = () => (
  <Page>
    <Navbar title="Not found">
      <Link iconOnly slot="left" back>
        <img src={backIcon} alt="" width="18px" />
      </Link>
    </Navbar>
    <Block strong>
      <p>Sorry</p>
      <p>Requested content not found.</p>
    </Block>
  </Page>
);

export default NotFoundPage;
