import { getReview } from '@api';
import SingleReview from '@components/reviews/SingleReview';
import { REACT_QUERY_KEYS } from '@constants';
import { Link, Navbar, Page, Preloader } from 'framework7-react';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import backIcon from '@assets/images/back_icon.png';

const ShowReviewPage = ({ id }) => {
  const { data, isLoading, isError } = useQuery([REACT_QUERY_KEYS.REVIEWS, id], async () => {
    const response = await getReview(id);
    return response;
  });

  const review = useMemo(() => data || '', [data]);

  return (
    <Page noToolbar>
      <Navbar title="리뷰 정보">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      {isLoading && (
        <div className="h-32 flex items-center justify-center">
          <Preloader size={20} />
        </div>
      )}
      {isError && (
        <div className="h-32 flex items-center justify-center">
          <Preloader size={20} />
        </div>
      )}
      {review && <SingleReview review={review} />}
    </Page>
  );
};

export default ShowReviewPage;
