import { Router } from 'framework7/types';
import packageJson from '../../../package.json';
import regions from './region.json';

/** 리터럴 혹은 불변 객체 */
export const TOKEN_KEY = `${packageJson.name}_TOKEN`;
export const CSRF_KEY = `${packageJson.name}_CSRF`;
export const ACTIONS = {
  NEW: 'new',
  INDEX: 'index',
  EDIT: 'edit',
  SHOW: 'show',
};

export const DEFAULT_ACTIONS = Object.values(ACTIONS);

export type IsConfirmed = 'unconfirmed' | 'pending' | 'confirmed';
export type UserType = 'normal' | 'driver' | 'company';

export const REACT_QUERY_KEYS = {
  DRIVER: 'DRIVER',
  RESERVATION: 'RESERVATION',
  CHATROOM: 'CHATROOM',
  MY_CHATROOMS: 'MY_CHATROOMS',
  INFINITE_MESSAGES: 'INFINITE_MESSAGES',
  NEW_MESSAGES: 'NEW_MESSAGES',
  NOTIFICATIONS: 'NOTIFICATIONS',
  REVIEWS: 'REVIEWS',
  COMMENTS: 'COMMENTS',
};

export interface Objects<T> {
  total_pages: number;
  total_count: number;
  objects: T[];
}

export interface PageRouteProps {
  f7route: Router.Route;
  f7router: Router.Router;
}

export type AmplifyStorageLevel = 'public' | 'protected' | 'private';
export interface InfiniteAppSync<T> {
  nextToken: string;
  items: T[];
}

export enum ReservationStatus {
  waiting = '대기',
  done = '예약 완료',
  cancelled = '예약 취소',
  rejected = '예약 거절',
  deleted = '목록에서 삭제',
}

export enum CalculateStatus {
  yet = '미정산',
  waiting = '운행 종료 대기',
  done = '운행 종료 확인',
}

export const CUSTOM_EVENTS = {
  SUBSCRIBE_CHATROOM: 'SUBSCRIBE_CHATROOM',
  UNSUBSCRIBE_CHATROOM: 'UNSUBSCRIBE_CHATROOM',
};

export const BankList = [
  '경남',
  '광주',
  '국민',
  '기업',
  '농협',
  '대구',
  '부산',
  '산업',
  '새마을',
  '산림',
  '수협',
  '신한',
  '신협',
  '씨티',
  '우리',
  '우체국',
  '저축',
  '전북',
  '제주',
  '카카오',
  '케이',
  '토스',
  '하나',
  'SC제일',
];

export const city = [
  '서울특별시',
  '인천광역시',
  '대전광역시',
  '대구광역시',
  '부산광역시',
  '광주광역시',
  '세종특별자치시',
  '울산광역시',
  '제주특별자치도',
];

export const Regions = regions;

export const NightBegin = ['21', '22', '23', '24', '1', '2', '3'];
export const NightEnd = ['4', '5', '6', '7'];
export const BusTypes = ['대형', '대형우등', '중형', '중형우등', '미니버스', '미니썬롱', '미니우등', '벤'];
export const BusBirth = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - i).reverse();

export interface CancelPaymentParams {
  total_price?: number;
  payment_amount?: number;
  reservationId: number;
}

export interface Payment {
  total_price: number;
  payment_amount?: number;
  reservationId?: string;
  status?: string;
  driverId?: number;
}
