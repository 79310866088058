import { AccordionContent, List, ListItem } from 'framework7-react';
import React, { useState } from 'react';
import moment from 'moment';
import scheduleIcon from '@assets/images/schedule.png';
import { useRecoilValue } from 'recoil';
import { onewayState } from '@atoms';

const ScheduleTimeDisplay = ({ tourSchedule, isOpen, departureDate, departureTime, returnDate, returnTime }) => {
  const startSchedule = tourSchedule[0];
  const endSchedule = tourSchedule[tourSchedule.length - 1];
  const oneway = useRecoilValue(onewayState);

  return (
    <>
      {tourSchedule.map((schedule) => (
        <List
          key={`${schedule.idx}-${schedule.day}`}
          noHairlines
          accordionList
          className="my-4 text-sm driver-card schedule"
        >
          <ListItem className="" accordionItem accordionItemOpened={isOpen}>
            <div slot="title" className="item-title driver-card-title">
              <img src={scheduleIcon} alt="" className="mr-2 w-5" />
              <span>
                {schedule === startSchedule && '가는날'}
                {!oneway && schedule === endSchedule && '오는날'}
                {!oneway && schedule !== startSchedule && schedule !== endSchedule && `${schedule.idx + 1}일차`}
              </span>
              <span className="schedule-closed font-semibold text-black text-sm ml-4">
                {schedule === endSchedule ? schedule.destination : schedule.departure}
              </span>
            </div>
            <AccordionContent>
              <div className="driver-card-body mt-6">
                <div className="schedule-grid">
                  <div className="">출발지</div>
                  <div className="">
                    <span className="font-semibold text-black text-sm mr-1">{schedule.departure}</span>
                  </div>
                </div>

                {schedule.stopOvers &&
                  schedule.stopOvers?.map(
                    (stopOver, i) =>
                      stopOver.region !== '' && (
                        <div className="schedule-grid mt-4">
                          <div className="">{i === 0 && '경유지'}</div>
                          <div className="">
                            <span className="font-semibold text-black text-sm mr-1">{stopOver.region}</span>
                          </div>
                        </div>
                      ),
                  )}

                <div className="schedule-grid mt-4">
                  <div className="">도착지</div>
                  <div className="">
                    <span className="font-semibold text-black text-sm mr-1">{schedule.destination}</span>
                  </div>
                </div>

                {schedule === startSchedule && (
                  <div className="schedule-grid mt-4">
                    <div className="">날짜 / 시간</div>
                    <div className="">
                      <span className="font-semibold text-black text-sm mr-1">
                        {moment(departureDate).format('YYYY. MM. DD (ddd)')} {departureTime}
                      </span>
                    </div>
                  </div>
                )}

                {!oneway && schedule === endSchedule && (
                  <div className="schedule-grid mt-4">
                    <div className="">날짜 / 시간</div>
                    <div className="">
                      <span className="font-semibold text-black text-sm mr-1">
                        {moment(returnDate).format('YYYY. MM. DD (ddd)')} {returnTime}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </AccordionContent>
          </ListItem>
        </List>
      ))}
    </>
  );
};

export default ScheduleTimeDisplay;
