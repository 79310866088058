import defaultImg from '@assets/images/profile.png';
import { totalChargeState } from '@atoms';
import S3ImageView from '@components/images/S3ImageView';
import { currency } from '@js/utils';
import { Storage } from 'aws-amplify';
import { Card, f7, Link } from 'framework7-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

interface driverListItem {
  driver: any;
  withPrice?: boolean;
}

const DriverListItem = ({ driver, withPrice = false }: driverListItem) => {
  const { id, totalCharge, basic_charge, bus } = driver;
  const busProfile = bus?.profiles?.sort((pr, cu) => pr.id - cu.id);

  const setFinalCharge = useSetRecoilState(totalChargeState);

  let displayName = driver.name;
  if (!window.isDesktop) {
    displayName = driver.name.length > 10 ? `${driver.name.substr(0, 10)}..` : driver.name;
  }

  return (
    <div
      onClick={() => {
        setFinalCharge(totalCharge);
        f7.views.current.router.navigate(`/drivers/${id}`);
      }}
      className="w-full bg-white rounded-lg shadow-md grid gap-x-4 gap-y-8 grid-cols-2"
    >
      <div>
        {busProfile && (
          <div className="aspect-w-1 aspect-h-1">
            <S3ImageView className="overflow-hidden rounded-l-lg" imageKey={busProfile[0]?.key} />
          </div>
        )}
      </div>

      <div className="max-w-3xl px-2 my-4">
        <div>
          <span className="text-base font-bold mr-1" style={{ color: '#2f3037' }}>
            {displayName}
          </span>
          <span className="text-sm" style={{ color: '#2f3037' }}>
            기사님
          </span>
        </div>

        <div className="driver-item-tags flex flex-wrap gap-1 my-4">
          <div>{driver.bus?.bus_number || ''}</div>
          <div> {driver.bus?.people_available || ''}인승</div>
          <div>{driver?.company_name ? `${driver?.company_name}` : '개인'}</div>
          <div> {driver.bus?.bus_old || ''}년식</div>
        </div>

        {driver.introduce && <div className="ellipsis multi">{driver.introduce}</div>}

        {/* <p className="mt-1 text-base font-medium text-primary">
          <span className="font-extrabold">{currency(driver?.basic_charge + driver?.service_charge)}</span>원 부터~
        </p> */}
        {withPrice && (
          <div className="flex items-center mt-1">
            <span className="font-semibold text-base">{totalCharge && `${totalCharge.toLocaleString()}원`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DriverListItem;
