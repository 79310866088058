import React, { useMemo } from 'react';
import { Page, Navbar, f7, Link } from 'framework7-react';
import * as Yup from 'yup';
import { getComment, updateComment } from '@api';
import { useQuery, useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import CommentForm from '@components/comments/Form';
import backIcon from '@assets/images/back_icon.png';

const CommentSchema = Yup.object().shape({
  body: Yup.string().required('필수 입력사항 입니다'),
});

const EditCommentPage = ({ f7router, f7route }) => {
  const queryClient = useQueryClient();
  const { id: commentId } = f7route.params;
  const { data } = useQuery([REACT_QUERY_KEYS.COMMENTS, commentId], async () => getComment(commentId));

  const comment = useMemo(() => data, [data]);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      f7.dialog.preloader('잠시만 기다려주세요...');
      await updateComment(commentId, values);
      await queryClient.refetchQueries(REACT_QUERY_KEYS.REVIEWS);
      f7.dialog.close();
      f7router.back();
    } catch (error) {
      f7.dialog.close();
      f7.dialog.alert(error.response.data || error.message);
    }
    setSubmitting(false);
  };

  return (
    <Page noToolbar>
      <Navbar title="댓글 작성">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <CommentForm
        initialValues={{ body: comment?.body }}
        validationSchema={CommentSchema}
        onSubmitHandler={onSubmitHandler}
        review={comment?.review}
      />
    </Page>
  );
};

export default EditCommentPage;
