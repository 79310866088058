/* eslint-disable no-nested-ternary */
import React, { useRef, useMemo, useCallback, useEffect } from 'react';
import { getReviews } from '@api';
import { REACT_QUERY_KEYS } from '@constants';
import useAuth from '@hooks/useAuth';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import { IoLockClosedOutline } from 'react-icons/io5';
import EmptyList from '@components/shared/EmptyList';
import { Block, Link, List, Navbar, Page } from 'framework7-react';
import ListPreloader from '@components/shared/ListPreloader';
import SingleReview from '@components/reviews/SingleReview';
import backIcon from '@assets/images/back_icon.png';

const ReviewIndexPage = () => {
  const { currentUser } = useAuth();
  const allowInfinite = useRef(true);
  const { data, isFetching, isLoading, isError, error, fetchNextPage, hasNextPage } = useInfiniteQuery(
    REACT_QUERY_KEYS.REVIEWS,
    async ({ pageParam: page = 1 }) => {
      const reviews = await getReviews({ driver_id: currentUser.id, cursor: page, per: 20 });
      return reviews.results || [];
    },
    {
      getNextPageParam: (lastPage, pages) => (pages[pages?.length - 1]?.length !== 0 ? pages.length + 1 : false),
    },
  );

  const { ref: targetRef, inView: isTargetInView } = useInView({
    threshold: 1,
  });

  const fetchNextPageAsync = useCallback(async () => {
    allowInfinite.current = false;
    await fetchNextPage();
    allowInfinite.current = true;
  }, [fetchNextPage]);

  useEffect(() => {
    if (!isTargetInView || !allowInfinite.current) return;
    fetchNextPageAsync();
  }, [isTargetInView, fetchNextPageAsync]);

  const reviews = useMemo(() => data?.pages?.flat() || '', [data]);

  return (
    <Page noToolbar>
      <Navbar title="리뷰 관리">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      {currentUser.isAuthenticated ? (
        isLoading ? (
          <div>로딩중입니다</div>
        ) : isError ? (
          <Block>{(error as any).message}</Block>
        ) : (
          <List className="m-0 list-none">
            {reviews && reviews.length > 0 ? (
              reviews.map((review) => <SingleReview review={review} />)
            ) : (
              <EmptyList title="리뷰 내역이 없습니다" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
            )}
          </List>
        )
      ) : (
        <EmptyList title="배낭버스에 로그인 해주세요" icon={<IoLockClosedOutline size={54} color="#ccc" />} />
      )}
      {hasNextPage && <ListPreloader ref={targetRef} />}
      {window.isDesktop && <div>-</div>}
    </Page>
  );
};

export default ReviewIndexPage;
