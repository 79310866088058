/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { f7, Link, Navbar, NavLeft, NavRight, NavTitle, Page, PageContent, Tabs, Toolbar } from 'framework7-react';
import { readChatroomAPI } from '@api';
import useAuth from '@hooks/useAuth';
import { useMutation, useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS, ReservationStatus } from '@constants';
import { getNotificationSubscription, NotificationSubscription } from '@appsync';
import { buildNewMessage, createInAppNotification } from '@utils';
import { AppSyncTypes } from '@interfaces';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createMessage } from '@graphql/mutations';
import { API } from 'aws-amplify';
import ReservationList from '@components/reservations/List';
import { deleteReservationInformationAtLocalStorage, setMainViewHash } from '@js/utils';
import DefaultNavbar from '@components/shared/DefaultNavbar';
import backIcon from '@assets/images/back_icon.png';

type sendInitMessageForUser = ({ message, targetUserUuid, presentUser, roomId }) => void;

const DriverReservationIndexPage = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();

  const next = useCallback(
    async (subscription: NotificationSubscription) => {
      if (currentUser.isAuthenticated === false) return;
      const notification = subscription.value.data?.onNotificationCreateRecevier;
      if (!notification) return;

      const isCurrentUserReceiver = notification?.receiver_id === currentUser.uuid;
      const isInChatroom = notification.redirect_to === f7.views.current.router.currentRoute.path;

      if (isInChatroom) await readChatroomAPI(notification.target_id || '');
      queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS);

      if (!isCurrentUserReceiver || isInChatroom) return;
      createInAppNotification(notification);
      queryClient.refetchQueries([REACT_QUERY_KEYS.NEW_MESSAGES, notification.target_id]);
    },
    [currentUser, queryClient],
  );

  useEffect(() => {
    if (currentUser.isAuthenticated === false) return () => {};
    const subscription = getNotificationSubscription(currentUser.uuid).subscribe({ next });
    return () => subscription.unsubscribe();
  }, [currentUser, next]);

  const createMessageMutation = useMutation<
    AppSyncTypes.CreateMessageMutation['createMessage'],
    unknown,
    AppSyncTypes.CreateMessageInput
  >(async (message) => {
    const result: GraphQLResult<AppSyncTypes.CreateMessageMutation> = await API.graphql({
      query: createMessage,
      variables: { input: message },
    });

    return result.data?.createMessage;
  });

  const sendInitMessageForUser: sendInitMessageForUser = ({ message, targetUserUuid, presentUser, roomId }) => {
    const params = {
      message,
      targetUserUuid,
      roomId,
      currentUser: presentUser,
    };

    const newMessage = buildNewMessage({ ...params });
    createMessageMutation.mutate(newMessage);
  };

  const reservationListRef = useRef(null);

  const reload = useCallback(async (done: () => void) => {
    await reservationListRef.current?.reload();
    done();
  }, []);

  const reservationStatus = Object.keys(ReservationStatus).slice(0, 3);
  const [activeTabId, setActiveTabId] = useState(reservationStatus[0]);

  useEffect(() => {
    if (window.localStorage.getItem('isProcessingWithUnAuthenticated')) {
      deleteReservationInformationAtLocalStorage();
    }
  }, []);
  return (
    <Page
      name="DriverReservation"
      className="driver-reservation-index"
      noToolbar
      pageContent={false}
      onPageAfterIn={() => {
        setMainViewHash('in');
      }}
      onPageAfterOut={() => {
        setMainViewHash('out');
      }}
    >
      <Navbar>
        <NavLeft>
          <Link iconOnly slot="left" back>
            <img src={backIcon} alt="" width="18px" />
          </Link>
        </NavLeft>
        <NavTitle>예약목록</NavTitle>
        <NavRight>
          <Link icon="noti_bell" href="/notifications" />
        </NavRight>
      </Navbar>

      <Toolbar tabbar position="top">
        {reservationStatus.map((el) => (
          <Link
            className="justity-content text-center p-3 block w-1/2"
            key={`link-${el}`}
            tabLink={`#tab-reservation-${el}`}
            tabLinkActive={el === activeTabId}
            onClick={() => setActiveTabId(el)}
          >
            {ReservationStatus[el]}
          </Link>
        ))}
      </Toolbar>
      <Tabs style={{ paddingTop: 'var(--f7-toolbar-height)' }}>
        {reservationStatus.map((el) => (
          <PageContent
            key={`tab-reservation-${el}`}
            id={`tab-reservation-${el}`}
            tab
            tabActive={activeTabId === el}
            ptr
            ptrPreloader
            ptrMousewheel
            onPtrRefresh={reload}
            style={{ paddingTop: 'calc(var(--f7-page-navbar-offset, 0px)' }}
          >
            <ReservationList
              query={el}
              sendInitMessageForUser={sendInitMessageForUser}
              ref={activeTabId === el ? reservationListRef : undefined}
            />
          </PageContent>
        ))}
      </Tabs>
    </Page>
  );
};

export default DriverReservationIndexPage;
