import S3ImageView from '@components/images/S3ImageView';
import { Formik } from 'formik';
import { Button, ListInput, List } from 'framework7-react';
import moment from 'moment';
import React from 'react';
import StarRatingComponent from 'react-star-rating-component';

const CommentForm = ({ initialValues, validationSchema, onSubmitHandler, review }) => (
  <>
    {review && (
      <div className="p-4">
        <div className="flex justify-between position-relative">
          <div className="grid grid-rows-3 grid-flow-col gap-2">
            <div className="row-span-3">
              <S3ImageView
                imageKey={review?.user?.profile?.key || ''}
                className="w-10 h-10 rounded-full overflow-hidden"
              />
            </div>
            <div className="col-span-2 text-sm font-bold">{review.user.name}</div>
            <div className="row-span-1 flex text-xs">
              <StarRatingComponent
                name="rating"
                editing={false}
                starCount={5}
                value={review.rating}
                starColor="#FFD400"
                emptyStarColor="#AAAAAA"
              />
              <div className="text-gray-500 ml-2">{`${moment(review.createdAt).format('YYYY/MM/DD')}`}</div>
            </div>
          </div>
        </div>
        <div className="text-base">{review.content}</div>
      </div>
    )}
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      validateOnMount
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className="registration-form">
          <List>
            <ListInput
              name="body"
              type="textarea"
              outline
              placeholder="댓글을 입력해주세요"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.body}
            />
          </List>
          <Button
            className="p-4 button button-fill button-large m-auto w-11/12"
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            작성
          </Button>
        </form>
      )}
    </Formik>
  </>
);

export default CommentForm;
