import React from 'react';

const ServiceTerm = () => (
  <>
    <div className="px-4 py-8 flex flex-col bg-[#F3F4F6]">
      <div className="mb-2">
        <h1 className="font-bold text-lg">제1조 (목적)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          이 약관은 폼나는누나(이하 "회사"라 합니다)가 제공하는 배낭버스 서비 스(이하 "서비스"라 합니다)와 관련하여,
          회사와 이용 고객 간에 서비스의 이용조건 및 절차, 회사와 회원간의 권리, 의무 및 기타 필요한 사항을 규 정함을
          목적으로 합니다. 본 약관은 PC통신, 스마트폰(안드로이드폰, 아 이폰 등) 앱 등을 이용하는 전자상거래에 대해서도
          그 성질에 반하지 않 는 한 준용됩니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제2조 (용어의 정의)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          본 이용약관에서 사용하는 용어의 의미는 아래와 같습니다.
          <p className="text-sm text-[#2f3037] pt-2">
            1. "사이트"란 회사가 전세버스 대절 비교견적 서비스를 이용고객에게 제공하기 위하여 컴퓨터 등 정보통신설비를
            이용하여 재화, 용역을 거래할 수 있도록 설정한 가상의 영업장을 말합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. "회원"이라 함은 "회사"에 개인정보를 제공하여 회원등록을 한 자로 서, 서비스의 정보를 지속적으로
            제공받으며, "회사"가 제공하는 서 비스를 계속적으로 이용할 수 있는 자로, 버스기사, 버스회사, 개인 이용자 등
            모든 이용자를 의미합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. "비회원"이라 함은 "회원"으로 가입하지 않고 "회사"가 제공하는 서비 스를 이용하는 자를 말합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. "비밀번호(Password)"라 함은 회원의 동일성 확인과 회원의 권익 및 비밀보호를 위하여 회원 스스로가 설정하여
            사이트에 등록한 영문 과 숫자 등의 조합을 말합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            5. "게시물"이라 함은 "회원"이 서비스를 이용함에 있어 서비스 상에 게 시한 부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상
            등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            6. "버스기사" "버스회사"라 함은 "회사"가 제공하는 "서비스"를 이용해 "회원"에게 전세버스 차량정보 및
            운행금액을 제시하는 "회원"을 말 합니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            7. "광고수수료"는 "버스기사" 혹은 "버스회사"가 "서비스"를 통해 고객 을 유치, 획득하였을 때, "회사"에
            지불해야하는 이용대금을 의미합 니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            8."운행요금"이라 함은 "회원" 또는 "비회원"이 "회사"가 제공하는 "서비 스"를 통해 전세버스를 이용할 때
            "전자지급결제대행업체를 통해 결제되는 이용대금을 의미합니다.
          </p>
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제3조 (약관의 명시와 설명 및 개정)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ "회사"는 이 약관의 내용과 상호, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함),
          대표자의 성명, 사업자등록번호, 통신 판매업 신고번호, 연락처(전화, 전자우편 주소 등) 등을 "이용자"가 쉽게 알 수
          있도록 "사이트"의 초기 서비스화면(전면)에 게시합니다. 다만, 약 관의 내용은 "이용자"가 연결화면을 통하여 보도록
          할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 『전자상거래 등에서의 소비자보호에 관한 법률』, 『약관의 규제등에 관한 법률』, 『전자문서 및
          전자거래기본법』, 『전자서명 법』, 『정보통신망 이용촉진 등에 관한 법률』, 『소비자기본법』 등 관 련법령을
          위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 "사이트"의 초기화면에 그
          적용일자 7일 이전부터 적용 일자 전일까지 공지합니다. 다만, "이용자"에게 불리하게 약관내용을 변 경하는 경우에는
          최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "회사"는 개정 전과 개정 후 내용을 "이용자"가 알기
          쉽도록 표시 합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회원"은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을
          중단하고 탈퇴를 요청할 수 있습 니다. 다만, "이용자"가 제3항의 방법 등으로 "회사"가 별도 고지한 약관 개정 공지
          기간 내에 "회사"에 개정 약관에 동의하지 않는다는 명시적인 의사표시를 하지 않는 경우 변경된 약관에 동의한
          것으로 간주합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 『전자 상거래 등에서의 소비자보호에 관한 법률』,
          『약관의 규제 등에 관한 법 률 』 , 공정거래위원회가 정하는 『 전자상거래 등에서의 소비자보호지 침』 및 관계
          법령 또는 상관례에 따릅니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제4조 (관련 법령과의 관계)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          이 약관 또는 개별약관에서 정하지 않은 사항은 전자문서 및 전자거래기 본법, 정보통신망 이용촉진 및 정보보호 등에
          관한 법률, 전자상거래 등에 서의 소비자보호에 관한 법률, 개인정보 보호법 등 관련 법령의 규정과 일 반적인
          상관례에 의합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제5조 (책임제한)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ "회사"는 "버스기사" 혹은 "버스회사"와 "회원" 간의 "재화 등"의 거래를 중개하는 플랫폼 서비스만을 제공할 뿐,
          "재화 등"을 판매하는 당사자가 아니며, "재화 등"에 대한 정보 및 이행여부, 하자, 도난사고, 교통사고 등 에 대한
          책임은 "회원"과 "버스기사"에게 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 "버스기사" 혹은 "버스회사"가 게재한 정보, 자료, 사실(운전 면허증, 보험증서 등 포함)의 신뢰도,
          정확성 등 내용에 관해서는 책임을 지지 않습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 서비스 제공에 관한
          책임이 면제됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ "회사"는 "회원" 및 "버스기사"가 게재한 이용후기, 평가, 사진 등 정보/ 자료/사실의 신뢰도, 정확성에 대해서는
          책임을 지지 않습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑥ "회사"는 제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고 한 제품 또는 서비스의 내용과 품질에
          대하여 감시할 의무 기타 어떠한 책 임도 지지 아니합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑦ "회사"는 "회원"이 서비스를 이용하여 기대하는 수익을 상실한 것에 대 하여 책임을 지지 않으며, 그 밖의 서비스를
          통하여 얻은 자료로 인한 손 해에 관하여 책임을 지지 않습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑧ "회사"는 "회원"간 또는 "회원"과 제3자 상호간에 서비스를 매개로 하 여 거래 등을 한 경우에는 책임이
          면제됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑨ "회사" 및 "회사"의 임직원 그리고 대리인은 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는
          손해에 대해 책임을 지지 아 니합니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 회원 정보의 허위 또는 부정확성에 기인하는 손해</p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. 서비스에 대한 접속 및 서비스의 이용과정에서 "회원"의 귀책사유로 발생하는 손해
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용 으로부터 발생하는 손해 및 제3자의
            불법적인 행위를 방지하거나 예방하는 과정에서 발생하는 손해
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어
            및 기타 악성 프로그램으 로 인한 손해
          </p>
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제6조 (서비스의 제공 및 변경)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ "회사"는 다음과 같은 서비스를 제공합니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 전세버스 대절 견적 서비스</p>
          <p className="text-sm text-[#2f3037] pt-2">2. 전세버스 대절 예약 서비스</p>
          <p className="text-sm text-[#2f3037] pt-2">3. 통근버스 대절 견적 서비스</p>
          <p className="text-sm text-[#2f3037] pt-2">4. 버스대절 관련 정보제공 서비스 </p>
          <p className="text-sm text-[#2f3037] pt-2">5. 기타 "회사"가 정하는 서비스</p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 서비스 제공과 관련한 회사 정책의 변경, 이용량 감소, 수익성 악화 등 기타 상당한 이유가 있는 경우 등
          운영상, 기술상의 필요에 따라 제공하고 있는 "서비스"의 전부 또는 일부를 변경 또는 중단할 수 있습니 다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "서비스"의 내용, 이용방법, 이용시간에 대하여 변경 또는 "서비스" 중 단이 있는 경우에는 변경 또는 중단될
          "서비스"의 내용 및 사유와 일자 등 은 그 변경 또는 중단 전에 회사 "웹사이트" 또는 "서비스" 내 "공지사항" 화면
          등 "회원"이 충분이 인지할 수 있는 방법으로 사전에 공지합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제7조 (서비스 이용시간 및 중단)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ "서비스"의 이용은 "회사"의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.
          다만, 정기 점검 등의 필요로 "회사"가 정한 날이나 시간은 제외됩니다. 정기점검시간은 서비스제공화 면에 공지한
          바에 따릅니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 "서비스"의 원활한 수행을 위하여 필요한 기간을 정하여 사전 에 공지하고 서비스를 중지할 수 있습니다.
          단, 불가피하게 긴급한 조치를 필요로 하는 경우 사후에 통지할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두 절 등의 사유가 발생한 경우에는 "서비스"의
          제공을 일시적으로 중단할 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제8조 (이용계약의 성립)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ 이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내 용에 동의를 하고, "회사"가 정한 가입
          양식에 따라 회원정보를 기입하여 회원가입신청을 하거나 "회사"가 이러한 신청에 대하여 승인함으로써 체 결됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 다음 각 호에 해당하는 신청에 대하여는 승인을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
          <p className="text-sm text-[#2f3037] pt-2">
            1. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우. 다만, 회원자격 상실 후 3년이
            경과한 자로서 회사의 회원 재가 입 승낙을 얻은 경우에는 예외로 함.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">2. 실명이 아니거나 타인의 명의를 이용한 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. 회사가 실명확인절차를 실시할 경우에 이용자의 실명가입신청이 사 실 아님이 확인된 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. 등록내용에 허위의 정보를 기재하거나, 기재누락, 오기가 있는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">5. 이미 가입된 회원과 전화번호나 전자우편주소가 동일한 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            6. 부정한 용도 또는 영리를 추구할 목적으로 본 서비스를 이용하고자 하는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            7. 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여
            필요하다고 인정하는 경우
          </p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ 제1항에 따른 신청에 있어 "회사"는 "회원"에게 전문기관을 통한 실명 확인 및 본인인증을 요청할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제 가 있는 경우에는 승낙을 유보할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ "회원"은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이 내에 "회사"에 대하여 회원정보 수정 등의
          방법으로 그 변경사항을 알려 야 합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제9조 (이용계약의 종료)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ⓵ "회원"은 언제든지 "회사"에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다. "회사"는 전항에 따른
          "회원"의 해지요청에 대해 특 별한 사정이 없는 한 이를 즉시 처리합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회원"이 계약을 해지하는 경우 "회원"이 작성한 게시물은 삭제되지 않 습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 다음과 같은 사유가 있는 경우, 이용계약을 해지할 수 있습니 다.
          <p className="text-sm text-[#2f3037] pt-2">
            1. 제7조 제2항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확 인된 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. "회원"이 "회사"나 다른 회원 기타 타인의 권리나 명예, 신용 기타 정 당한 이익을 침해 하는 행위를 한 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. 기타 "회원"이 이 약관 및 "회사"의 정책에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">4. 1년 이상 서비스를 이용한 이력이 없는 경우</p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ 이용계약은 "회사"가 해지의사를 "회원"에게 통지함으로써 종료됩니 다. 이 경우 "회사"는 "회원"에게 전자우편,
          전화, 팩스, 그 외 등의 방법을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 다만, "회사"는 해당 "회 원"에게
          해지사유에 대한 의견진술의 기회를 부여 할 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제10조 (회원의 ID 및 비밀번호에 대한 의무)</h1>
        <p className="text-sm text-[#2f3037] pt-2">① ID와 비밀번호에 관한 관리책임은 "회원"에게 있습니다.</p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회원"은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회원"이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 즉시 "회사"에
          통보하고 "회사"의 조치가 있는 경우에는 그에 따라야 합니다. "회원"이 위 통지를 하지 않거나 "회사"의 위 조치에
          응하지 아니하여 발생하는 모든 불이익에 대한 책임은 "회원"에게 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제11조 (회원 및 이용자의 의무)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회원"은 관계법령 및 이 약관의 규정, "회사"의 정책, 이용안내 등 "회사"가 통지 또는 공지하는 사항을
          준수하여야 하며, 기타 "회사" 업무에 방해되는 행위를 하여서는 안됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "개인이용자회원"은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 버스 견적 신청 또는 수정 시 허위내용의 등록</p>
          <p className="text-sm text-[#2f3037] pt-2">2. 동일한 견적요청을 반복적으로 등록</p>
          <p className="text-sm text-[#2f3037] pt-2">3. "회사"에 게시된 정보의 허가 받지 않은 변경 </p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시
          </p>
          <p className="text-sm text-[#2f3037] pt-2">5. "회사" 또는 제3자의 저작권 등 지적 재산권에 대한 침해</p>
          <p className="text-sm text-[#2f3037] pt-2">6. "회사" 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
          <p className="text-sm text-[#2f3037] pt-2">
            7. 광고성 정보, 외설 또는 폭력적인 메시지, 화상, 음성 기타 공공질서 미풍양속에 반하는 정보를 "서비스"에 공개
            또는 게시하는 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">8. 고객센터 상담 내용이 욕설, 폭언, 성희롱 등에 해당하는 행위</p>
          <p className="text-sm text-[#2f3037] pt-2">9. 포인트를 부정하게 적립하거나 사용하는 등의 행위</p>
          <p className="text-sm text-[#2f3037] pt-2">
            10. 허위 견적요청, 허위 리뷰작성 등을 통해 서비스를 부정한 목적으로 이용하는 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">11. 자신의 ID, PW를 제3자에게 양도하거나 대여하는 등의 행위</p>
          <p className="text-sm text-[#2f3037] pt-2">
            12. 정당한 사유 없이 당사의 영업을 방해하는 내용을 기재하는 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            13. 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또 모방 기타
            변형하는 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            14. 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를
            일으켜 회사의 정상적인 서비스를 방해하는 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">15. 기타 관계법령에 위반된다고 판단되는 행위</p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ “버스기사” 또는 “버스회사”은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 버스 및 기사에 대한 허위정보 등록</p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. 지나치게 낮은 가격 제시, 부대비용 표기 누락 등 허위, 기만 정보 등록
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. 가입시 입력한 버스 외 다른 버스에 대한 정보를 등록하는 행위(한 계정은 한 대 버스에 대한 등록 가능)
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. "서비스"를 통해 고객을 유치한 후, "광고수수료"를 면탈하기 위한 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            5. "버스회사"는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
          </p>
          <p className="text-sm text-[#2f3037] pt-2">6. 업체 및 버스, 기사에 대한 허위정보 등록</p>
          <p className="text-sm text-[#2f3037] pt-2">7. 예약이 진행되었을 때, 배차를 못하는 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            8. "서비스"를 통해 고객을 유치한 후, "광고수수료"를 면탈하기 위한 행위
          </p>
          <p className="text-sm text-[#2f3037] pt-2">9. 버스 운행금액에 관한 설정 정보 관리 미흡</p>
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제12조 (회원의 게시물)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          "회원"이 작성한 게시물에 대한 저작권 및 모든 책임은 이를 게시한 "회 원"에게 있습니다. 단, "회사"는 "회원"이
          게시하거나 등록하는 게시물의 내용이 다음 각 호에 해당한다고 판단되는 경우 해당 게시물을 사전통지 없이 삭제
          또는 임시조치(블라인드)할 수 있습니다.
          <p className="text-sm text-[#2f3037] pt-2">
            1. 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는 내용인 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">2. 공공질서 및 미풍양속에 위반되는 내용일 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">3. 범죄적 행위에 결부된다고 인정되는 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            5. 회원이 사이트와 게시판에 음란물을 게재하거나 음란사이트를 링크 하는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            6. 회사로부터 사전 승인 받지 아니한 상업광고, 판촉 내용을 게시하는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">7. 해당 상품과 관련 없는 내용인 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            8. 정당한 사유 없이 "회사" 또는 "버스기사"의 영업을 방해하는 내용을 기재하는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            9. 자신의 버스 혹은 소속조직을 홍보할 목적으로 허위 또는 과장된 게 시글을 게재하는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">10. 의미 없는 문자 및 부호에 해당하는 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">11. 제3자 등으로부터 권리침해신고가 접수된 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">12. 관계법령에 위반된다고 판단되는 경우</p>
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제13조 (회원게시물의 관리)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회원"의 "게시물"이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이
          정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야
          합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및
          관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ 본 조에 따른 세부절차는 정보망 이용촉진 및 정보보호에 관한 법률 및 저작권법이 규정한 범위 내에서 회사가 정한
          게시중단요청서비스에 따릅니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">게시중단요청: form0313@daum.net</p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제14조 (이용제한 등)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 "회원"이 본 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지,
          영구이용정지 등의 서비스 이용제한 조치를 취할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 "주민등록법"을 위반한 명의도용 및 결제도용, 전화번호 도용, "저작권법" 및 "컴퓨터프로그램보호법"을
          위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포,
          접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ 회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할
          수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 회사의 이용제한정책에서 정하는 바에 의합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ 본 조에 따라 서비스 이용을 제한하거나 계약을 해지하는 경우에는 회사는 제15조[회원에 대한 통지]에 따라
          통지합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑥ "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가
          정당하다고 회사가 인정하는 경우 "회사"는 즉시 서비스의 이용을 재개합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑦ 본 조에 따라 이용제한이 되는 경우 서비스 이용을 통해 획득한 혜택 등도 모두 이용중단, 또는 소멸되며, "회사"는
          이에 대해 별도로 보상하지 않습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제15조 (권리의 귀속)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라 제공된
          저작물 등은 제외합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"가 제공하는 "서비스"의 디자인, "회사"가 만든 텍스트, 스크립트(script), 그래픽, "회원" 상호간 전송 기능
          등 "회사"가 제공하는 "서비스"에 관련된 모든 상표, 마크, 로고 등에 관한 저작권 기타 지적재산권은 대한민국 및
          외국의 법령에 기하여 "회사"가 보유하고 있거나 "회사"에게 소유권 또는 사용권이 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회원"은 이 이용약관으로 인하여 서비스를 소유하거나 "서비스"에 관한 저작권을 보유하게 되는 것이 아니라,
          "회사로부터 서비스의 이용을 허락 받게 되는바, 정보취득 또는 개인용도로만 "서비스"를 이용할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회원"은 명시적으로 허락된 내용을 제외하고는 "서비스"를 통해 얻어지는 정보를 영리 목적으로 사용, 복사,
          유통하는 것을 포함하여, "회사"가 만든 텍스트, 스크립트, 그래픽의 "회원" 상호간 전송기능 등을 복사하거나 유통할
          수 없습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ "회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 계정, ID, 콘텐츠 등을 이용할 수 있는
          이용권만을 부여하며, 이용자는 회사를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포,
          방송 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용, 양도, 판매, 담보목적으로 제공하여서는
          안됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑥ "회원"이 "서비스"를 이용하면서 입력한 게시물, 정보(텍스트, 이미지 등)는 별도의 저작권 동의 없이 "회사"가
          홍보, 판촉 등의 목적으로 자유롭게 이용할 수 있습니다. 단, "회원"이 타당한 사유로 삭제요청이 있을 경우,
          "회사"는 내부검토 후에 빠르게 이를 조치합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제16조 (회원에 대한 통지)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"가 "회원"에 대한 통지를 하는 경우, "회원"이 가입신청 시 "회사"에 제출한 전자우편 주소나 휴대전화번호
          등으로 할 수 있습니다. ② "회사"는 불특정다수 "회원"에 대한 통지의 경우, 1주일 이상 사이트에 게시함으로써 개별
          통지에 갈음할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 불특정다수 "회원"에 대한 통지의 경우, 1주일 이상 사이트에 게시함으로써 개별 통지에 갈음할 수
          있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제17조 (회사의 의무)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로
          "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 개인정보취급방침을
          수립하여 공시하고 준수합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 통신판매중개사업자로 전세버스 운행계약의 거래당사자가 아니며, 운행계약 및 이용약관은 버스회사나
          버스기사가 소속되어 있는 버스회사의 이용약관을 따르고 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제18조 (할인쿠폰)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① 할인쿠폰, 적립금, 포인트 등(이하, “할인쿠폰 등”)은 "회사"의 이벤트 프로모션 참여, "버스기사" 및 "버스회사"
          가 발급한 경우, "회사"의 정책에 따른 "회원" 등급별 부여 등을 통하여 "회원"과 "버스기사", "버스회사"에게
          지급되며, 개별 “할인쿠폰 등”의 유효기간, 할인금액 및 사용방법 등은 개별 안내사항을 통하여 확인 가능합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② “할인쿠폰 등”은 현금으로 환급될 수 없으며, 표시된 유효기간이나 사용 정책으로 정하여 게시한 사용기간이
          만료되거나 이용계약이 종료되면 소멸합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② “할인쿠폰 등”은 현금으로 환급될 수 없으며, 표시된 유효기간이나 사용 정책으로 정하여 게시한 사용기간이
          만료되거나 이용계약이 종료되면 소멸합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 "회원"이 부정한 목적과 방법으로 “할인쿠폰 등”을 획득하거나 사용하는 사실이 확인될 경우, 해당
          이용자에 대한 “할인쿠폰 등”을 회수 또는 소멸시키거나 회원자격을 제한할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ “회사”는 “할인쿠폰 등” 제도를 폐지할 수 있습니다. 이 경우, 폐지일로부터 6개월 이내까지만 “할인쿠폰 등”을
          사용할 수 있게 제한할 수 있습니다. 이러한 경우 사용하지 못하고 소멸되는 “할인쿠폰 등”에 대해서는 별도로
          보전하지 않습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ “할인쿠폰 등”의 제공내용 및 운영방침은 "회사"의 정책에 따라 달라질 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제19조 (광고수수료)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "광고수수료"의 계약은 "회사"와 "버스기사" 혹은 "회사"와 "버스회사"간에 이루어지고, "버스기사" 혹은
          "버스회사"가 회원이 올린 견적요청에 수락함과 동시에 성립됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ②“광고수수료금액”은 제1항의 “버스기사” 또는 “버스회사”가 수락한 견적에서 정한 총운행서비스 대금 10% 상당의
          금액으로 하고, “버스기사” 또는 “버스회사”가 회원에게 제1항의 운송서비스를 제공하여 운행이 종료된 때
          지급합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ 위 제2항의 수수료는 제1항의 수락에 따라 회원이 지급한 총 운송서비스 대금에서 전자지급결제대행업체의 결제를
          통하여 수취 및 정산됩니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사"는 "버스기사" 혹은 "버스회사"가 수수료 정책을 위반할 경우 "서비스" 이용을 제한할 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제20조 (서비스 이용요금 및 지급 방법)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① 회사가 제공하는 서비스는 기본적으로 유료 또는 무료입니다. 단, 별도의 유료서비스의 경우 해당 서비스에 명시된
          요금을 지불하셔야 사용 가능합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② 회사는 유료서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에
          합산하여 청구할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ MMS 등으로 게시물을 등록할 경우 발생하는 요금은 회원이 가입한 각 이동통신사의 정책에 따릅니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "운행요금"의 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있습니다. 다만 "회사"는 "회원" 또는
          "비회원" 의 지급방법에 대하여 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 선불카드, 직불카드, 신용카드 등의 각종 카드결제</p>
          <p className="text-sm text-[#2f3037] pt-2">2. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 </p>
          <p className="text-sm text-[#2f3037] pt-2">4. "할인쿠폰" 등 "회사"가 지급한 포인트, 쿠폰에 의한 결제</p>
          <p className="text-sm text-[#2f3037] pt-2">5. "회사"와 계약을 맺었거나 "회사"가 인정한 상품권에 의한 결제</p>
          <p className="text-sm text-[#2f3037] pt-2">6. 전화 또는 휴대전화를 이용한 결제</p>
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제21조 (청약철회와 계약해제 및 해지)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① “회사”와 “서비스” 이용에 관한 계약을 체결한 “회원” 또는 “비회원” 아 래의 방법에 따라 청약을 철회할 수
          있습니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 이용일 5일 이전의 날까지 해지되는 경우: 전액 환불</p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. 이용일 4일 이전의 날부터 이용일까지 해지 되는 경우 : 이용요금의 20% 취소 수수료
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            "회사"가 다음 각 호중 하나의 조치를 취한 경우에는 "회원" 또는 "비회 원"의 청약철회권이 제한될 수 있습니다.
            <p className="text-sm text-[#2f3037] pt-2">
              1. 청약의 철회가 불가능한 "서비스"에 대한 사실을 표시사항에 포함한 경우
            </p>
            <p className="text-sm text-[#2f3037] pt-2">2. 시용 상품을 제공한 경우</p>
            <p className="text-sm text-[#2f3037] pt-2">3. 한시적 또는 일부이용 등의 방법을 제공한 경우</p>
          </p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회원"또는 "비회원"은 다음 각 호의 사유가 있을 때에는 당해 "서비 스"를 공급받은 날로부터 3월 이내 또는 그
          사실을 안 날 또는 알 수 있었 던 날로부터 30일 이내에 서비스이용계약을 해제·해지할 수 있습니다.
          <p className="text-sm text-[#2f3037] pt-2">1. 이용계약에서 약정한 "서비스"가 제공되지 않은 경우</p>
          <p className="text-sm text-[#2f3037] pt-2">
            2. 제공되는 "서비스"가 표시 광고 등과 상이하거나 현저한 차이가 있는 경우
          </p>
          <p className="text-sm text-[#2f3037] pt-2">
            3. 기타 "서비스"의 결함으로 정상적인 이용이 현저히 불가능한 경우
          </p>
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ 제1항의 청약철회와 제2항의 계약해제·해지는 "회원" 또는 "비회원"이 전화, 전자우편 또는 모사전송으로 "회사"에
          그 의사를 표시한 때에 효력 이 발생합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사"는 제 3항에 따라 "회원" 또는 "비회원"이 표시한 청약철회 또는 계약해제·해지의 의사표시를 수신한 후 지체
          없이 이러한 사실을 "회원" 또는 "비회원"에게 회신합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ "회원" 또는 "비회원"은 제 2항의 사유로 계약해제·해지의 의사표시를 하기 전에 상당한 기간을 정하여 완전한
          "서비스"혹은 서비스이용의 하자 에 대한 치유를 요구할 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제22조 ("회원" 또는 "비회원"의 청약철회와 계약해제&#183;해지의 효과)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 "이용자"가 청약철회의 의사표시를 한 날로부터 또는 "회원" 또는 "비회원"에게 계약해제·해지의
          의사표시에 대하여 회신한 날로부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 환급하여야 하며, 동일한
          방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 이 경우 "회사"가 "회원" 또는 "비회원"에게
          환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한
          지연이자를 지급합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"가 제1항에 따라 환급할 경우에 "회원" 또는 "비회원"이 서비스이용으로부터 얻은 이익에 해당하는 금액을
          공제하고 환급할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 위 대금을 환급함에 있어서 "회원" 또는 "비회원"이 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의
          대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는
          취소하도록 요청합니다. 다만, 제2항의 금액공제가 필요한 경우에는 그러하지 아니할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사", "서비스 등의 대금을 지급 받은 자" 또는 "회원 또는 비회원과 서비스이용계약을 체결한 자"가 동일인이
          아닌 경우에 각자는 청약철회 또는 계약해제·해지로 인한 대금환급과 관련한 의무의 이행에 있어서 연대하여 책임을
          집니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ⑤ "회사"는 "회원" 또는 "비회원"에게 청약철회를 이유로 위약금 또는 손해배상을 청구하지 않습니다. 그러나 "회원"
          또는 "비회원"의 계약해제·해지는 손해배상의 청구에 영향을 미치지 않습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제23조 (회사의 계약해제&#183;해지 및 이용제한)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 "회원" 또는 "비회원"이 제13조 각 항에서 정한 행위를 하였을 경우 사전통지 없이 계약을
          해제·해지하거나 또는 기간을 정하여 서비스이용을 제한할 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② 제1항의 해제·해지는 "회사"가 자신이 정한 통지방법에 따라 "회원" 또는 "비회원"에게 그 의사를 표시한 때에
          효력이 발생합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"의 해제·해지 및 이용제한에 대하여 "회원" 또는 비회원"은 "회사"가 정한 절차에 따라 이의신청을 할 수
          있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우, "회사"는 즉시 서비스의 이용을 재개합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제24조 (회사의 계약해제&#183;해지의 효과)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          "회원" 또는 "비회원"의 귀책사유에 따른 이용계약의 해제·해지의 효과는 제23조를 준용합니다. 다만, "회사"는
          "회원" 또는 "비회원"에 대하여 계약 해제·해지의 의사표시를 한 날로부터 7영업일 이내에 대금의 결제와 동일 한
          방법으로 이를 환급합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제25조 (개인정보보호)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 "회원"의 개인정보를 보호하기 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 개인정보
          보호법 등 관계 법령에서 정하는 바를 준수합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 "회원"의 개인정보를 보호하기 위한 개인정보취급방침을 수립하여 서비스 초기화면에 게시합니다. 다만,
          개인정보취급방침의 구체적 내용은 연결화면을 통하여 볼 수 있습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"는 관련법령 및 개인정보취급방침에 따라 "회원"의 개인정보를 최대한 보호하기 위하여 노력합니다. 이러한
          노력의 일환으로 개인정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를
          개인정보관리책임자로 지정해 운영하고 있으며, 개인정보관리책임자의 성명과 연락처는 아래와 같습니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">개인정보 관리책임자 : 정수경</p>
        <p className="text-sm text-[#2f3037] pt-2">대표전화 : 1533-1035</p>
        <p className="text-sm text-[#2f3037] pt-2">대표이메일 : form0313@daum.net</p>
        <p className="text-sm text-[#2f3037] pt-2">
          ④ "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지 않습니다. 링크된 사이트
          및 구매 상품이나 서비스를 제공하는 제3자의 개인정보 취급과 관련하여는 해당 사이트 및 해당 제3자의
          개인정보취급방침을 확인할 책임이 "회원"에게 있으며, "회사"는 이에 대하여 책임을 부담하지 않습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제26조 (분쟁해결)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① "회사"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 고객상담 및
          피해보상 처리기구를 설치・운영합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② "회사"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
          곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ③ "회사"와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회
          또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">제27조 (준거법 및 관할법원)</h1>
        <p className="text-sm text-[#2f3037] pt-2">
          ① 이 약관의 해석 및 회사와 회원 간의 분쟁에 대하여는 대한민국의 법을 적용합니다.
        </p>
        <p className="text-sm text-[#2f3037] pt-2">
          ② 서비스 이용 중 발생한 회원과 회사 간의 소송은 민사소송법에 의한 관할법원에 제소합니다.
        </p>
      </div>
      <div className="mb-2">
        <h1 className="font-bold text-lg">부칙</h1>
        <p className="text-sm text-[#2f3037] pt-2">이 약관은 2022년 03월부터 시행됩니다.</p>
      </div>
    </div>
  </>
);

export default ServiceTerm;
