import React, { useState } from 'react';
import { List, ListItem, Link } from 'framework7-react';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import _ from 'lodash';
import { BiChevronRight } from 'react-icons/bi';

interface AgreeCheckBoxProps {
  names: string[];
}

const AgreeCheckboxes = ({ names }: AgreeCheckBoxProps) => {
  const { values, handleChange, setFieldValue, validateField } = useFormikContext();
  const [allCheck, setAllCheck] = useState('');

  const onClickAgreeAll = (e) => {
    if (e.target.checked) {
      setFieldValue('all', true);
      names.map(async (name) => {
        await setFieldValue(name, true);
        await validateField(name);
      });
      setAllCheck('bg-secondary');
    } else {
      setFieldValue('all', false);
      names.map(async (name) => {
        await setFieldValue(name, false);
        await validateField(name);
      });
    }
  };
  return (
    <div className="p-4 bg-gray-200">
      <div className="flex items-end mt-3 mb-1 justify-between">
        <div className="flex items-center terms">
          <List className="m-0 bg-gray-200">
            <ListItem checkbox name="all" onChange={onClickAgreeAll} className="text-base bg-gray-200" />
          </List>
          <a onClick={onClickAgreeAll} className="text-black font-bold text-lg">
            약관 전체 동의하기
          </a>
        </div>
      </div>
      <List noHairlines noHairlinesBetween className="mt-2 bg-gray-200 agree-check-box" bgColor="#E5E7EB">
        {_.map(names, (name, index) => (
          <ListItem
            key={index}
            checkbox
            title={i18next.t('checkdesc')[name] as string}
            name={name}
            onChange={handleChange}
            value={values[name]}
            checked={values[name]}
            className={`text-sm bg-white mb-2.5 rounded-xl shadow ${
              values[name] && 'border border-[#00abeb] text-[#00abeb] font-medium'
            }`}
          >
            <Link className="" href={`/users/sign_up/terms?tab=${name.replace('Check', '')}`}>
              <span slot="inner" className="z-10 text-[#cccccc] text-[13px] font-medium">
                <BiChevronRight size={20} color="#BAC1C9" />
              </span>
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default AgreeCheckboxes;
