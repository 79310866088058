/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import moment from 'moment';
import { f7 } from 'framework7-react';

export const currency = (data, options) => {
  if (!data) return '0';

  const fd = Math.floor(data);
  return fd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateFormat = (date, format) => {
  try {
    return moment(date).format(i18next.t('date_formats')[format]);
  } catch (e) {
    console.log(e);
    return '시간이나 포맷이 잘못되었습니다.';
  }
};

export const enumT = (model_name, value) => {
  try {
    return i18next.t('enum')[model_name][value];
  } catch {
    return '모델명 혹은 값이 잘못되었습니다.';
  }
};

export const toast = (() => {
  let instance;
  // public
  function init(f7) {
    const textToast = f7.toast.create({
      text: 'text',
      position: 'center',
      closeTimeout: 2000,
    });
    const iconToast = f7.toast.create({
      text: 'text',
      icon: `<i class="f7-icons">exclamationmark_triangle</i>`,
      position: 'center',
      closeTimeout: 2000,
    });
    function privateMethod() {
      console.log('private');
    }
    return {
      openToast: () => textToast.open(),
      openIconToast: () => iconToast.open(),
      setToastIcon: (icon) => {
        iconToast.$el.find('.toast-icon i.f7-icons').text(icon);
        return instance;
      },
      setToastText: (text) => {
        textToast.$el.find('.toast-text').text(text);
        iconToast.$el.find('.toast-text').text(text);
        return instance;
      },
    };
  }
  return {
    get: (_) => instance,
    set(f7) {
      if (!instance) instance = init(f7);
    },
  };
})();

export const simpleFormat = (text, options) => {
  try {
    eltext = _.isString(text) ? text.split(/(?:\r\n|\r|\n)/g) : [];
    text = _.map(text, (v) => <>{v}</>);
    return text;
  } catch (e) {
    console.log(e);
  }
};

export const sleep = (n) => new Promise((resolve) => setTimeout(resolve, n));

export const saleRate = ({ list_price, sale_price }) => {
  let result = 0;
  if (list_price !== sale_price) {
    const resultSaleRate = Math.round((sale_price / list_price) * 100);
    result = 100 - resultSaleRate;
  }
  return result;
};

export const showToast = (text, timeout = 2000, position = 'center') => {
  f7.toast.show({ text, position, closeTimeout: timeout });
};

export const setReservationInformationAtLocalStorage = (
  totalCharge,
  tourSchedule,
  searchingOption,
  unAuthenticated,
  people,
  id,
) => {
  window.localStorage.setItem('totalCharge', JSON.stringify(totalCharge));
  window.localStorage.setItem('tourSchedule', JSON.stringify(tourSchedule));
  window.localStorage.setItem('searchingOption', JSON.stringify(searchingOption));
  window.localStorage.setItem('isProcessingWithUnAuthenticated', JSON.stringify(unAuthenticated));
  window.localStorage.setItem('peoplesCount', JSON.stringify(people));
  window.localStorage.setItem('driverId', id);
};

export const deleteReservationInformationAtLocalStorage = () => {
  window.localStorage.removeItem('isProcessingWithUnAuthenticated');
  window.localStorage.removeItem('driverId');
  window.localStorage.removeItem('tourSchedule');
  window.localStorage.removeItem('totalCharge');
  window.localStorage.removeItem('searchingOption');
  window.localStorage.removeItem('peoplesCount');
};

export const setMainViewHash = (action) => {
  if (window.isDesktop) return;

  if (action === 'in') {
    window.history.pushState(null, null, '#main_view');
  } else {
    window.history.pushState(null, null, window.location.pathname + window.location.search);
  }
};
