import { getBuses, getUsers } from '@api';
import useAuth from '@hooks/useAuth';
import { List, Navbar, Page, Stepper, FabButtons, Fab, Icon, FabButton, Button, Toolbar, Link } from 'framework7-react';
import { eq, isNull, map, times } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import DriverNewManagePage from '@pages/users/driverNewManagePage';
import backIcon from '@assets/images/back_icon.png';

const DriverManagePage = ({ f7router }) => {
  const { currentUser } = useAuth();

  const { data: buses, isLoading } = useQuery(
    ['driverManagement', 'buses'],
    getBuses({ q: { director_id_eq: currentUser.id } }),
    {
      enabled: currentUser.isAuthenticated,
    },
  );

  return (
    <Page noToolbar>
      <Navbar title="소속 기사 관리" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      {isLoading ? (
        <div>정보 로딩중...</div>
      ) : (
        <>
          {buses.results.length <= 0 ? (
            <div className="sm:mt-4 mt-8 flex justify-center font-bold text-lg">등록한 기사가 아직 없습니다.</div>
          ) : (
            <List linksList className="mt-6">
              {map(buses.results, (bus) => (
                <li key={bus.id}>
                  <a href={`/driversManage/${bus.id}?name=${bus.user.name}`}>
                    {bus.user.name} / {bus.bus_number}
                  </a>
                </li>
              ))}
            </List>
          )}
        </>
      )}

      <Toolbar position="bottom" className={window.isDesktop && 'initial-bottom bottom'} style={{ height: '60px' }}>
        <div className="w-full flex justify-between py-4">
          <Button text="기사 추가하기" className="bg-primary w-full" fill large href="/users/driverNewManagePage" />
        </div>
      </Toolbar>
    </Page>
  );
};

export default DriverManagePage;
