import { User, MessageType } from '@interfaces';
import { find } from 'lodash';
import moment from 'moment';

interface MessageRuleParams {
  message: MessageType;
  previousMessage: MessageType | undefined;
  nextMessage: MessageType | undefined;
}
interface GetMessagePropsParams extends MessageRuleParams {
  currentUserUuid: string;
}

const getMessageType = ({ message, currentUserUuid }: Partial<GetMessagePropsParams>) =>
  message?.user_id.toString() === currentUserUuid?.toString() ? 'sent' : 'received';

const messageTitleFormat = (message: MessageType) => moment(message?.createdAt).format('LL');

const getMessageUser = (user_id, users: User[]) => find(users, { id: Number(user_id) })?.name;

const messageType = (message, currentUserId) =>
  message?.user_id.toString() === currentUserId?.toString() ? 'sent' : 'received';

const sendAtFormat = (message) => moment(message?.createdAt).format('LT');
const isSameTime = (message, diffMessage) => sendAtFormat(message) === sendAtFormat(diffMessage);
const isSameDate = ({ message, previousMessage }: Omit<MessageRuleParams, 'nextMessage'>) => {
  const messageDate = moment(message.createdAt).format('LL');
  const previousMessageDate = moment(previousMessage?.createdAt).format('LL');
  return messageDate === previousMessageDate;
};

const firstMessageRule = ({ message, previousMessage }: MessageRuleParams) => {
  if (previousMessage?.user_id !== message.user_id) return true;
  if (!isSameTime(message, previousMessage)) return true;
  return false;
};

const lastMessageRule = ({ message, nextMessage }: MessageRuleParams) => {
  if (!nextMessage || nextMessage.user_id !== message.user_id) return true;
  return false;
};

const tailMessageRule = ({ message, nextMessage }: MessageRuleParams) => {
  if (!nextMessage || nextMessage.user_id !== message.user_id) return true;
  if (!isSameTime(message, nextMessage)) return true;

  return false;
};

const getMessageProps = (params: GetMessagePropsParams) => ({
  type: getMessageType(params),
  first: firstMessageRule(params),
  last: lastMessageRule(params),
  tail: tailMessageRule(params),
});

export { getMessageProps, isSameDate, sendAtFormat, getMessageType, messageTitleFormat };
