import { currentUserState } from '@atoms';
import S3ImageView from '@components/images/S3ImageView';
import { configs } from '@config';
import useAuth from '@hooks/useAuth';
import { Kakao } from '@interfaces';
import { setMainViewHash } from '@js/utils';
import { f7, Link, List, Navbar, NavLeft, NavRight, NavTitle, Page, PageContent } from 'framework7-react';
import { isNull } from 'lodash';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import bell from '@assets/images/bell_solid_white.png';
import menu from '@assets/images/menu_white.png';
import pen from '@assets/images/pen_solid.png';
import reservation from '@assets/images/reservation_icon.png';
import review from '@assets/images/review_icon.png';
import estimate from '@assets/images/estimate_icon.png';
import line from '@assets/images/line.png';

declare global {
  interface Window {
    Kakao: Kakao;
  }
}

const MyPage = () => {
  const { signOutUser, currentUser } = useAuth();
  const user = useRecoilValue(currentUserState);
  const { email, name, profile = { key: '', level: '' } } = user;
  const { KAKAO_TOKEN } = configs;

  const handleSignout = () => {
    f7.dialog.confirm('로그아웃 하시겠어요?', async () => {
      await signOutUser();
      if (window.Kakao?.isInitialized() === false) {
        window.Kakao.init(KAKAO_TOKEN);
        if (!window.Kakao.Auth.getAccessToken) {
          return;
        }
        window.Kakao.Auth.logout(() => {
          console.log(window.Kakao.Auth.getAccessToken());
        });
      }
      window.location.replace('/');
    });
  };

  const isDirector = useMemo(
    () => !isNull(currentUser?.director_email) && !isNull(currentUser?.director_name),
    [currentUser],
  );

  return (
    <Page
      onPageAfterIn={() => {
        setMainViewHash('in');
      }}
      onPageAfterOut={() => {
        setMainViewHash('out');
      }}
      className="mypage"
      pageContent={false}
    >
      <Navbar noHairline noShadow bgColor="primary" color="primary" className="bg-primary mypage-navbar">
        <NavLeft>
          <Link panelOpen="left">
            <img src={menu} alt="" width={20} />
          </Link>
        </NavLeft>
        <NavTitle className="font-bold text-lg text-white">마이페이지</NavTitle>
        <NavRight>
          <Link href="/notifications">
            <img src={bell} alt="" width={20} />
          </Link>
        </NavRight>
      </Navbar>
      <PageContent className="bg-[#F3F4F6]">
        <div className="sm:min-h-full">
          <div className="py-9 overflow-hidden bg-primary text-white px-4">
            <div className="flex w-full">
              <div className="mr-2.5">
                <S3ImageView imageKey={profile?.key || ''} className="w-[55px] h-[55px] rounded-full overflow-hidden" />
              </div>
              <div className="flex justify-between w-full items-center">
                <div className="">
                  <h4 className="text-lg font-bold pt-[10.5px]">
                    {name || '비회원'} <span className="font-normal">님</span>
                  </h4>
                  <p className="text-xs">{email || '로그인하시고 모든 서비스를 이용하세요'}</p>
                  {currentUser.isAuthenticated && (
                    <a
                      href="/users/edit"
                      className="rounded-full border border-white mt-3 px-3 py-1.5 text-xs font-medium inline-flex items-center"
                    >
                      정보 수정
                      <img src={pen} alt="" width={10} className="ml-1" />
                    </a>
                  )}
                </div>

                {currentUser.isAuthenticated && (
                  <div
                    onClick={handleSignout}
                    className="font-medium text-sm px-3 py-1 bg-[#0180B6] inline-flex items-center rounded-full w-auto mb-3"
                  >
                    로그아웃
                  </div>
                )}
              </div>
            </div>
          </div>

          {currentUser.isAuthenticated &&
            (currentUser.user_type === 'normal' ? (
              <div className="flex px-4 gap-4 justify-evenly mt-6">
                <Link
                  href="/normal-reservations"
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-6 h-24"
                >
                  <img src={reservation} alt="" width={38} className="mb-1" />
                  나의 예약
                </Link>
                <a
                  href="/reviews"
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-6 h-24"
                >
                  <img src={review} alt="" width={30} className="mb-1" />
                  고객 리뷰
                </a>
                {/*                <a
                  // TODO: 견적서 링크 필요
                  href=""
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-8 h-24"
                >
                  <img src={estimate} alt="" width={36} className="mb-1" />
                  견적서
                </a> */}
              </div>
            ) : (
              <div className="flex px-4 gap-4 justify-between mt-6">
                <a
                  href={isDirector ? '/dayoff' : `/dayoff/${currentUser.id}`}
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-6 h-24"
                >
                  <img src={reservation} alt="" width={38} className="mb-1" />
                  휴일 관리
                </a>
                <a
                  href="/reviews"
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-6 h-24"
                >
                  <img src={review} alt="" width={30} className="mb-1" />
                  고객 리뷰
                </a>
                <Link
                  href="/driver-reservations"
                  className="shadow inline-flex flex-col items-center justify-center font-medium text-sm bg-white rounded-lg px-6 h-24"
                >
                  <img src={line} alt="" width={30} className="mb-1" />
                  예약 관리
                </Link>
              </div>
            ))}

          {currentUser.isAuthenticated ? (
            <List linksList inset className="mt-6 bg-white font-medium shadow">
              <li>
                <a href="/notices">공지사항</a>
              </li>
              <li>
                <a href="/faqs">자주 묻는 질문</a>
                <a href="https://pf.kakao.com/_vUcrxj/chat" target="_blank" className="external" rel="noreferrer">
                  1:1 문의하기
                </a>
              </li>
              <li>
                {currentUser.user_type === 'normal' ? (
                  <>
                    <a href="/users/card">카드목록</a>
                  </>
                ) : (
                  <>
                    {isDirector ? (
                      <a href="/users/driverManage">소속 기사 관리</a>
                    ) : (
                      <a href="/users/driverEdit">내 버스정보</a>
                    )}
                  </>
                )}
              </li>
            </List>
          ) : (
            <>
              <List linksList inset className="mt-6 bg-white font-medium shadow">
                <li>
                  <a href="/notices">공지사항</a>
                </li>
                <li>
                  <a href="/faqs">자주 묻는 질문</a>
                  <a href="https://pf.kakao.com/_vUcrxj/chat" target="_blank" className="external" rel="noreferrer">
                    1:1 문의하기
                  </a>
                </li>
                <li>
                  <a href="/users/sign_in">로그인</a>
                </li>
                <li>
                  <a href="/users/sign_up/intro">회원가입</a>
                </li>
              </List>
            </>
          )}
        </div>
      </PageContent>
    </Page>
  );
};

export default MyPage;
