export const configs = {
  API_URL: process.env.API_URL || 'http://localhost:3000',
  SERVICE_URL: process.env.SERVICE_URL || 'http://0.0.0.0:8080',
  ENV: process.env.NODE_ENV || 'development',
  VERSION: process.env.VERSION || '1',
  AWS_API_KEY: process.env.AWS_API_KEY || '',
  KAKAO_TOKEN: process.env.KAKAO_TOKEN || '',
  DYNAMIC_LINK_BROWSER_KEY: process.env.DYNAMIC_LINK_BROWSER_KEY || '',
  APPLE_REDIRECT_URL: process.env.APPLE_REDIRECT_URL || '',
  TOSS_CLIENT_KEY: process.env.TOSS_CLIENT_KEY || 'test_ck_jZ61JOxRQVEZ2mjwEWzrW0X9bAqw',
};

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
