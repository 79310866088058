import Auth, { CognitoUser } from '@aws-amplify/auth';
import React, { useCallback, useState } from 'react';
import { f7, Page, Navbar, List, ListInput, Button, Link } from 'framework7-react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';
import useAuth from '@hooks/useAuth';
import { userMeApi } from '@api';
import { showToast } from '@js/utils';
import { KakaoLoginButton, AppleLoginButton } from '@components/oauthButtons';
import { kakaoLoginSuccessHandler } from '@utils/customAuthChallenge';
import { F7Route } from '@interfaces';
import { configs } from '@config';
import { getDevice } from 'framework7';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import backIcon from '@assets/images/back_icon.png';
import logoIcon from '@assets/images/logo.png';

type AmplifySignIn = (param: UserSignInParams) => Promise<CognitoUser>;

interface UserSignInParams {
  email: string;
  password: string;
}

const amplifySignIn: AmplifySignIn = async (params) => {
  const { email, password } = params;

  const user = await Auth.signIn({
    username: email,
    password,
  });

  return user;
};

const INITIAL_LOG_IN_PARAMS: UserSignInParams = { email: '', password: '' };

const signInSchema = Yup.object().shape({
  email: Yup.string().email('유효한 이메일 주소가 아닙니다.').required('필수 입력사항 입니다'),
  password: Yup.string().min(8, '길이가 너무 짧습니다').max(50, '길이가 너무 깁니다').required('필수 입력사항 입니다'),
});

const SessionNewPage: React.FC<F7Route> = ({ f7router }) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const { authenticateUser } = useAuth();
  const { KAKAO_TOKEN } = configs;
  const device = getDevice();

  const onSubmitHandler = useCallback(
    async (signInParams: UserSignInParams, { setSubmitting }: FormikHelpers<UserSignInParams>) => {
      setSubmitting(true);
      f7.preloader.show();

      let user: null | CognitoUser = null;
      let message: string;
      let me = null;

      try {
        user = await amplifySignIn(signInParams);
        message = '성공적으로 로그인 하였습니다';

        const { data } = await userMeApi((user as CognitoUser & { attributes: any }).attributes.email);
        me = data;
        if (!me) {
          message = '해당 계정이 존재하지 않습니다';
        }
      } catch (error) {
        if (error.code === 'UserNotFoundException') {
          message = '해당 계정이 존재하지 않습니다';
        } else if (error.code === 'NotAuthorizedException') {
          message = '이메일 또는 비밀번호를 확인해주세요';
        } else {
          message = '예상치 못한 오류가 발생하였습니다';
        }
      } finally {
        setSubmitting(false);
        f7.preloader.hide();
        if (user && me) {
          await authenticateUser(user);
          window.location.replace('/');
        } else {
          f7.dialog.alert(message);
        }
      }
    },
    [authenticateUser],
  );

  return (
    <Page className="bg-white" noToolbar>
      <Navbar sliding={false} noHairline>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="sm:min-h-full " style={{ maxWidth: '30rem' }}>
        <div className="pt-9 text-center">
          <img src={logoIcon} alt="" width="101px" className="mx-auto" />
          <div className="text-lg mt-4">
            <span className="font-bold">안녕하세요.</span>
            <br />
            배낭버스 회원이시면 로그인 해 주세요.
          </div>
        </div>
        <Formik
          initialValues={INITIAL_LOG_IN_PARAMS}
          validationSchema={signInSchema}
          onSubmit={onSubmitHandler}
          validateOnMount
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
            <form onSubmit={handleSubmit} className="registration-form mt-16 mx-2.5">
              <List>
                <ListInput
                  label={i18next.t('login.email') as string}
                  name="email"
                  type="email"
                  placeholder="이메일을 입력해주세요."
                  clearButton
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errorMessageForce
                  errorMessage={touched.email && errors.email}
                />
                <ListInput
                  label={i18next.t('login.password') as string}
                  type={isPasswordShow ? 'text' : 'password'}
                  name="password"
                  placeholder="비밀번호를 입력해주세요"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  errorMessageForce
                  errorMessage={touched.password && errors.password}
                >
                  <span
                    onClick={() => setIsPasswordShow(!isPasswordShow)}
                    slot="input"
                    className="absolute top-[-5px] transform translate-y-1/2 right-4 text-[17px] text-theme text-opacity-50 z-10"
                  >
                    {values.password &&
                      (isPasswordShow ? (
                        <AiOutlineEye color="#b9c0c8" size="25" />
                      ) : (
                        <AiOutlineEyeInvisible color="#b9c0c8" size="25" />
                      ))}
                  </span>
                </ListInput>
              </List>

              <div className="mt-6 p-4">
                <button
                  type="submit"
                  className="button button-fill disabled:bg-[#b9c0c8] h-11 font-bold text-base mb-2.5"
                  disabled={isSubmitting || !isValid}
                >
                  로그인
                </button>

                <div className="mb-2.5">
                  <KakaoLoginButton
                    token={KAKAO_TOKEN}
                    onSuccess={kakaoLoginSuccessHandler(authenticateUser)}
                    onFail={(_) => _}
                    f7router={f7router}
                  />
                </div>
                <div>{device.ios && <AppleLoginButton />}</div>
              </div>
            </form>
          )}
        </Formik>
        <div className="flex justify-around mr-4 mt-2 mb-6 text-black font-medium">
          <a href="/users/sign_up/intro">회원가입</a>
          <a href="/users/passwords">비밀번호 찾기</a>
        </div>
      </div>
    </Page>
  );
};

export default React.memo(SessionNewPage);
