import React, { useMemo } from 'react';
import { Navbar, Page, List, ListItem, AccordionContent, Link } from 'framework7-react';
import { useQuery } from 'react-query';
import { getFaqs } from '@api';
import ReactQueryState from '@components/shared/ReactQueryState';
import backIcon from '@assets/images/back_icon.png';

const FaqIndexPage = (props) => {
  const { status, data, error } = useQuery('faqs', getFaqs);

  const faqs = useMemo(() => data?.results?.flat(), [data]);

  return (
    <Page noToolbar>
      <Navbar title="FAQ">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <div className="sm:min-h-full sm:pt-6">
        <ReactQueryState data={faqs} status={status} error={error} />
        {data && data?.results?.length > 0 ? (
          <List accordionList className="m-0">
            {faqs.map((faq, index) => (
              <ListItem key={faq.id} accordionItem title={`${index + 1}. ${faq.question}`}>
                <AccordionContent>
                  <div className="p-3">
                    <p>{faq.answer}</p>
                  </div>
                </AccordionContent>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className="h-60 flex justify-center items-center text-base">등록된 FAQ가 없습니다.</div>
        )}
      </div>
    </Page>
  );
};

export default FaqIndexPage;
