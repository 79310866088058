import React, { useCallback, useEffect, useRef } from 'react';
import { f7, Link, Navbar, NavRight, NavTitle, Page } from 'framework7-react';
import { F7Route } from '@interfaces';
import { useRecoilValue } from 'recoil';
import { CurrentUserState, currentUserState } from '@atoms';
import { useQuery, useQueryClient } from 'react-query';
import { Actions, Messages as MesagesList } from 'framework7/types';
import { REACT_QUERY_KEYS } from '@constants';
import { getChatroom, readChatroomAPI } from '@api';
import useSingleChat from '@hooks/useSingleChat';
import ChatRoomMessageBar from '@components/chatrooms/ChatRoomMessageBar';
import ChatRoomMessageList from '@components/chatrooms/ChatRoomMessageList';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import backIcon from '@assets/images/back_icon.png';

const SingleChatRoomPage: React.FC<F7Route> = ({ f7route }) => {
  const currentUser = useRecoilValue(currentUserState as CurrentUserState);
  const queryClient = useQueryClient();
  const chatroomActions = useRef<Actions.Actions | null>(null);

  const { id: targetUserUuid = '' } = f7route.params;

  const { data: chatroom, refetch: refetchChatroom } = useQuery(
    [REACT_QUERY_KEYS.CHATROOM, targetUserUuid],
    async () => {
      const chatroomData = await getChatroom(targetUserUuid);
      return chatroomData;
    },
    { refetchOnMount: true },
  );

  const { sendMessageHandler, disableUserChatroomHandler, ...singleChatHooks } = useSingleChat({
    currentUser,
    targetUserUuid,
    chatroom,
    queryClient,
    refetchChatroom,
  });

  const openChatroomEditHandler = useCallback(() => {
    chatroomActions.current?.open();
  }, []);

  useEffect(() => {
    if (!chatroom) return;
    const isNeedToReadChatroom = chatroom.last_message_user_id !== currentUser.id && chatroom.unread_count > 0;
    if (!isNeedToReadChatroom) return;
    readChatroomAPI(chatroom.id).then(() => queryClient.refetchQueries(REACT_QUERY_KEYS.MY_CHATROOMS));
  }, [chatroom, currentUser.id, queryClient]);

  useEffect(() => {
    if (!chatroom) return;
    if (chatroom.user_chatrooms_count < 2) {
      f7.toast
        .create({
          text: '비활성화된 채팅방 입니다<br>메시지를 보내면<br>다시 활성화 됩니다',
          position: 'center',
          cssClass: 'text-center',
          closeTimeout: 3000,
          destroyOnClose: true,
        })
        .open();
    }
  }, [chatroom, currentUser.id]);

  useEffect(() => {
    chatroomActions.current = f7.actions.create({
      buttons: [
        {
          text: '채팅방 나가기',
          bold: true,
          color: 'red',
          onClick: disableUserChatroomHandler,
        },
        {
          text: '취소',
          bold: true,
          color: 'black',
        },
      ],
    });
  }, [disableUserChatroomHandler]);
  const targetUser = chatroom?.users?.find((v) => v.id !== currentUser.id);

  return (
    <Page noToolbar>
      <Navbar>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
        <NavTitle>{targetUser?.name || '새 채팅방'}</NavTitle>
        <NavRight>
          {chatroom && (
            <NavRight>
              <IoEllipsisHorizontal size={22} onClick={openChatroomEditHandler} />
            </NavRight>
          )}
        </NavRight>
      </Navbar>
      <div className="sm:min-h-full" style={{ maxWidth: '30rem' }}>
        {chatroom && (
          <ChatRoomMessageList
            chatroom={chatroom}
            currentUser={currentUser}
            targetUser={targetUser}
            {...singleChatHooks}
          />
        )}
        <ChatRoomMessageBar sendMessageHandler={sendMessageHandler} />
      </div>
    </Page>
  );
};

export default SingleChatRoomPage;
