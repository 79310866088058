import { useState, useRef, useEffect } from 'react';
import Amplify, { API } from 'aws-amplify';
import { configs } from '@config';
import { showToast } from '@js/utils';
import { f7 } from 'framework7-react';
import { sleep } from '@utils';
import { FormikErrors } from 'formik';
import { NormalSignUpParams, DriverSignUpParams } from '@interfaces';
import { sendCode } from '@api';

interface PhoneCertificationParams {
  phone: string;
  phoneCertificationCode: string | number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void> | Promise<FormikErrors<NormalSignUpParams | DriverSignUpParams>>;
}

const usePhoneCertification = ({ phone, phoneCertificationCode, setFieldValue }: PhoneCertificationParams) => {
  const [code, setCode] = useState('');
  const certificateCode = useRef(code);
  const phoneRegExp = new RegExp('^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$');

  useEffect(() => {
    Amplify.configure({
      aws_appsync_authenticationType: configs.AWS_API_KEY,
    });
  }, []);

  const sendPhoneCertification = async () => {
    const tempCode = `${Math.floor(1000 + Math.random() * 1000)}`;
    const phoneNumber = phone.replace(/-/g, '');
    setCode(tempCode);
    certificateCode.current = tempCode;
    if (configs.ENV === 'development') {
      console.log(tempCode);
    }

    await sendCode({ phone: phoneNumber, code: certificateCode.current });
    showToast('인증번호가 발송되었습니다');
  };

  const checkPhoneCertification = async () => {
    const isMatched = phoneCertificationCode === certificateCode.current;
    f7.preloader.show();
    await sleep(500);
    f7.preloader.hide();
    if (isMatched) {
      setFieldValue('phone_matched', true);
      showToast('인증이 완료되었습니다');
    } else {
      setFieldValue('phoneCertificationCode', null);
      showToast('인증번호가 일치하지 않습니다');
    }
  };

  return {
    certificateCode,
    phoneRegExp,
    sendPhoneCertification,
    checkPhoneCertification,
  };
};

export default usePhoneCertification;
