import { getBanner } from '@api';
import { Banner } from '@interfaces';
import { Link, Navbar, NavTitle, Page } from 'framework7-react';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import backIcon from '@assets/images/back_icon.png';

const BannerPage = ({ id }) => {
  const { data, refetch } = useQuery<Banner>([`banner-${id}`], async () => {
    const response = await getBanner(id);
    return response;
  });

  const banner = useMemo(() => data, [data]);

  return (
    <Page noToolbar>
      <Navbar>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
        <NavTitle>{banner?.title}</NavTitle>
      </Navbar>
      {banner && (
        <div
          dangerouslySetInnerHTML={{
            __html: banner?.body,
          }}
        />
      )}
    </Page>
  );
};

export default BannerPage;
