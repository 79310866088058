import { Auth } from '@aws-amplify/auth';
import useAuth from '@hooks/useAuth';
import { showToast, toast } from '@js/utils';
import React, { useCallback, useRef, useState } from 'react';
import { f7, Navbar, Page, List, ListInput, Button, Link } from 'framework7-react';
import { sleep } from '@utils';
import { Formik, Form, useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { currentUserState } from '@atoms';
import i18next from 'i18next';
import S3ImagePicker from '@components/images/S3ImagePicker';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { MainPlaceHolder } from '@components/images';
import { updateAPI, userDestroyAPI } from '@api';
import { pick } from 'lodash';
import usePhoneCertification from '@hooks/usePhoneCertification';
import backIcon from '@assets/images/back_icon.png';

export interface UserInfoParams {
  profileImg: any;
  name: string;
  email: string;
  phone: string;
  phoneCertificationCode: number | null;
  phone_matched: boolean;
}

const UserInfoSchema = Yup.object().shape({
  email: Yup.string().email().required('필수 입력사항 입니다'),
  name: Yup.string().required('필수 입력사항 입니다'),
  phone: Yup.string()
    .min(9, '길이가 너무 짧습니다')
    .max(15, '길이가 너무 깁니다')
    .required('휴대폰 번호를 입력해주세요'),
  phone_matched: Yup.boolean().oneOf([true], '휴대폰 인증을 완료해주세요'),
});

const EditPage = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [removedKey, setRemovedKey] = useState('');
  const removeKey = useRef(removedKey);
  const { name, profile, email, phone: originPhone } = currentUser;
  const { signOutUser } = useAuth();
  const resetCurrentUser = useResetRecoilState(currentUserState);

  const onSubmitHandler = async (values: UserInfoParams, { setSubmitting }: FormikHelpers<UserInfoParams>) => {
    setSubmitting(false);
    f7.preloader.show();
    try {
      if (email !== values.email) {
        const aws_user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(aws_user, {
          email: values.email,
        });
      }
      const profileImg = values.profileImg || profile;
      values.profileImg = pick(profileImg?.key !== removeKey.current ? profileImg : {}, ['key']);
      const { data: user } = await updateAPI({ ...values, type: 'user_edit' });

      f7.preloader.hide();
      await setCurrentUser({ ...user, isAuthenticated: true });
      showToast('정보가 변경되었습니다');
    } catch (error) {
      console.log(error);
      f7.preloader.hide();
      showToast(error?.response?.data || error?.message);
    }

    f7.preloader.hide();
  };

  const value = useFormik({
    initialValues: {
      email,
      name,
      phone: originPhone,
      profileImg: currentUser.profile || '',
      phoneCertificationCode: null,
      phone_matched: true,
    },
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: onSubmitHandler,
    validationSchema: UserInfoSchema,
  });

  const { setFieldValue, handleChange, submitForm, values, isValid, handleBlur, errors, touched, isSubmitting } = value;
  const { phone, phoneCertificationCode } = values;
  const phoneCodeRef = useRef(phoneCertificationCode);
  const { certificateCode, phoneRegExp, sendPhoneCertification, checkPhoneCertification } = usePhoneCertification({
    phone,
    phoneCertificationCode: phoneCodeRef.current,
    setFieldValue,
  });

  const { mutate: deleteMutation } = useMutation(userDestroyAPI(currentUser.id), {
    onError: (error) => {
      f7.dialog.close();
      console.log({ error });
      f7.dialog.alert('오류가 발생했습니다. 잠시후 다시 시도해주세요');
    },
  });

  const onClickDestroy = () => {
    f7.dialog.confirm('정말 탈퇴 하시겠습니까?', () => {
      f7.dialog.preloader('잠시만 기다려주세요...');
      deleteMutation(null, {
        onSuccess: async (res) => {
          try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            cognitoUser.deleteUser((deleteUserError) => {
              if (deleteUserError) throw deleteUserError;
              Auth.signOut({ global: true });
              f7.dialog.close();
            });
            console.log(res);
            await resetCurrentUser();
          } catch (error) {
            console.log(error);
            f7.dialog.alert('문제가 발생 하였습니다.');
          } finally {
            f7.dialog.close();
            f7.dialog.alert('탈퇴 되었습니다.');
            setTimeout(() => {
              window.location.href = '/?notice=회원 탈퇴 되었습니다';
            }, 1500);
          }
        },
      });
    });
  };

  return (
    <Page noToolbar>
      <Navbar title="회원정보 수정" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>

      <FormikProvider value={value}>
        <Form encType="multipart/form-data" className="registration-form">
          <List noHairlinesMd className="my-4">
            <div className="p-3 font-semibold bg-white">기본 정보</div>
            {currentUser.user_type === 'normal' && (
              <>
                <div className="p-3 bg-white">프로필 사진을 등록 해주세요</div>
                <div className="flex flex-col items-center bg-white">
                  <S3ImagePicker
                    isMultiple={false}
                    initialData={profile ? [profile] : undefined}
                    placeholderComponent={<MainPlaceHolder maxCount={1} isImage />}
                    deleteButtonComponent={
                      <IoCloseCircleSharp size={26} className="text-black bg-white rounded-full" />
                    }
                    removeImageHandler={(key, removedS3Image) => {
                      setRemovedKey(() => key);
                      removeKey.current = key;
                    }}
                    containerClassName="w-full h-full relative"
                    addImageHandler={(v: any) => {
                      if (v[0]) {
                        setFieldValue('profileImg', v[0]);
                      }
                      setRemovedKey((prev) => '');
                    }}
                  />
                </div>
              </>
            )}
            <ListInput
              outline
              label={i18next.t('login.email') as string}
              type="text"
              name="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <ListInput
              outline
              label={i18next.t('login.name') as string}
              type="text"
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
            />

            <li className="grid grid-cols-12 gap-4">
              <div className="col-span-9">
                <ListInput
                  outline
                  label="핸드폰번호"
                  type="text"
                  name="phone"
                  placeholder="'-'없이 입력해주세요"
                  clearButton
                  onChange={(e) => {
                    if (originPhone !== e.target.value) {
                      setFieldValue('phone_matched', false);
                    } else {
                      setFieldValue('phone_matched', true);
                    }

                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.phone}
                  errorMessageForce
                  errorMessage={touched.phone && errors.phone}
                  onInputClear={() => {
                    setFieldValue('phone', '');
                  }}
                />
              </div>
              <div className="col-span-3 my-auto mr-4">
                <Button
                  outline
                  disabled={!phoneRegExp.test(values.phone)}
                  className="text-primary border-primary"
                  onClick={sendPhoneCertification}
                >
                  인증받기
                </Button>
              </div>
            </li>
            <li className="grid grid-cols-12 gap-4">
              <div className="col-span-9">
                <ListInput
                  outline
                  label="인증번호"
                  type="number"
                  name="phoneCertificationCode"
                  placeholder="인증번호를 입력해주세요"
                  clearButton
                  onChange={(e) => {
                    phoneCodeRef.current = e.target.value;
                    setFieldValue('phoneCertificationCode', e.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.phoneCertificationCode}
                  errorMessageForce
                  errorMessage={touched.phoneCertificationCode && errors.phoneCertificationCode}
                  onInputClear={() => {
                    setFieldValue('phoneCertificationCode', '');
                  }}
                />
              </div>
              <div className="col-span-3 my-auto mr-4">
                <Button
                  fill
                  onClick={checkPhoneCertification}
                  disabled={certificateCode.current === ''}
                  className="authenticate-check-button"
                >
                  인증확인
                </Button>
              </div>
            </li>
          </List>
          <div className="p-4">
            <button
              type="submit"
              className="button button-fill button-large disabled:opacity-50"
              disabled={isSubmitting || !isValid}
            >
              정보수정
            </button>
          </div>
        </Form>
      </FormikProvider>

      <div className="p-4 mx-auto text-center">
        <button
          className="text-red-600 outline-none"
          onClick={() => {
            onClickDestroy();
          }}
        >
          계정 탈퇴
        </button>
      </div>
    </Page>
  );
};

export default EditPage;
