import { ResourceRoute } from '@interfaces';
import BannerPage from '@pages/banner';
import SingleChatRoom from '@pages/chatrooms/SingleChatRoom';
import DriverShowPage from '@pages/DriverShow';
import HomePage from '@pages/home';
import IntroPage from '@pages/intro';
import MyPage from '@pages/mypage';
import NotificationIndexaPage from '@pages/notifications/index';
import DriverReservationIndexPage from '@pages/reservations/driverIndex';
import DriverPastReservationIndexPage from '@pages/reservations/driverPastIndex';
import NormalReservationIndexPage from '@pages/reservations/normalIndex';
import EditReviewPage from '@pages/reviews/Edit';
import NewReviewPage from '@pages/reviews/new';
import SearchPage from '@pages/search';
import SearchConfirmPage from '@pages/searchConfirm';
import CardPage from '@pages/users/card';
import DayoffPage from '@pages/users/dayoff';
import DriverEditPage from '@pages/users/driverEdit';
import EditPage from '@pages/users/edit';
import EditDayoffPage from '@pages/users/editDayoff';
import NewPasswordPage from '@pages/users/passwords';
import EditPasswordPage from '@pages/users/passwords/new';
import SignUpIndexPage from '@pages/users/registrations';
import DriverSignUpPage from '@pages/users/registrations/driver';
import NormalSignUpPage from '@pages/users/registrations/normal';
import TermsPage from '@pages/users/registrations/terms';
import UserTypeSelectPage from '@pages/users/registrations/userTypeSelect';
import LoginPage from '@pages/users/sessions/new';
import DriverManagePage from '@pages/users/driverManage';
import DriverEditManagePage from '@pages/users/driverEditManagePage';
import DriverNewManagePage from '@pages/users/driverNewManagePage';

import { mapAsyncRoute, mapResourceRoute, mergeRoutes } from './routes.utils';

/**
 * @resourceRoutes
 * @param {String} resource (required)
 * @param {Array} only (optional)
 * ex) ['show'] -> [{ path: 'items/:id', component: '@pages/items/show.{jsx|tsx}'}]
 * only 를 명시 안해주면 show, index, new, edit 을 모두 탐색 합니다.
 *
 * @param {Array} collection (optional)
 * ex) ['buy'] -> [{ path: 'items/buy', component: '@pages/items/buy.{jsx|tsx}'}]
 *
 * @param {Array} member (optional)
 * ex) ['my_item'] -> [{ path: 'items/:id/my_item', component: '@pages/items/my_item.{jsx|tsx}'}]
 */
const resourceRoutes: ResourceRoute[] = [
  {
    resource: 'users',
  },
  {
    resource: 'notices',
    only: ['index', 'show'],
  },
  {
    resource: 'faqs',
    only: ['index'],
  },
  {
    resource: 'estimates',
    only: ['index'],
  },
  {
    resource: 'contacts',
  },
  {
    resource: 'reservations',
    only: ['index', 'show'],
  },
  {
    resource: 'rooms',
  },
  {
    resource: 'driverlists',
  },
  {
    resource: 'chatrooms',
    only: ['index', 'show'],
    member: ['single'],
  },
  {
    resource: 'reviews',
  },
  {
    resource: 'comments',
  },
];

/**
 * @customRoutes
 * @param {String} path (required)
 * @param {Array} component (required)
 */
const customRoutes = [
  { path: '/', component: HomePage },
  { path: '/mypage', component: MyPage },
  { path: '/intro', component: IntroPage },
  { path: '/search', component: SearchPage },
  { path: '/notifications', component: NotificationIndexaPage },
  { path: '/banners/:id', component: BannerPage },
  { path: '/users/sign_in', component: LoginPage },
  { path: '/users/sign_up/normal', component: NormalSignUpPage },
  { path: '/users/sign_up/driver', component: DriverSignUpPage },
  { path: '/users/sign_up/intro', component: SignUpIndexPage },
  { path: '/users/sign_up/terms', component: TermsPage },
  { path: '/users/passwords', component: NewPasswordPage },
  { path: '/users/passwords/new', component: EditPasswordPage },
  { path: '/users/card', component: CardPage },
  { path: '/users/edit', component: EditPage },
  { path: '/users/driverEdit', component: DriverEditPage },
  { path: '/drivers/:id', component: DriverShowPage },
  { path: '/reviews/create', component: NewReviewPage },
  { path: '/reviews/edit/:id', component: EditReviewPage },
  { path: '/users/:id/chatroom', component: SingleChatRoom },
  { path: '/normal-reservations', component: NormalReservationIndexPage },
  { path: '/driver-reservations', component: DriverReservationIndexPage },
  { path: '/driver-past-reservations', component: DriverPastReservationIndexPage },
  { path: '/users/sign_up/type', component: UserTypeSelectPage },
  { path: '/users/driverManage', component: DriverManagePage },
  { path: '/users/driverNewManagePage', component: DriverNewManagePage },
  { path: '/driversManage/:id', component: DriverEditManagePage },
  { path: '/dayoff', component: DayoffPage },
  { path: '/dayoff/:id', component: EditDayoffPage },
  { path: '/search/confirm', component: SearchConfirmPage },
];

/**
 * @asyncRoutes
 * @param {String} path (required)
 * @param {React.FC} component (required)
 * asyncRoutes 랑 path 가 중복되면 asyncRoute 를 우선 적용
 */
const asyncRoutes = [];

const mappedResourceRoutes = resourceRoutes
  .map((resource) => mapResourceRoute(resource))
  .reduce((acc, routes) => [...acc, ...routes], []);

const mappedAsyncRoutes = asyncRoutes.map((route) => mapAsyncRoute(route));

export default mergeRoutes(mappedResourceRoutes, customRoutes, mappedAsyncRoutes);
