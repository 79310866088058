import S3ImageView from '@components/images/S3ImageView';
import { S3Image } from '@interfaces';
import { Swiper, SwiperSlide } from 'framework7-react';
import React, { useMemo } from 'react';
import { SwiperOptions } from 'swiper';

interface ImageSlideProps {
  images: S3Image[];
  navigation: string;
}

const ImageSlide: React.FC<ImageSlideProps> = ({ images, navigation, children }) => {
  let swiperDefaultProps: SwiperOptions = {
    spaceBetween: 10,
    slidesPerView: 'auto',
    observer: true,
    speed: 600,
    pagination: {
      clickable: true,
    },
  };

  if (navigation) {
    swiperDefaultProps = {
      ...swiperDefaultProps,
      navigation: {
        nextEl: `.${navigation}-right`,
        prevEl: `.${navigation}-left`,
      },
    };
  }

  return (
    <Swiper {...swiperDefaultProps} className="relative">
      {children}
      {images?.length !== 0 &&
        images?.map(({ key }, index) => (
          <SwiperSlide className="relative " key={key}>
            {(images?.length && (
              <S3ImageView
                size="resized"
                ratio="square"
                imageKey={images[index]?.key}
                className="w-full object-cover"
              />
            )) ||
              null}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default ImageSlide;
