import React, { ChangeEvent, useCallback, useRef } from 'react';
import { Link, Messagebar } from 'framework7-react';
import 'moment/locale/ko';
import { Messagebar as F7Messagebar } from 'framework7/types';
import { IoArrowForwardCircleSharp } from 'react-icons/io5';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import { SingleChatHooks } from '@hooks/useSingleChat';

interface MessageBarRef {
  el: HTMLElement | null;
  f7Messagebar: () => F7Messagebar.Messagebar;
}

type ChatRoomMessageBarProps = Pick<SingleChatHooks, 'sendMessageHandler'>;

interface MessageForm {
  message: string;
}

const chatMessageSchema = Yup.object().shape({
  message: Yup.string().required(),
});

const ChatRoomMessageBar: React.FC<ChatRoomMessageBarProps> = ({ sendMessageHandler }) => {
  const messageBarRef = useRef<F7Messagebar.Messagebar | null>(null);

  const onSubmitHandler = useCallback(
    async (values: MessageForm, helpers: FormikHelpers<MessageForm>) => {
      await sendMessageHandler(values.message);
      helpers.resetForm();
      if (messageBarRef.current?.focus) messageBarRef.current.focus();
      helpers.setFieldError('message', undefined);
      helpers.validateForm();
    },
    [sendMessageHandler],
  );

  const { values, isValid, setFieldValue, submitForm } = useFormik<MessageForm>({
    initialValues: { message: '' },
    onSubmit: onSubmitHandler,
    validateOnMount: true,
    validationSchema: chatMessageSchema,
  });

  const messsageBarRefCallback: React.RefCallback<MessageBarRef> = useCallback((ref) => {
    if (!ref) return;
    messageBarRef.current = ref.f7Messagebar();
  }, []);

  const onInputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue('message', event.target.value);
    },
    [setFieldValue],
  );

  return (
    <Messagebar
      value={values.message}
      className="fixed bottom-0"
      placeholder="메시지를 입력 해주세요"
      onInput={onInputChangeHandler}
      ref={messsageBarRefCallback}
    >
      <Link href={false} style={{ alignSelf: 'center' }}>
        {isValid ? (
          <IoArrowForwardCircleSharp size={36} onClick={submitForm} />
        ) : (
          <IoArrowForwardCircleSharp color="#aaa" size={36} />
        )}
      </Link>
    </Messagebar>
  );
};

export default ChatRoomMessageBar;
