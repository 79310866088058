import React, { useState } from 'react';
import { Navbar, Page, NavTitle, Toolbar, Link, Tab, Block, Tabs, Button } from 'framework7-react';
import ServiceTerm from '@components/users/serviceTerm';
import LocationTerm from '@components/users/locationTerm';
import PrivacyTerm from '@components/users/privacyTerm';
import backIcon from '@assets/images/back_icon.png';

const TermsPage = ({ f7route }) => {
  const { tab } = f7route.query;
  const [tabActivate, setTabActivate] = useState(tab || 'term');
  return (
    <Page name="TermsPage" className="" noToolbar={!window.isDesktop}>
      <Navbar title="이용약관" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>

      <Toolbar tabbar top className="terms-tab">
        <Link
          tabLink="#term"
          tabLinkActive={tabActivate === 'term'}
          onClick={() => setTabActivate('term')}
          className="text-sm"
        >
          서비스 이용약관
        </Link>
        <Link
          tabLink="#location"
          tabLinkActive={tabActivate === 'location'}
          onClick={() => setTabActivate('location')}
          className="text-sm"
        >
          위치기반서비스
        </Link>
        <Link
          tabLink="#privacy"
          tabLinkActive={tabActivate === 'privacy'}
          onClick={() => setTabActivate('privacy')}
          className="text-sm"
        >
          개인정보취급방침
        </Link>
      </Toolbar>

      <Tabs>
        <Tab id="term" tabActive={tabActivate === 'term'} onTabShow={() => setTabActivate('term')}>
          <Block>
            <ServiceTerm />
          </Block>
        </Tab>
        <Tab id="location" tabActive={tabActivate === 'location'} onTabShow={() => setTabActivate('location')}>
          <Block>
            <LocationTerm />
          </Block>
        </Tab>
        <Tab id="privacy" tabActive={tabActivate === 'privacy'} onTabShow={() => setTabActivate('privacy')}>
          <Block>
            <PrivacyTerm />
          </Block>
        </Tab>
      </Tabs>
      <div className="sticky bottom-0 px-4 py-3 z-50 bg-[#F3F4F6]">
        <Button fill large back className="button button-fill rounded-xl h-12 font-bold text-base">
          확인
        </Button>
      </div>
    </Page>
  );
};

export default TermsPage;
