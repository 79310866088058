import { getBuses, getUsers } from '@api';
import useAuth from '@hooks/useAuth';
import { Link, List, Navbar, Page } from 'framework7-react';
import { map } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import backIcon from '@assets/images/back_icon.png';

const DayoffPage = ({ f7router }) => {
  const { currentUser } = useAuth();

  const { data: buses, isLoading } = useQuery(
    ['dayoff', 'buses'],
    getBuses({ q: { director_id_eq: currentUser.id } }),
    {
      enabled: currentUser.isAuthenticated,
    },
  );

  return (
    <Page noToolbar>
      <Navbar title="휴일 관리" sliding={false}>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      {isLoading ? (
        <div>정보 로딩중...</div>
      ) : (
        <>
          {buses.results.length <= 0 ? (
            <div className="sm:mt-4 mt-8 flex justify-center font-bold text-lg">등록한 기사가 아직 없습니다.</div>
          ) : (
            <List linksList className="mt-6">
              {map(buses.results, (bus) => (
                <li key={bus.id}>
                  <a href={`/dayoff/${bus.user_id}?name=${bus.user.name}`}>
                    {bus.user.name} / {bus.bus_number}
                  </a>
                </li>
              ))}
            </List>
          )}
        </>
      )}
    </Page>
  );
};

export default DayoffPage;
