import { f7, Link, List, ListInput, Navbar, Page } from 'framework7-react';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import backIcon from '@assets/images/back_icon.png';

const EditPasswordSchema = Yup.object().shape({
  code: Yup.string().required('필수 입력사항 입니다'),
  new_password: Yup.string()
    .min(8, '길이가 너무 짧습니다')
    .max(50, '길이가 너무 깁니다')
    .required('필수 입력사항 입니다'),
  new_password_confirmation: Yup.string()
    .required('필수 입력사항 입니다')
    .when('new_password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf([Yup.ref('new_password')], '비밀번호가 일치하지 않아요'),
    }),
});

const EditPasswordPage = ({ f7route }) => {
  const { email } = f7route.query;
  return (
    <Page className="bg-white" noToolbar>
      <Navbar title="비밀번호 갱신" sliding>
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <Formik
        initialValues={{ code: '', new_password: '', new_password_confirmation: '' }}
        onSubmit={async (values, actions) => {
          f7.preloader.show();
          try {
            const { code, new_password } = values;
            Auth.forgotPasswordSubmit(email, code, new_password)
              .then((data) =>
                f7.dialog.alert('비밀번호가 변경되었습니다.', () => {
                  window.location.replace('/');
                }),
              )
              .catch((err) => console.log(err));
            actions.setSubmitting(false);
            f7.preloader.hide();
          } catch (error) {
            f7.preloader.hide();
            f7.dialog.alert(error.message);
          }
        }}
        validationSchema={EditPasswordSchema}
      >
        {({ values, handleSubmit, handleChange, handleBlur, isSubmitting, isValid }) => (
          <form onSubmit={handleSubmit} className="registration-form">
            <List noHairlinesMd>
              <ListInput
                outline
                label="코드"
                name="code"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.code}
                placeholder="코드"
                clearButton
              />
            </List>
            <List noHairlinesMd>
              <ListInput
                outline
                label="새로운 비밀번호"
                name="new_password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new_password}
                placeholder="새로운 비밀번호"
                clearButton
              />
            </List>
            <List noHairlinesMd>
              <ListInput
                outline
                label="새로운 비밀번호 확인"
                name="new_password_confirmation"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.new_password_confirmation}
                placeholder="새로운 비밀번호 확인"
                clearButton
              />
            </List>
            <div className="p-4">
              <button
                type="submit"
                className="button button-fill button-large disabled:opacity-50"
                disabled={isSubmitting || !isValid}
              >
                비밀번호 변경
              </button>
            </div>
          </form>
        )}
      </Formik>
    </Page>
  );
};

export default EditPasswordPage;
