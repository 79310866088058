import Footer from '@components/shared/Footer';
import Layout from '@pages/Layout';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { f7ready, App } from 'framework7-react';
import { RecoilRoot } from 'recoil';
import { getDevice } from '@js/framework7-custom';
import { IS_PRODUCTION } from '@config';
import { toast } from '@js/utils';
import routes from '@routes';
import F7Views from '@components/Views';
import Auth from '@aws-amplify/auth';
import nativeConfig from '@js/nativeConfig';
import awsconfig from '../aws-exports';

declare global {
  interface Window {
    isDesktop: boolean;
  }
}

Auth.configure(awsconfig);
window.isDesktop = window.matchMedia('(min-width: 1180px)').matches;

const F7App = () => {
  const device = getDevice();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const f7params = {
    name: '배낭버스',
    theme: 'ios',
    id: 'com.insomenia.bus',
    routes,
    input: {
      scrollIntoViewOnFocus: device.capacitor,
      scrollIntoViewCentered: device.capacitor,
    },
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    view: {
      iosDynamicNavbar: device.ios,
    },
    dialog: {
      title: ' ',
      buttonOk: '확인',
      buttonCancel: '취소',
    },
  };

  useEffect(() => {
    f7ready((f7) => {
      nativeConfig.init(f7);
      toast.set(f7);
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Layout>
          <App {...f7params}>
            <F7Views />
          </App>
        </Layout>
        {IS_PRODUCTION ? null : <ReactQueryDevtools position="bottom-left" />}
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default F7App;
