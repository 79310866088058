import { S3File } from '@interfaces';
import useS3FileUploader, { AddFileHandler, RemoveFileHandler } from '@hooks/useS3FileUploader';
import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

interface FilePickerProps {
  initialData?: S3File[];
  containerClassName?: string;
  imageClassName?: string;
  deleteButtonComponent?: React.ReactElement;
  placeholderComponent: React.ReactElement;
  addFileHandler: AddFileHandler;
  removeFileHandler: RemoveFileHandler;
  isMultiple: boolean;
  maxCount?: number;
}

const S3MultiFilesPicker: React.FC<FilePickerProps> = ({
  initialData = [],
  isMultiple,
  imageClassName,
  placeholderComponent,
  deleteButtonComponent,
  containerClassName = '',
  maxCount = 1,
  addFileHandler,
  removeFileHandler,
}) => {
  const { s3Files, data, inputProps, openBrowserHandler, onRemoveHandler, onChangeHandler, inputRef } =
    useS3FileUploader({
      initialData,
      isMultiple,
      level: 'public',
      addFileHandler,
      maxCount,
      removeFileHandler,
    });
  // `grid-cols-5`
  return (
    <>
      <input {...inputProps} onChange={onChangeHandler} ref={inputRef} />
      <div className="flex gap-4">
        {(data.length &&
          data.map(({ previewSrc, key, isUploaded }) => (
            <div
              className="image-slide image-small rounded-md relative border border-dashed border-gray-300"
              key={previewSrc}
            >
              {isUploaded ? <img src={previewSrc} alt="" className={`rounded-md ${imageClassName}`} /> : '로딩중'}

              <div
                className="image-slide-delete-btn opacity-40 absolute -top-2 -right-3"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  onRemoveHandler(key);
                }}
              >
                {deleteButtonComponent}
              </div>
            </div>
          ))) ||
          null}
        {(s3Files.length &&
          s3Files?.map(({ key }) => (
            <div className="image-slide image-small rounded-md bg-gray-200 cursor-pointer relative" key={key}>
              <div className="flex justify-center items-center p-4 break-all w-full h-full transition duration-300 ease-in-out transform hover:scale-110">
                {key?.split('/')[1]}
              </div>
              <div className="image-slide-delete-btn absolute -top-2 -right-3" onClick={() => onRemoveHandler(key)}>
                {deleteButtonComponent}
              </div>
            </div>
          ))) ||
          null}
        {data.length + s3Files.length < maxCount && (
          <div className={containerClassName}>
            <div
              className={`image-slide image-small flex-col gap-y-1.5 border-none border-gray-300 rounded-md text-gray-600 cursor-pointer `}
              onClick={openBrowserHandler}
            >
              <div className="flex flex-col justify-center items-center w-full h-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110">
                <AiOutlinePlus size={30} color="#BAC1C9" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(S3MultiFilesPicker);
