import React from 'react';
import { Page, Navbar, PageContent, List, Panel, Link, NavRight } from 'framework7-react';
import S3ImageView from '@components/images/S3ImageView';
import leftNotice from '@assets/images/left_notice.png';
import leftFaq from '@assets/images/left_faq.png';
import leftLogin from '@assets/images/left_login.png';
import leftUsers from '@assets/images/left_users.png';
import leftLogout from '@assets/images/left_logout.png';
import emptyProfile from '@assets/images/empty_profile.png';
import backBtn from '@assets/images/back_btn.png';

const LeftPanel = ({ currentUser, handleLogout, isLoggedIn }) => {
  const { email, name, profile = { key: '', level: '' } } = currentUser;

  return (
    <Panel left cover className="shadow-2xl rounded-r-2xl">
      <Page className="rounded-r-2xl">
        <Navbar noHairline>
          <NavRight className="mr-4 back">
            <Link panelClose>
              <img src={backBtn} alt="" width={10} />
            </Link>
          </NavRight>
        </Navbar>
        <PageContent className="p-0">
          <a
            href="#"
            className="mb-8 my-2 mx-6 flex-shrink-0 group block border-b pb-8"
            style={{ borderColor: '#e7e7e7' }}
          >
            <div className="flex items-center">
              <div>
                {isLoggedIn && profile ? (
                  <S3ImageView imageKey={profile.key} className="w-16 h-16 rounded-full" />
                ) : (
                  <img src={emptyProfile} alt="" className="w-16 h-16 rounded-full" />
                )}
              </div>
              <div className="ml-3">
                <p className="text-lg font-medium text-gray-700 group-hover:text-gray-900">
                  {isLoggedIn ? (
                    name
                  ) : (
                    <>
                      <span className="font-bold underline">로그인</span> 해주세요
                    </>
                  )}
                </p>
                <p
                  className="text-sm font-normal text-gray-500 group-hover:text-gray-700"
                  style={{ overflowWrap: 'anywhere' }}
                >
                  {isLoggedIn ? email : ''}
                </p>
              </div>
            </div>
          </a>

          <List className="mt-0">
            <Link
              href="/notices"
              className="flex justify-start text-gray-900 hover:text-gray-900 hover:bg-gray-50 group px-6 py-4 text-base font-medium rounded-md"
              panelClose
              view="current"
            >
              <img src={leftNotice} alt="" width={16} className="mr-4" />
              공지사항
            </Link>
            <Link
              href="/faqs"
              className="flex justify-start text-gray-900 hover:text-gray-900 hover:bg-gray-50 group px-6 py-4 text-base font-medium rounded-md"
              panelClose
              view="current"
            >
              <img src={leftFaq} alt="" width={16} className="mr-4" />
              FAQ
            </Link>
            {isLoggedIn ? (
              <Link
                onClick={handleLogout}
                className="flex justify-start text-gray-900 hover:text-gray-900 hover:bg-gray-50 group px-6 py-4 text-base font-medium rounded-md"
                panelClose
              >
                <img src={leftLogout} alt="" width={16} className="mr-4" />
                로그아웃
              </Link>
            ) : (
              <div>
                <Link
                  href="/users/sign_in"
                  className="flex justify-start text-gray-900 hover:text-gray-900 hover:bg-gray-50 group px-6 py-4 text-base font-medium rounded-md"
                  panelClose
                  view="current"
                >
                  <img src={leftLogin} alt="" width={16} className="mr-4" />
                  로그인
                </Link>
                <Link
                  href="/users/sign_up/intro"
                  className="flex justify-start text-gray-900 hover:text-gray-900 hover:bg-gray-50 group px-6 py-4 text-base font-medium rounded-md"
                  panelClose
                  view="current"
                >
                  <img src={leftUsers} alt="" width={16} className="mr-4" />
                  회원가입
                </Link>
              </div>
            )}
          </List>
        </PageContent>
      </Page>
    </Panel>
  );
};

export default LeftPanel;
