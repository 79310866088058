import React from 'react';
import { Page, Navbar, f7, Link } from 'framework7-react';
import * as Yup from 'yup';
import { createComment } from '@api';
import { useQueryClient } from 'react-query';
import { REACT_QUERY_KEYS } from '@constants';
import useAuth from '@hooks/useAuth';
import CommentForm from '@components/comments/Form';
import backIcon from '@assets/images/back_icon.png';

const CommentSchema = Yup.object().shape({
  body: Yup.string().required('필수 입력사항 입니다'),
});

const NewCommentPage = ({ f7router, f7route }) => {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const { reviewId, review } = f7route.query;
  const parsedReview = JSON.parse(review);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      f7.dialog.preloader('잠시만 기다려주세요...');
      values.review_id = reviewId;
      values.user_id = currentUser.id;
      await createComment(values);
      await queryClient.refetchQueries(REACT_QUERY_KEYS.REVIEWS);
      f7.dialog.close();
      f7router.back();
    } catch (error) {
      f7.dialog.close();
      f7.dialog.alert(error.response.data || error.message);
    }
    setSubmitting(false);
  };

  return (
    <Page noToolbar>
      <Navbar title="댓글 작성">
        <Link iconOnly slot="left" back>
          <img src={backIcon} alt="" width="18px" />
        </Link>
      </Navbar>
      <CommentForm
        initialValues={{ body: '' }}
        validationSchema={CommentSchema}
        onSubmitHandler={onSubmitHandler}
        review={parsedReview}
      />
    </Page>
  );
};

export default NewCommentPage;
