import { Bus } from '@interfaces';
import React from 'react';

const BusOptions = ({ bus }) => {
  const { wifi, usb, fridge, movie, audio, sanitizer }: Bus = bus;

  return (
    <div className="mt-8">
      <div className="flex justify-between">
        <span className="">손소독제</span>
        <span className={`tag ${sanitizer && 'active'}`}>{sanitizer ? '포함' : '미포함'}</span>
      </div>
      <div className="flex justify-between mt-4">
        <span className="">Wi-Fi</span>
        <span className={`tag ${wifi && 'active'}`}>{wifi ? '포함' : '미포함'}</span>
      </div>
      <div className="flex justify-between mt-4">
        <span className="">전 좌석 USB포트</span>
        <span className={`tag ${usb && 'active'}`}>{usb ? '포함' : '미포함'}</span>
      </div>
      <div className="flex justify-between mt-4">
        <span className="">냉장고</span>
        <span className={`tag ${fridge && 'active'}`}>{fridge ? '포함' : '미포함'}</span>
      </div>
      <div className="flex justify-between mt-4">
        <span className="">영화관람</span>
        <span className={`tag ${movie && 'active'}`}>{movie ? '포함' : '미포함'}</span>
      </div>
      <div className="flex justify-between mt-4">
        <span className="">음향시설</span>
        <span className={`tag ${audio && 'active'}`}>{audio ? '포함' : '미포함'}</span>
      </div>
    </div>
  );
};

export default BusOptions;
