import { f7 } from 'framework7-react';
import { AppSyncTypes, CurrentUser } from '@interfaces';

const getIsCurrRouteSingleChatRoom = (currentRoute: string) => {
  const [_, roomName, roomId, roomType] = currentRoute.split('/');
  return roomName === 'chatrooms' && Boolean(roomId) && roomType === 'single';
};

const onNotificationClickHandler = (notification: AppSyncTypes.Notification) => () => {
  const { currentRoute } = f7.views.current.router;

  const isChatNotification = notification.target_type === 'Room';
  const isCurrRouteSingleChatRoom = getIsCurrRouteSingleChatRoom(currentRoute.route.path);

  switch (true) {
    case isChatNotification && isCurrRouteSingleChatRoom:
      f7.views.current.router.navigate(notification.redirect_to || '', {
        reloadCurrent: true,
      });
      break;
    case isChatNotification && !isCurrRouteSingleChatRoom:
      f7.views.current.router.navigate(notification.redirect_to || '');
      break;
    default:
      break;
  }
};

export const createInAppNotification = (notification: AppSyncTypes.Notification) => {
  f7.notification
    .create({
      title: notification.title || '',
      titleRightText: '지금',
      text: notification.content,
      closeTimeout: 3000,
      closeOnClick: true,
      on: {
        closed: (self) => f7.notification.destroy(self),
        click: onNotificationClickHandler(notification),
      },
    })
    .open();
};

export type NewMessageParams = { message: string; currentUser: CurrentUser; targetUserUuid: string; roomId: string };

export type BuildNewMessageFn = (params: NewMessageParams) => AppSyncTypes.CreateMessageInput;

export const buildNewMessage: BuildNewMessageFn = (params) => ({
  user_id: params.currentUser.uuid,
  room_id: params.roomId,
  members: [params.currentUser.uuid, params.targetUserUuid],
  text: params.message,
});

export const buildNewNotification = (message: AppSyncTypes.CreateMessageInput, currentUser: CurrentUser) => {
  const { user_id: sender_id, members, text, room_id, owner } = message;
  const receiver_id = members?.find((id) => id !== sender_id);
  if (!receiver_id) throw new Error('target user not found');
  return {
    receiver_id,
    content: text,
    target_type: 'Room',
    target_id: room_id,
    title: currentUser.name,
    redirect_to: `/users/${currentUser.uuid}/chatroom`,
  };
};

export const buildInitMessageForUser = (currentUser: CurrentUser, driver) => {
  const { phone, name } = currentUser;
  console.log(driver);
  const { bus_number } = driver.bus;
  const message = `
    차량번호 ${bus_number} <br> 
    ${name}님 ${phone}입니다. <br>
    예약건에 관하여 기사님이 통화요청을 할 수 있으며 궁금하신 사항은 채팅을 통해 문의 바랍니다.
  `;
  return message;
};
